import React from "react";
import { Article } from "src/components/Category/ElementBlock/Article";
import { Loader } from "src/components/Loader";

export const ElementBlock = (props) => {
  let tableItems = props.items;
  const page = props.pagination?.page;

  return (
    <div className="articleList__ul">
      <div className="articleList__list">
        {props.isLoading && (
          <div>
            <Loader />
          </div>
        )}
        {tableItems.map((item, index) => {
          return (
            <Article
              key={item._id}
              state={item}
              linkStatus={props.linkStatus}
              deadlineStatus={props.deadlineStatus}
              isAdmin={props.isAdmin}
            />
          );
        })}
      </div>
      {props.pagination && (
        <div className="paginationButtons">
          {page > 1 && (
            <div
              onClick={() => {
                let value;
                if (page <= 2) {
                  value = null;
                } else {
                  value = String(page - 1);
                }
                props.chengeFilter({ value }, props.pagination.pageName);
              }}
              className="button"
            >
              Предыдущая страница
            </div>
          )}
          {page > 0 && <div className="paginationButtons__counter">{page}</div>}
          {props.pagination.next && (
            <div
              onClick={() => {
                let value = String(page + 1);
                if (!page || page < 2) {
                  value = "2";
                }

                props.chengeFilter({ value }, props.pagination.pageName);
              }}
              className="button"
            >
              Следующая страница
            </div>
          )}
        </div>
      )}
    </div>
  );
};
