import getObjectByStringPath from "src/functions/getObjectByStringPath";

function checkExactDependencies(conditions, state, currentState) {
  return getDependenciesStatusItemsTypeAND(conditions, state, currentState);
}

function getDependenciesStatusItemsTypeAND(conditions, state, currentState) {
  if (!conditions || conditions.length === 0) return true;

  let i, item, status;
  for (i = 0; i < conditions.length; ++i) {
    item = conditions[i];
    status = getDependenciesStatusItem(item, state, currentState);
    if (status === false) {
      return false;
    }
  }
  return true;
}
function getDependenciesStatusItemsTypeOR(conditions, state, currentState) {
  if (!conditions || conditions.length === 0) return true;

  let i, item, status;
  for (i = 0; i < conditions.length; ++i) {
    item = conditions[i];
    status = getDependenciesStatusItem(item, state, currentState);
    if (status === true) {
      return true;
    }
  }
  return false;
}

function getDependenciesStatusItem(condition, state, currentState) {
  if (!condition?.type) {
    return false;
  }

  if (condition.type === "AND") {
    return getDependenciesStatusItemsTypeAND(condition.items, state, currentState);
  }

  if (condition.type === "OR") {
    return getDependenciesStatusItemsTypeOR(condition.items, state, currentState);
  }

  if (!condition?.path && !condition?.pathFromCurrent) return false;

  let dependencyValue;
  if (condition?.path) {
    dependencyValue = getObjectByStringPath(state, condition.path);
  }
  if (condition?.pathFromCurrent) {
    dependencyValue = getObjectByStringPath(currentState, condition.pathFromCurrent);
  }
  if (typeof dependencyValue === "undefined") return true;

  if (condition.type === "boolean") {
    if (dependencyValue === condition.value) return true;
    return false;
  }

  if (condition.type === "string") {
    if (condition?.include) {
      if (
        condition.include?.includes(dependencyValue) &&
        !condition?.exclude?.includes(dependencyValue)
      ) {
        return true;
      }
    } else {
      if (dependencyValue && !condition?.exclude?.includes(dependencyValue)) {
        return true;
      }
    }

    return false;
  }

  return false;
}

export default checkExactDependencies;
