import React, { useContext } from "react";
import toaster from "src/hooks/toaster.hook";
import copy from "copy-to-clipboard";
import { EditorJsWrapper } from "src/components/Content/items/EditorJsWrapper";
import GlobalBlock from "src/components/Content/items/GlobalBlock";
import { AuthContext } from "src/context/AuthContext";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { createUpdateList } from "src/redux/createReducer";
import checkExactDependencies from "src/functions/checkExactDependencies";

export const CreateArticleAll = (props) => {
  const { token } = useContext(AuthContext);
  const actions = props.actions();
  let state = props.state;
  let theme = props.themeContent;
  const options = props.themeGlobal.options;
  let loading = false;

  let site = props.state.main.project;
  let tableNames = [
    "№",
    "Название",
    "Автор",
    "Модератор",
    "Срок",
    "Символы",
    "Статус",
    "Статус оплаты",
    "Проект",
    "Тип",
    "Категория",
    "Удаление",
    "Публикация",
    "Ссылка",
  ];

  let statusEditor = checkExactDependencies(
    theme.creator.editor.exactDependencies,
    props.state,
    props.state.main
  );

  let updateStatusEditor = false;
  if (props.state.main.updateEditorStatus !== "update") {
    updateStatusEditor = true;
  }

  const addArticle = () => {
    let data = {
      data: props.state.data,
      main: props.state.main,
    };
    actions.generateAritcle(data, token);
  };

  const importArticles = () => {
    let tableId = window.prompt("Введите ID таблицы");
    if (!tableId) {
      return;
    }

    let data = {
      data: props.state.data,
      main: props.state.main,
      tableId,
    };
    actions.importArticles(data, token);
  };

  const toutchPastCodeEditor = (e) => {
    actions.changeText("update", props.state.main, "updateEditorStatus");
  };

  const pastCodeEditor = (e) => {
    let newText = e.target.value;
    newText = JSON.parse(newText);
    actions.updateEditor(newText);
    actions.changeText("no", props.state.main, "updateEditorStatus");
  };

  //Копирование кода статьи
  let copyCodeEditor = () => {
    //Преобразуем state в json
    let copyElement = JSON.stringify(props.state.data.editor);
    //Перемещаем state в буфер обмена
    copy(copyElement, {
      debug: true,
      message: "Нажмите #{key} чтобы скопировать",
    });
    toaster.notify("Код скопирован", {
      duration: 2000,
    });
  };

  return (
    <div className="createPage">
      <GlobalBlock
        actions={actions}
        state={state.main}
        theme={theme.creator.main}
        themeItems={theme.creator.main.items}
        themeItemsHidden={theme.creator.main.itemsHidden}
        isAdmin={props.isAdmin}
        site={state.main.project}
        articleState={state}
        articleStatus={"published"}
      />
      {statusEditor && (
        <>
          {updateStatusEditor && (
            <EditorJsWrapper
              updateEditor={actions.updateEditor}
              state={props.state.data.editor}
              classItem="editorBlock editorBlock--padding"
              site={site}
              name={props.state.main.name}
              editorClass={"editorPlace"}
            />
          )}
          <div className="element editorBlock">
            <div className="element__item element__item--oneLine">
              <input
                autoComplete="off"
                placeholder="Вставить код в редактор"
                onClick={toutchPastCodeEditor}
                onBlur={pastCodeEditor}
                name="editorPastCode"
                className="element__input"
              />
              <button
                className="button button--ots_left button--em_input"
                onClick={copyCodeEditor}
                disabled={loading}
              >
                Копировать код из редактора
              </button>
            </div>
          </div>
        </>
      )}
      <div className="element editorBlock">
        <button
          className="button button--ots_right"
          onClick={addArticle}
          disabled={loading}
        >
          Создать статью
        </button>
        <button className="button" onClick={importArticles} disabled={loading}>
          Импортировать статьи
        </button>
      </div>
      <div className="articleList">
        <h2 className="articleList__title">Добавленные</h2>
        <TableBlock
          isAdmin={props.isAdmin}
          names={tableNames}
          options={options}
          items={props.state.items}
          callBack={createUpdateList}
        />
      </div>
    </div>
  );
};
