import React, { useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { connect } from "react-redux";
import { AuthContext } from "src/context/AuthContext";
import HeaderInner from "src/components/Header/HeaderInner";

const Header = (props) => {
  const { status, token } = useContext(AuthContext);
  const isAuth = !!token;
  const isAdmin = status === "admin" ? true : false;

  return (
    <div className="header">
      <Routes>
        <Route
          path="/create"
          element={
            <HeaderInner
              theme={props.theme}
              isAuth={isAuth}
              isAdmin={isAdmin}
              state={props.state.createReducer}
            />
          }
        />
        <Route
          path="*"
          element={
            <HeaderInner
              theme={props.theme}
              isAuth={isAuth}
              isAdmin={isAdmin}
              state={props.state.articlesReducer}
            />
          }
        />
      </Routes>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state,
    theme: state.themReducer?.data?.header,
  };
};

export default connect(mapStateToProps)(Header);
