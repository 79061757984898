import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Loader } from "src/components/Loader";
import { AuthContext } from "src/context/AuthContext";
import {
  dashboardInitialData,
  dashboardInitialDataRequest,
} from "src/redux/dashboardReducer";
import { useSearchParams } from "react-router-dom";
import getSearchParamsObject from "src/functions/getSearchParamsObject";
import DashboardAdmin from "src/components/dashboard/DashboardAdmin";
import DashboardUser from "src/components/dashboard/DashboardUser";

const DashboardPage = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { token, status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  useEffect(() => {
    const searchParamsObject = getSearchParamsObject(searchParams.toString());

    dispatch(dashboardInitialData(token, searchParamsObject));

    //Обнуляем данные при выходе
    return () => {
      dispatch(dashboardInitialDataRequest());
    };
    //eslint-disable-next-line
  }, [token, dispatch, searchParams]);

  if (props.state.isLoading === true && !props.state.data) {
    return <Loader type="full" />;
  }

  if (props.state.isError === true) {
    //Если есть проблема с авторизацией
    if (props.state.errorCode === 401) {
      localStorage.removeItem("userData");
      return <p className="textCenter">Ошибка авторизации, перезагрузите эту страницу</p>;
    } else {
      return (
        <p className="textCenter">
          Ошибка загрузки данных. Если ошибка повторяется нажмите кнопку "Выйти" в самом
          верху и зайдите заново в редактор.
        </p>
      );
    }
  }

  return (
    <>
      <div className="content">
        {isAdmin && (
          <DashboardAdmin
            isAdmin={isAdmin}
            themeGlobal={props.themeGlobal}
            articles={props.state.data}
            activeUserId={props.state.activeUserId}
            isLoading={props.state.isLoading}
            pagination={props.state.pagination}
          />
        )}
        {!isAdmin && (
          <DashboardUser
            isAdmin={isAdmin}
            articles={props.state.data}
            pagination={props.state.pagination}
            isLoading={props.state.isLoading}
          />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.dashboardReducer,
    themeGlobal: state.themReducer.data.global,
  };
};

export default connect(mapStateToProps)(DashboardPage);
