import { replaceBbCodes } from "src/functions/replaceBbCodes";

export function getAllCharts(state, type = "count") {
  let elementDelimeter = "";
  if (type === "check") {
    elementDelimeter = ".";
  }

  let allCharts = "";

  if (state.articleType === "productlist" && state.projectId === 1) {
    allCharts = allChartsText(state, elementDelimeter);
  } else if (state.articleType === "gamelist" && state.projectId === 4) {
    allCharts = allChartsGamelist(state, elementDelimeter);
  } else if (state.articleType === "categorylist" && state.projectId === 4) {
    allCharts = allChartsGameCategorylist(state, elementDelimeter);
  } else {
    allCharts = getAllChartsInEditor(state, elementDelimeter);
  }

  allCharts = allCharts
    .replace(/<\/?[^>]+(>|$)/g, "")
    .replace(/=/g, "")
    .replace(/\*/g, "")
    .replace(/_/g, "")
    .replace(/~/g, "")
    .replace(/`/g, "")
    .replace(/#/g, "");

  return allCharts;
}

//Объединяем все написанные символы в одну строку для проверки
function allChartsText(state, t) {
  function ucFirst(str) {
    if (!str) return str;
    return str[0].toUpperCase() + str.slice(1);
  }

  let d = " ";

  let allCharts =
    state.data.main.description +
    d +
    state.data.main.content +
    d +
    state.data.choices.name +
    t +
    d +
    state.data.choices.top +
    d +
    state.data.choices.items.reduce(
      (sum, item) => sum + d + item.name + d + item.content,
      ""
    ) +
    d +
    state.data.choices.buttom +
    d +
    state.data.categories.name +
    t +
    d +
    state.data.categories.top +
    d +
    state.data.categories.items.reduce(
      (sum, item) => sum + d + item.name + t + d + item.content,
      ""
    ) +
    d +
    state.data.categories.buttom +
    d +
    state.data.trends.top +
    d +
    state.data.trends.items.reduce(
      (sum, item) => sum + d + item.name + t + d + item.content,
      ""
    ) +
    d +
    state.data.trends.buttom +
    d +
    state.data.questions.top +
    d +
    state.data.questions.items.reduce(
      (sum, item) => sum + d + item.name + d + item.content,
      ""
    ) +
    d +
    state.data.questions.buttom +
    d +
    state.data.brands.top +
    d +
    state.data.brands.items.reduce(
      (sum, item) => sum + d + item.name + t + d + item.country + d + item.content,
      ""
    ) +
    d +
    state.data.brands.otherBrands +
    t +
    d +
    state.data.brands.buttom +
    d +
    state.data.products.items.reduce(
      (sum, item) => sum + d + item.name + d + item.content,
      ""
    ) +
    d +
    state.data.products.items.reduce(
      (sum, item) =>
        sum +
        d +
        item.items.reduce((sum, item) => {
          if (item?.config?.imported) {
            return sum;
          }

          return (
            sum +
            d +
            item.name +
            t +
            d +
            ucFirst(item.brand) +
            t +
            d +
            ucFirst(item.country) +
            t +
            d +
            ucFirst(item.feature) +
            t +
            d +
            item.content +
            d +
            item.positive +
            t +
            d +
            item.negative +
            t +
            d +
            item.specifications.items.reduce((sum, item) => {
              let content;
              if (item.showInProductlist === true) {
                content = ucFirst(item.content) + t;
              } else {
                content = "";
              }
              return sum + d + content;
            }, "")
          );
        }, ""),
      ""
    ) +
    d +
    state.data.ending.name +
    d +
    state.data.ending.top +
    d +
    state.data.ending.tableName +
    d +
    state.data.ending.items.reduce(
      (sum, item) => sum + d + item.name + d + item.content,
      ""
    ) +
    d +
    state.data.ending.buttom;

  allCharts = replaceBbCodes(allCharts.replace(/ {2,}/g, " "));

  return allCharts;
}

function allChartsGamelist(state, t) {
  let d = " ";
  let allCharts =
    d +
    state.data.gameList.items.reduce(
      (sum, item) => sum + d + item.name + d + item.content,
      ""
    );
  allCharts = replaceBbCodes(allCharts.replace(/ {2,}/g, " "));
  return allCharts;
}

function allChartsGameCategorylist(state, t) {
  let d = " ";
  let allCharts =
    d +
    state.data.categoryGameList.items.reduce(
      (sum, item) => sum + d + item.content + d + item.description,
      ""
    );
  allCharts = replaceBbCodes(allCharts.replace(/ {2,}/g, " "));
  return allCharts;
}

export function getAllChartsInEditor(state, t) {
  let d = " ";
  let allCharts = "";

  if (state.data && state.data.main) {
    allCharts = allCharts + state.data.main.description;
  }

  if (state.data?.editor?.blocks?.length > 0) {
    allCharts = allCharts + d + getEditorCharts(state.data.editor.blocks, d);
  }

  if (state.data?.sochinenija?.config?.active === true) {
    allCharts =
      allCharts +
      d +
      state.data.sochinenija.items.reduce((sum, item) => {
        if (item?.config?.imported) {
          return sum;
        }
        return sum + d + item.name + d + item.content;
      }, "");
  }

  if (state.data?.compilation?.config?.active === true) {
    allCharts =
      allCharts +
      d +
      state.data.compilation.items.reduce((sum, item) => {
        if (item?.config?.imported) {
          return sum;
        }
        return (
          sum +
          d +
          item.name +
          d +
          item.content +
          item.specifications.items.reduce((sum, item) => sum + d + item.content, "")
        );
      }, "");
  }

  if (state.data?.guides?.config?.active) {
    allCharts =
      allCharts +
      d +
      state.data.guides.items.reduce((sum, item) => {
        if (item?.config?.imported) {
          return sum;
        }
        return sum + d + item.name + d + getEditorCharts(item?.data?.blocks, d);
      }, "");
  }

  if (state.data?.editorEnding?.blocks?.length > 0) {
    allCharts = allCharts + d + getEditorCharts(state.data.editorEnding.blocks, d);
  }

  if (state.data?.quiz?.config?.active === true) {
    allCharts =
      allCharts +
      d +
      state.data.quiz.name +
      d +
      state.data.quiz.top +
      d +
      state.data.quiz.buttom +
      d +
      state.data.quiz.items.reduce((sum, item) => sum + d + item.name, "");
  }

  if (state.data?.specifications?.config?.active === true) {
    allCharts =
      allCharts +
      d +
      state.data.specifications.items.reduce((sum, item) => sum + d + item.name, "");
  }

  //Для страницы создания записей
  if (state.main) {
    if (state.main.plan_specifications && state.main.plan_specifications !== "") {
      allCharts = allCharts + d + state.main.plan_specifications.replace(",", "");
    }
    if (state.main.planText?.items) {
      allCharts =
        allCharts +
        d +
        state.main.planText.items.reduce(
          (sum, item) => sum + d + item.name + d + item.content,
          ""
        );
    }
  }

  allCharts = allCharts.replace("undefined", "");
  allCharts = allCharts.replace(/\n*\r*\t*/g, "");
  allCharts = allCharts.replace(/<a.*?a>/g, "");
  allCharts = allCharts.replace(/<i>/g, "");
  allCharts = allCharts.replace(/<\/i>/g, "");
  allCharts = allCharts.replace(/<b>/g, "");
  allCharts = allCharts.replace(/<\/b>/g, "");
  allCharts = allCharts.replace(/<mark class="cdx-marker">/g, "");
  allCharts = allCharts.replace(/<\/mark>/g, "");
  allCharts = allCharts.replace(/<br>/g, "");
  allCharts = allCharts.replace(/ {2,}/g, " ");

  return allCharts;
}

export function getEditorCharts(blocks, d) {
  if (!blocks) {
    return "";
  }

  return blocks.reduce((sum, item) => {
    let text = sum;
    if (item.type === "header" || item.type === "paragraph") {
      text = sum + d + item.data.text;
    } else if (item.type === "list") {
      text = sum + d + item.data.items.reduce((sum, item) => sum + d + item, "");
    } else if (item.type === "checklist") {
      text = sum + d + item.data.items.reduce((sum, item) => sum + d + item.text, "");
    } else if (item.type === "personality") {
      text = sum + d + item.data.text + d + item.data.name + d + item.data.description;
    } else if (item.type === "warning") {
      text = sum + d + item.data.name + d + item.data.text;
    } else if (item.type === "spoiler" && item.data?.type !== "hide") {
      text = sum + d + item.data.name + d + item.data.text;
    } else if (item.type === "table") {
      text =
        sum +
        d +
        item.data.content.reduce(
          (sum, item) => sum + d + item.reduce((sum, item) => sum + d + item, ""),
          ""
        );
    }
    text = text
      .replace(/<\/?[^>]+(>|$)/g, "")
      .replace(/=/g, "")
      .replace(/\*/g, "")
      .replace(/_/g, "")
      .replace(/~/g, "")
      .replace(/`/g, "")
      .replace(/#/g, "");
    return text;
  }, "");
}
