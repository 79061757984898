import React from "react";
import getVideoLink from "src/functions/getVideoLink";

const YotubePlaceholder = (props) => {
  //Получаес картинку к видео
  let videoImg = getVideoLink(props.link);

  return (
    <div className="element__block">
      <img className="element__videoImg" alt="" src={videoImg} />
    </div>
  );
};

export default YotubePlaceholder;
