import * as React from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

import VideoGallery from "src/modules/EditorJs/videoGallery/gallery";
import ImagesGallery from "src/modules/EditorJs/imagesGallery/gallery";
import Math from "src/modules/EditorJs/math/bundle";
import Button from "src/modules/EditorJs/button/bundle";
import Quote from "src/modules/EditorJs/quote/bundle";
import Warning from "src/modules/EditorJs/warning/bundle";
import Spoiler from "src/modules/EditorJs/spoiler/bundle";
import TableBlock from "src/modules/EditorJs/table/src/plugin";
import TimelineTune from "src/modules/EditorJs/timelineTune/TimelineTune";

import Paragraph from "@editorjs/paragraph";
import Header from "@editorjs/header";
import Delimiter from "@editorjs/delimiter";
import List from "@editorjs/list";
import Embed from "@editorjs/embed";
import Marker from "@editorjs/marker";
import ImageTool from "@editorjs/image";
import LinkTool from "@editorjs/link";
import Personality from "@editorjs/personality";
import InlineCode from "@editorjs/inline-code";
import { useRef } from "react";

export const EditorJsWrapper = (props) => {
  const elmtRef = useRef(null);
  const fullScreenHandle = useFullScreenHandle();

  let config = {
    tools: {
      paragraph: {
        class: Paragraph,
        inlineToolbar: true,
      },
      header: {
        class: Header,
      },
      list: {
        class: List,
      },
      embed: {
        class: Embed,
        config: {
          services: {
            youtube: true,
            coub: true,
            "yandex-music-track": true,
            "yandex-music-album": true,
            "yandex-music-playlist": true,
            vine: true,
            vimeo: true,
            imgur: true,
            gfycat: true,
            twitter: true,
            instagram: true,
            codepen: true,
            "twitch-video": true,
            "twitch-channel": true,
          },
        },
      },
      delimiter: Delimiter,
      quote: {
        class: Quote,
        config: {
          quotePlaceholder: "Цитата",
          namePlaceholder: "Имя",
          descriptionPlaceholder: "Должность и другие данные",
          linkPlaceholder: "Ссылка",
          endpoint: `/api/upload/editor/${props.site}/img`, // Your backend file uploader endpoint
        },
      },
      marker: Marker,
      linkTool: {
        class: LinkTool,
        config: {
          endpoint: `/api/upload/editor/fetchUrl`, // Your backend endpoint for url data fetching
        },
      },
      personality: {
        class: Personality,
        config: {
          namePlaceholder: "Имя",
          descriptionPlaceholder: "Должность и другие данные",
          linkPlaceholder: "Ссылка",
          endpoint: `/api/upload/editor/${props.site}/img`, // Your backend file uploader endpoint
        },
      },
      table: {
        class: TableBlock,
        inlineToolbar: ["bold", "marker", "italic"],
      },
      image: {
        class: ImageTool,
        config: {
          endpoints: {
            byFile: `/api/upload/editor/${props.site}/img`, // Your backend file uploader endpoint
            byUrl: `/api/upload/editor/urokium/imgFetch`, // Your endpoint that provides uploading by Url
          },
        },
      },
      imagesGallery: {
        class: ImagesGallery,
        config: {
          endpoints: {
            byFile: `/api/upload/editor/${props.site}/img`, // Your backend file uploader endpoint
            byUrl: `/api/upload/editor/${props.site}/imgFetch`, // Your endpoint that provides uploading by Url
          },
        },
      },
      videoGallery: {
        class: VideoGallery,
        config: {
          endpoints: {
            byFile: `/api/upload/editor/${props.site}/img`, // Your backend file uploader endpoint
            byUrl: `/api/upload/editor/${props.site}/imgFetch`, // Your endpoint that provides uploading by Url
            getYoutubeName: "/api/upload/getYoutubeName",
          },
        },
      },
      warning: {
        class: Warning,
        config: {
          namePlaceholder: "Заголовок",
          textPlaceholder: "Текст",
        },
      },
      spoiler: {
        class: Spoiler,
        config: {
          namePlaceholder: "Заголовок",
          textPlaceholder: "Текст",
        },
      },
      button: {
        class: Button,
        config: {
          linkPlaceholder: "Ссылка",
          textPlaceholder: "Текст",
        },
      },
      math: {
        class: Math,
      },
      inlineCode: InlineCode,
      timelineTune: {
        class: TimelineTune,
      },
    },
    tunes: ["timelineTune"],
    // autofocus: true,
    data: props.state,

    i18n: {
      messages: {
        ui: {
          blockTunes: {
            toggler: {
              "Click to tune": "Нажмите, чтобы настроить",
              "or drag to move": "или перетащите",
            },
          },
          inlineToolbar: {
            converter: {
              "Convert to": "Конвертировать в",
            },
          },
          toolbar: {
            toolbox: {
              Add: "Добавить",
            },
          },
        },
        toolNames: {
          Text: "Параграф",
          Heading: "Заголовок",
          List: "Список",
          warning: "Блок внимания",
          button: "Кнопка",
          spoiler: "Спойлер",
          Checklist: "Чеклист",
          Quote: "Цитата",
          Code: "Код",
          Delimiter: "Разделитель",
          "Raw HTML": "HTML-фрагмент",
          Table: "Таблица",
          Link: "Ссылка",
          Marker: "Маркер",
          Bold: "Полужирный",
          Italic: "Курсив",
          InlineCode: "Моноширинный",
          Image: "Картинка",
          Personality: "Личность",
          Math: "Математические формулы",
        },
        tools: {
          warning: {
            name: "Название",
            text: "Сообщение",
          },
          link: {
            "Add a link": "Вставьте ссылку",
          },
          linkTool: {
            Link: "Вставьте ссылку",
          },
          stub: {
            "The block can not be displayed correctly.": "Блок не может быть отображен",
          },
          image: {
            Caption: "Описание",
            "Select an Image": "Добавить картинку",
            "With border": "С обводкой",
            "Stretch image": "Растянуть",
            "With background": "Добавить фон",
          },
          table: {
            "Insert column before": "Вставить колонку перед",
            "Insert column after": "Вставить колонку после",
            "Insert row before": "Вставить строку перед",
            "Insert row after": "Вставить строку после",
            "Delete row": "Удалить строку",
            "Delete column": "Удалить колонку",
          },
        },
        blockTunes: {
          delete: {
            Delete: "Удалить",
          },
          moveUp: {
            "Move up": "Переместить вверх",
          },
          moveDown: {
            "Move down": "Переместить вниз",
          },
        },
      },
    },
  };

  React.useEffect(() => {
    if (!elmtRef.current) {
      return;
    }

    let editorJs;
    (async () => {
      const { default: EditorJS } = await import("@editorjs/editorjs");

      editorJs = new EditorJS({
        ...config,
        holder: elmtRef.current,
        logLevel: "ERROR",
        placeholder: "Начните писать текст",
        onChange: () => {
          saveData();
        },
      });
    })().catch((error) => console.error(error));

    const saveData = () => {
      if (editorJs.save) {
        editorJs
          .save()
          .then((outputData) => {
            let data = outputData;
            props.updateEditor(data);
          })
          .catch((error) => {
            console.log("Saving failed: ", error);
          });
      }
    };

    return () => {
      if (editorJs.destroy) {
        editorJs.destroy();
      }
    };

    // eslint-disable-next-line
  }, []);

  return (
    <div className={props.classItem}>
      <FullScreen className="editorBlock__fullscreen" handle={fullScreenHandle}>
        <div className="editorBlock__fullscreenButtonBlock">
          <div
            onClick={
              fullScreenHandle.active ? fullScreenHandle.exit : fullScreenHandle.enter
            }
            className="editorBlock__fullscreenButton"
          >
            {fullScreenHandle.active ? "В обычный режим" : "На весь экран"}
          </div>
        </div>
        {props.name && <div className="editorBlock__editorHeader">{props.name}</div>}
        <div
          className={`editorWrapper ${props.editorClass}${
            props.readOnly && " editorWrapper--readOnly"
          }`}
          style={props.style}
          ref={elmtRef}
        />
      </FullScreen>
    </div>
  );
};
