import React, { useRef, useState } from "react";
import ContentEditable from "react-contenteditable";
import { useEffect } from "react";
import {
  getHtmlInMarkdown,
  replaceBackData,
  replaceGetData,
} from "src/functions/replaceBbCodes";
import toaster from "src/hooks/toaster.hook";

const ReadElement = (props) => {
  //Создаем реф для хранения данных
  const text = useRef(props.element);
  //Создаем статус, чтобы понимать когда какой стейт выводить
  const [status, setStatus] = useState(false);

  const refItem = useRef();

  //Переменная для вывода стейта
  let textData;

  //Нужен, чтобы обновлять данные в рефе и они были синхронизированы с textData
  useEffect(() => {
    text.current = props.element;
  }, [props.element]);

  //При ререндере компонента, если мы не редактируем элемент - выводим данные их стейта, если редактируем, то из рефа.
  if (status === false) {
    textData = props.element;
  } else {
    textData = text.current;
  }

  //Изменяем реф при каждом нажатии на клавишу
  let onSaveContent = (e) => {
    textData = e.target.value;
    text.current = e.target.value;
    setStatus(true);
  };

  //Меняем статус, когда элемент редактирования в фокусе
  let onChangeStatus = (e) => {
    setStatus(true);
    const target = refItem.current;
    document.addEventListener("click", clickOutside);

    toaster.notify("Нажмите еще раз, чтобы активировать редактирование", {
      duration: 2000,
    });

    function clickOutside(event) {
      const withinBoundaries = event.composedPath().includes(target);
      if (!withinBoundaries) {
        setStatus(false);
        document.removeEventListener("click", clickOutside, false);
      }
    }
  };

  //Вносим изменения в стейт, при переключении фокуса
  let onChangeText = (e) => {
    let newText = replaceBackData(e.target.innerHTML);
    let name = props.name;
    let element = props.patch;
    props.changeText(newText, element, name);
    setStatus(false);
  };
  //Выбираем имя для элемента
  let tagNameItem = props.tagName ? props.tagName : "div";

  let paragraphsStyles = { display: "block" };
  if (props.withoutParagraphs) {
    paragraphsStyles = { display: "inline" };
  }

  if (props.textarea) {
    return (
      <div style={{ ...paragraphsStyles }} ref={refItem}>
        {status === false && (
          <div
            style={{
              marginBottom: "15px",
              display: "block",
              cursor: "pointer",
              ...paragraphsStyles,
            }}
            onClick={onChangeStatus}
            dangerouslySetInnerHTML={{
              __html: getHtmlInMarkdown(textData, {
                withoutParagraphs: props.withoutParagraphs,
              }),
            }}
          ></div>
        )}

        <ContentEditable
          style={{
            marginBottom: "15px",
            display: status === true ? paragraphsStyles.display : "none",
          }}
          tagName={tagNameItem}
          html={replaceGetData(textData)}
          onChange={onSaveContent}
          onBlur={onChangeText}
        />
      </div>
    );
  }

  return (
    <ContentEditable
      tagName={tagNameItem}
      html={replaceGetData(textData)}
      onChange={onSaveContent}
      onBlur={onChangeText}
    />
  );
};

export default ReadElement;
