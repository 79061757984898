import { nanoid } from "nanoid";
import React, { useContext } from "react";
import { useRef } from "react";
import { connect } from "react-redux";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

let ImgBlockNew = (props) => {
  const { token } = useContext(AuthContext);
  const fileRef = useRef(null);
  const patch = props.patch;
  const site = props.site;
  const dateCode = props.element.dateCode ? props.element.dateCode : "01";
  const version = props.element.imagesVersion ? Number(props.element.imagesVersion) : 0;
  const imgElement = props.noNestedName ? props.origElement : props.element;
  let statusInfo = props.element.status;
  let type = props.element.type;

  // console.log(props.themePrototype);

  let imgName = imgElement[props.namePropertyName];
  if (!imgName) {
    if (imgElement?.imagesName) {
      imgName = imgElement?.imagesName;
    } else {
      //Нужно для совместимости
      imgName = imgElement?.images;
    }
  }

  const imgUrl = props.element.imagesLink
    ? props.element.imagesLink
    : `https://enotznaet.ru/dist/images/${dateCode}/${patch}/orig/${imgName}.jpg`;

  let modificator = "";
  if (statusInfo === true && type && type !== "") {
    modificator = " element__imgBlock--active";
  }

  let changeType = (type, typeName) => {
    let newText = type;
    let name = typeName;
    let element = props.element;
    props.changeText(newText, element, name);
  };

  let changeStatus = () => {
    let newText = true;
    let name = "status";
    let element = props.element;
    props.changeText(newText, element, name);
  };

  const uploadPicturesToTheServer = (file, imgName, callback) => {
    let data = new FormData();
    data.append("file", file);
    data.append("filename", imgName);
    data.append("patch", patch);
    data.append("site", site);
    data.append("id", props.id);
    let body = data;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    fetch(`/api/upload/img`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.status === "error") {
          toaster.notify(result.message, {
            duration: 3000,
          });
          return;
        }
        callback(result);
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let handleSubmit = async (e) => {
    const files = fileRef.current.files;

    for (let i = 0; i < files.length; i++) {
      if (i === 0) {
        uploadPicturesToTheServer(files[i], imgName, (result) => {
          changeStatus();
          changeType(result.type, "type");
          changeType(result.width, "width");
          changeType(result.height, "height");
          changeType(result.url, "imagesLink");
          changeType(version + 1, "imagesVersion");
        });
        if (!props.isMulti) break;
      } else {
        const createdImgName = props.themePrototype.imagesName + nanoid();
        uploadPicturesToTheServer(files[i], createdImgName, (result) => {
          const localPrototype = {
            ...props.themePrototype,
            type: result.type,
            width: result.width,
            height: result.height,
            imagesLink: result.url,
            imagesVersion: 1,
            status: true,
            imagesName: createdImgName,
          };
          props.createNewLastCreatorBlock({ localPrototype });
        });
      }
    }
  };

  return (
    <div className="element__block">
      <span className="element__blockName">{props.blockName}</span>
      <div className={"element__imgBlock" + modificator}>
        {statusInfo === true && type && type !== "" && (
          <div className="element__imgStatus">
            <img
              className="element__imgItem"
              src={imgUrl + "?" + String(version)}
              alt={props.name}
            />
          </div>
        )}
        <input
          readOnly={props.readOnly}
          ref={fileRef}
          accept="image/*"
          type="file"
          id="button-file"
          multiple={props.isMulti ? true : false}
          onChange={handleSubmit}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    id: state.articlesReducer._id,
  };
};

export default connect(mapStateToProps)(ImgBlockNew);
