import React from "react";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { availableArticlesInitialData } from "src/redux/availableArticlesReducer";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";

export const AvailableArticles = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = props.isAdmin;

  const page = Number(searchParams.get("page"));
  const urlProject = searchParams.get("project");
  const urlCategory = searchParams.get("category");
  // const urlType = searchParams.get("type");
  // const urlText = searchParams.get("text");

  const sortProjectOptions = props.themeGlobal.options.projects;
  const sortCategoryOptions = props.themeGlobal.options.category[urlProject];

  const tableNames = [
    "№",
    "Название",
    "Срок",
    "Символы",
    "Проект",
    "Тип",
    "Категории",
    "Взять в работу",
  ];
  const options = props.themeGlobal.options;

  const pagination = {
    page,
    pageName: "page",
    limit: props.pagination.limit,
    next: props.pagination.next,
  };

  let chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      if (type === "project") {
        newParams.delete("category");
      }
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  return (
    <>
      {isAdmin && (
        <div className="articleFilter">
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"project"}
            options={sortProjectOptions}
            value={urlProject}
            placeholder={"Проект"}
            isClearable={true}
            multi={true}
          />
          {sortCategoryOptions !== undefined &&
            urlProject !== null &&
            urlProject !== "all" && (
              <SelectTableBlock
                callBack={chengeFilter}
                callBackData={"category"}
                options={sortCategoryOptions}
                value={urlCategory}
                placeholder={"Категория"}
                isClearable={true}
                multi={true}
              />
            )}
        </div>
      )}
      <div className="articleList">
        {!props.articles.length && <p className="textCenter">Ничего не найдено</p>}

        {props.articles.length > 0 && (
          <>
            <h2 className="articleList__title">Доступные к взятию темы</h2>
            <TableBlock
              deadlineStatus={false}
              isAdmin={isAdmin}
              names={tableNames}
              items={props.articles}
              options={options}
              updater={availableArticlesInitialData}
              chengeFilter={chengeFilter}
              pagination={pagination}
              isLoading={props.isLoading}
              type={"availableArticles"}
            />
          </>
        )}
      </div>
    </>
  );
};
