import { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { Loader } from "src/components/Loader";
import { AuthContext } from "src/context/AuthContext";
import { themeInitialData, themeInitialDataRequest } from "src/redux/themReducer";

const ThemeCreator = ({ children, theme, isAuth }) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();

  const { token, logout } = useContext(AuthContext);

  useEffect(() => {
    dispatch(themeInitialData(token));

    return () => {
      dispatch(themeInitialDataRequest());
    };
  }, [token, dispatch, searchParams]);

  if (theme.isLoading === true && !theme.data) {
    return <Loader type="full" />;
  }

  if (theme.isError === true && isAuth) {
    if (theme.errorCode === 401) {
      logout();
    }
    return (
      <p className="textCenter">
        Возникла ошибка получения данных, попробуйте обновить страничку
      </p>
    );
  }

  return children;
};

const mapStateToProps = (state) => {
  return {
    theme: state.themReducer,
  };
};

export default connect(mapStateToProps)(ThemeCreator);
