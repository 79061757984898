import React from "react";
import ReadElement from "src/components/Content/items/resultBlock/ReadElement";
import getVideoLink from "src/functions/getVideoLink";

const ResultBlockProject1 = (props) => {
  let choicesClass = "";
  if (props.state.choices.name || props.state.choices.items[0]) {
    choicesClass = "";
  } else {
    choicesClass = "none";
  }
  let categoriesClass = "";
  if (props.state.categories.name && props.state.categories.items[0]) {
    categoriesClass = "";
  } else {
    categoriesClass = "none";
  }
  let questionsClass = "";
  if (props.state.questions.items[0]) {
    questionsClass = "";
  } else {
    questionsClass = "none";
  }
  let brandsClass;
  if (props.state.brands.items[0] || props.state.brands.otherBrands) {
    brandsClass = "";
  } else {
    brandsClass = "none";
  }
  let trendsClass = "";
  if (props.state.trends.items[0]) {
    trendsClass = "";
  } else {
    trendsClass = "none";
  }
  let endingClass = "";
  if (props.state.ending.name || props.state.ending.items[0]) {
    endingClass = "";
  } else {
    endingClass = "none";
  }

  const getVersion = (element) => {
    const version = element.imagesVersion ? Number(element.imagesVersion) : 0;

    return String(version);
  };

  const getVideoImg = (element) => {
    if (element.imagesLink) {
      return element.imagesLink + "?" + getVersion(element);
    }

    if (element.images) {
      return (
        `https://enotznaet.ru/dist/images/${
          element.dateCode ? element.dateCode : "01"
        }/previewVideo/orig/${element.images}.jpg` +
        "?" +
        getVersion(element)
      );
    }

    return getVideoLink(element.link);
  };

  return (
    <>
      <h1>
        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"name"}
          element={props.state.main.name}
          patch={props.state.main}
          changeText={props.changeText}
        />
      </h1>

      <ReadElement
        onUpdateCE={props.onUpdateCE}
        tagName="span"
        name={"description"}
        element={props.state.main.description}
        patch={props.state.main}
        changeText={props.changeText}
        textarea={true}
      />

      <ReadElement
        onUpdateCE={props.onUpdateCE}
        tagName="span"
        name={"content"}
        element={props.state.main.content}
        patch={props.state.main}
        changeText={props.changeText}
        textarea={true}
      />

      <span className={choicesClass}>
        <h2>
          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"name"}
            element={props.state.choices.name}
            patch={props.state.choices}
            changeText={props.changeText}
          />
        </h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.choices.top}
          patch={props.state.choices}
          changeText={props.changeText}
          textarea={true}
        />

        <ul>
          {props.state.choices.items.map((item) => (
            <li key={item.id}>
              <span className="bold">
                <ReadElement
                  onUpdateCE={props.onUpdateCE}
                  tagName="span"
                  name={"name"}
                  element={item.name}
                  patch={item}
                  changeText={props.changeText}
                />
                .{" "}
              </span>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"content"}
                element={item.content}
                patch={item}
                changeText={props.changeText}
                textarea={true}
                withoutParagraphs={true}
              />
            </li>
          ))}
        </ul>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.choices.buttom}
          patch={props.state.choices}
          changeText={props.changeText}
          textarea={true}
        />
      </span>
      {props.state.choices.relatedVideos.items.length > 0 && (
        <>
          <span className="bold">Видео по выбору</span>
          <div className="result__videoItems">
            {props.state.choices.relatedVideos.items.map((item) => (
              <span key={item.id}>
                <span className="result__videoItem" key={item.id}>
                  <img
                    className="result__videoImg"
                    src={getVideoImg(item)}
                    alt={props.name}
                  />
                </span>
              </span>
            ))}
          </div>
        </>
      )}

      <span className={categoriesClass}>
        <h2>
          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"name"}
            element={props.state.categories.name}
            patch={props.state.categories}
            changeText={props.changeText}
          />
        </h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.categories.top}
          patch={props.state.categories}
          changeText={props.changeText}
          textarea={true}
        />

        <ul>
          {props.state.categories.items.map((item) => (
            <li key={item.id}>
              <span className="bold">
                <ReadElement
                  onUpdateCE={props.onUpdateCE}
                  tagName="span"
                  name={"name"}
                  element={item.name}
                  patch={item}
                  changeText={props.changeText}
                />{" "}
                -{" "}
              </span>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"content"}
                element={item.content}
                patch={item}
                changeText={props.changeText}
                textarea={true}
                withoutParagraphs={true}
              />
            </li>
          ))}
        </ul>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.categories.buttom}
          patch={props.state.categories}
          changeText={props.changeText}
          textarea={true}
        />
      </span>

      <span className={trendsClass}>
        <h2>Тренды</h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.trends.top}
          patch={props.state.trends}
          changeText={props.changeText}
          textarea={true}
        />

        {props.state.trends.items.map((item) => (
          <span key={item.id}>
            <h3>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"name"}
                element={item.name}
                patch={item}
                changeText={props.changeText}
              />
            </h3>
            <ReadElement
              onUpdateCE={props.onUpdateCE}
              tagName="span"
              name={"content"}
              element={item.content}
              patch={item}
              changeText={props.changeText}
              textarea={true}
            />
          </span>
        ))}

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.trends.buttom}
          patch={props.state.trends}
          changeText={props.changeText}
          textarea={true}
        />
      </span>

      <span className={questionsClass}>
        <h2>Часто задаваемые вопросы</h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.questions.top}
          patch={props.state.questions}
          changeText={props.changeText}
          textarea={true}
        />

        {props.state.questions.items.map((item) => (
          <span key={item.id}>
            <h3>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"name"}
                element={item.name}
                patch={item}
                changeText={props.changeText}
              />
            </h3>

            <ReadElement
              onUpdateCE={props.onUpdateCE}
              tagName="span"
              name={"content"}
              element={item.content}
              patch={item}
              changeText={props.changeText}
              textarea={true}
            />
          </span>
        ))}

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.questions.buttom}
          patch={props.state.questions}
          changeText={props.changeText}
          textarea={true}
        />
      </span>

      <span className={brandsClass}>
        <h2>Популярные бренды</h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.brands.top}
          patch={props.state.brands}
          changeText={props.changeText}
          textarea={true}
        />

        <ul>
          {props.state.brands.items.map((item) => (
            <li key={item.id}>
              <span className="bold">
                <ReadElement
                  onUpdateCE={props.onUpdateCE}
                  tagName="span"
                  name={"name"}
                  element={item.name}
                  patch={item}
                  changeText={props.changeText}
                />{" "}
                -{" "}
              </span>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"content"}
                element={item.content}
                patch={item}
                changeText={props.changeText}
                textarea={true}
                withoutParagraphs={true}
              />
            </li>
          ))}
        </ul>
        <p>
          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"otherBrands"}
            element={props.state.brands.otherBrands}
            patch={props.state.brands}
            changeText={props.changeText}
          />
        </p>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.brands.buttom}
          patch={props.state.brands}
          changeText={props.changeText}
          textarea={true}
        />
      </span>

      {props.state.products.items.map((item) => (
        <span key={item.id}>
          <h2>
            <ReadElement
              onUpdateCE={props.onUpdateCE}
              tagName="span"
              name={"name"}
              element={item.name}
              patch={item}
              changeText={props.changeText}
            />
          </h2>

          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"content"}
            element={item.content}
            patch={item}
            changeText={props.changeText}
            textarea={true}
          />

          {item.items.map((item) => (
            <div
              key={item.id}
              className={`result__product ${
                item?.config?.imported ? "result__product--imported" : ""
              }`}
            >
              {item.images.items[0] && item.images.items[0].status === true && (
                <div className="result__productImgWraper">
                  <img
                    className="result__productImg"
                    src={
                      item.images.items[0].imagesLink
                        ? item.images.items[0].imagesLink +
                          "?" +
                          getVersion(item.images.items[0])
                        : `https://enotznaet.ru/dist/images/${
                            item.images.items[0].dateCode
                              ? item.images.items[0].dateCode
                              : "01"
                          }/product/orig/${item.images.items[0].images}.jpg?${getVersion(
                            item.images.items[0]
                          )}`
                    }
                    alt={props.name}
                  />
                </div>
              )}

              <h3>
                <ReadElement
                  onUpdateCE={props.onUpdateCE}
                  tagName="span"
                  name={"name"}
                  element={item.name}
                  patch={item}
                  changeText={props.changeText}
                />
              </h3>
              <p>
                {item.brand && (
                  <span>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"brand"}
                      element={item.brand}
                      patch={item}
                      changeText={props.changeText}
                    />
                    ,&nbsp;
                  </span>
                )}
                {item.country && (
                  <span>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"country"}
                      element={item.country}
                      patch={item}
                      changeText={props.changeText}
                    />
                    ,&nbsp;
                  </span>
                )}
                {item.price && (
                  <span>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"price"}
                      element={item.price}
                      patch={item}
                      changeText={props.changeText}
                    />
                    ,&nbsp;
                  </span>
                )}
                {item.feature && (
                  <span>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"feature"}
                      element={item.feature}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </span>
                )}
              </p>

              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"content"}
                element={item.content}
                patch={item}
                changeText={props.changeText}
                textarea={true}
              />

              {item.positive && (
                <>
                  <span className="bold">Плюсы</span>
                  <p>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"positive"}
                      element={item.positive}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </p>
                </>
              )}
              {item.negative && (
                <>
                  <span className="bold">Минусы</span>
                  <p>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"negative"}
                      element={item.negative}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </p>
                </>
              )}
              {item.specifications.items.length > 0 && (
                <>
                  <span className="bold">Характеристики</span>
                  {item.specifications.items.map((item) => (
                    <span key={item.id}>
                      <p>
                        {item.name}:{" "}
                        <ReadElement
                          onUpdateCE={props.onUpdateCE}
                          tagName="span"
                          name={"content"}
                          element={item.content}
                          patch={item}
                          changeText={props.changeText}
                        />
                      </p>
                    </span>
                  ))}
                </>
              )}
              {item.video.items.length > 0 && (
                <>
                  <span className="bold">Видео</span>
                  <div className="result__videoItems">
                    {item.video.items.map((item) => (
                      <span key={item.id}>
                        <span className="result__videoItem" key={item.id}>
                          <img
                            className="result__videoImg"
                            src={getVideoImg(item)}
                            alt={props.name}
                          />
                        </span>
                      </span>
                    ))}
                  </div>
                </>
              )}
            </div>
          ))}
        </span>
      ))}

      <span className={endingClass}>
        <h2>
          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"name"}
            element={props.state.ending.name}
            patch={props.state.ending}
            changeText={props.changeText}
          />
        </h2>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"top"}
          element={props.state.ending.top}
          patch={props.state.ending}
          changeText={props.changeText}
          textarea={true}
        />

        <h3>
          <ReadElement
            onUpdateCE={props.onUpdateCE}
            tagName="span"
            name={"tableName"}
            element={props.state.ending.tableName}
            patch={props.state.ending}
            changeText={props.changeText}
          />
        </h3>
        <ul>
          {props.state.ending.items.map((item) => (
            <li key={item.id}>
              <span className="bold">
                <ReadElement
                  onUpdateCE={props.onUpdateCE}
                  tagName="span"
                  name={"name"}
                  element={item.name}
                  patch={item}
                  changeText={props.changeText}
                />{" "}
              </span>
              <ReadElement
                onUpdateCE={props.onUpdateCE}
                tagName="span"
                name={"content"}
                element={item.content}
                patch={item}
                changeText={props.changeText}
                textarea={true}
                withoutParagraphs={true}
              />
            </li>
          ))}
        </ul>

        <ReadElement
          onUpdateCE={props.onUpdateCE}
          tagName="span"
          name={"buttom"}
          element={props.state.ending.buttom}
          patch={props.state.ending}
          changeText={props.changeText}
          textarea={true}
        />
      </span>

      {props.state.videos.items.length > 0 && (
        <>
          <span className="bold">Видео к статье</span>
          <div className="result__videoItems">
            {props.state.videos.items.map((item) => (
              <span key={item.id}>
                <span className="result__videoItem" key={item.id}>
                  <img
                    className="result__videoImg"
                    src={getVideoImg(item)}
                    alt={props.name}
                  />
                </span>
              </span>
            ))}
          </div>
        </>
      )}

      <h2>Сравнительная таблица</h2>

      <div className="result__intro">
        Проверьте все сравнительные характеристики, плюсы и минусы товаров по таблице,
        удобно ли по ней ориентироваться? Правильно ли заполнены все поля? Если таблица
        слишком большая, ее можно скролить.
      </div>

      {props.state.products.items.map((item) => (
        <div className="tableScroll" key={item.id}>
          <h3>{item.name}</h3>
          <table className="resultTable">
            <thead>
              <tr>
                <th>{"Картинка"}</th>
                <th>{"Название"}</th>
                <th>{"Плюсы"}</th>
                <th>{"Минусы"}</th>
                {props.state.specifications.statusBrand && <th>{"Бренд"}</th>}
                {props.state.specifications.statusCountry && <th>{"Страна"}</th>}
                {props.state.specifications.statusPrice && <th>{"Цена"}</th>}
                <th>{"Рейтинг"}</th>
                <th>{"Особенность"}</th>
                {props.state.specifications.items.length > 0 && (
                  <>
                    {props.state.specifications.items.map((item) => (
                      <th key={item.id}>{item.name}</th>
                    ))}
                  </>
                )}
              </tr>
            </thead>
            <tbody>
              {item.items.map((item) => (
                <tr key={item.id}>
                  <td>
                    {item.images.items[0] && item.images.items[0].status === true && (
                      <div className="resultTable__img">
                        <img
                          className="resultTable__imgItem"
                          src={
                            item.images.items[0].imagesLink
                              ? item.images.items[0].imagesLink +
                                "?" +
                                getVersion(item.images.items[0])
                              : `https://enotznaet.ru/dist/images/${
                                  item.images.items[0].dateCode
                                    ? item.images.items[0].dateCode
                                    : "01"
                                }/product/orig/${
                                  item.images.items[0].images
                                }.jpg?${getVersion(item.images.items[0])}`
                          }
                          alt={props.name}
                        />
                      </div>
                    )}
                  </td>
                  <td>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"name"}
                      element={item.name}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </td>
                  <td>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"positive"}
                      element={item.positive}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </td>
                  <td>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"negative"}
                      element={item.negative}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </td>
                  {props.state.specifications.statusBrand && (
                    <td>
                      <ReadElement
                        onUpdateCE={props.onUpdateCE}
                        tagName="span"
                        name={"brand"}
                        element={item.brand}
                        patch={item}
                        changeText={props.changeText}
                      />
                    </td>
                  )}
                  {props.state.specifications.statusCountry && (
                    <td>
                      <ReadElement
                        onUpdateCE={props.onUpdateCE}
                        tagName="span"
                        name={"country"}
                        element={item.country}
                        patch={item}
                        changeText={props.changeText}
                      />
                    </td>
                  )}
                  {props.state.specifications.statusPrice && (
                    <td>
                      <ReadElement
                        onUpdateCE={props.onUpdateCE}
                        tagName="span"
                        name={"price"}
                        element={item.price}
                        patch={item}
                        changeText={props.changeText}
                      />
                    </td>
                  )}
                  <td>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"rating"}
                      element={item.rating}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </td>
                  <td>
                    <ReadElement
                      onUpdateCE={props.onUpdateCE}
                      tagName="span"
                      name={"feature"}
                      element={item.feature}
                      patch={item}
                      changeText={props.changeText}
                    />
                  </td>
                  {item.specifications.items.length > 0 && (
                    <>
                      {item.specifications.items.map((item) => (
                        <td key={item.id}>
                          <ReadElement
                            onUpdateCE={props.onUpdateCE}
                            tagName="span"
                            name={"content"}
                            element={item.content}
                            patch={item}
                            changeText={props.changeText}
                          />
                        </td>
                      ))}
                    </>
                  )}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      ))}
    </>
  );
};

export default ResultBlockProject1;
