import { useContext, useEffect, useState } from "react";
import { Loader } from "src/components/Loader";
import SelectItems from "src/components/other/SelectItems";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const ItemsSelectBlock = ({ project, type, name, item, element, actions, readOnly }) => {
  const { token } = useContext(AuthContext);

  const [selectData, setSelectData] = useState({
    isLoading: true,
    isError: false,
    data: null,
  });

  const getSelectData = async (token, project, type) => {
    if (!element[item]?.length || element[item].length < 1) {
      return setSelectData({ isLoading: false, isError: false, data: [] });
    }

    const ids = element[item]?.map((item) => item._id);

    let params = {
      project,
      type,
      ids,
    };

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    fetch("/api/items/all", { method: "POST", body: params, headers })
      .then((res) => res.json())
      .then((result) => {
        const items = result?.data.map((item) => {
          return {
            _id: item._id,
            value: item.id,
            label: item.name,
          };
        });

        if (items?.length !== 0 && !items?.length) {
          setSelectData({ isLoading: false, isError: true, data: null });
        }

        setSelectData({ isLoading: false, isError: false, data: items });
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  const changeSelectItems = (value) => {
    value = value.map((item) => {
      return { _id: item._id, id: item.value };
    });

    actions.changeText(value, element, item);
  };

  useEffect(() => {
    getSelectData(token, project, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, project, type]);

  if (selectData.isLoading === true) {
    return <Loader />;
  }

  if (selectData.isError) {
    return <p>Ошибка загрузки данных.</p>;
  }

  return (
    <div className="element__block">
      <span className="element__blockName">{name}</span>

      <SelectItems
        classNames="element__select"
        value={selectData.data}
        project={project}
        type={type}
        setMainState={changeSelectItems}
        valueName="id"
        readOnly={readOnly}
      />
    </div>
  );
};

export default ItemsSelectBlock;
