import React, { useContext } from "react";
import BlockItems from "src/components/Content/items/items/BlockItems";
import { CreatorBlock } from "src/components/Content/items/items/creatorBlock/CreatorBlock";
import { AuthContext } from "src/context/AuthContext";
import launchCreatorActions from "src/functions/launchCreatorActions";

const Block = (props) => {
  const { token } = useContext(AuthContext);

  //Получаем место в массиве у данного элемента
  let elementID = props.parentElement.items.indexOf(props.element) + 1;

  //Кликаем на кнопку показа/скрытия элемента
  let onClickHiddenElement = () => {
    let element = props.element;
    props.clickHiddenElementItem(element, props.parentElement);
  };

  //Кликаем на кнопку показа/скрытия доп данных
  let onClickHiddenButton = () => {
    let element = props.element;
    props.clickHiddenButton(element);
  };

  let itemHiddenStatus = false;
  if (props.themeItemsHidden && props.themeItemsHidden.length > 0) {
    itemHiddenStatus = true;
  }

  let importedClass = "";
  if (props.element?.config?.imported) {
    importedClass = " element__item--imported";
  }

  const themeClass = props.theme?.class || "";

  //Скрываем и показываем блок элемента
  let ShowHiddenElementClass = "element__item " + themeClass + importedClass;
  let HiddenElementText = "-";
  if (props.element.config.hidden === true) {
    ShowHiddenElementClass = "element__item element--hide " + themeClass + importedClass;
    HiddenElementText = "+";
  }

  //Скрываем и показываем блок с доп данными в зависимости от стейта
  let ShowHiddenBlockClass = "element__hidden";
  let blockHiddenText = "Дополнительные элементы";
  if (props.element.config.hiddenItems === false) {
    ShowHiddenBlockClass = "element__hidden element__hidden--active";
    blockHiddenText = "Скрыть дополнительные элементы";
  }

  let controlsStatus = true;
  if (props.controls === false) {
    controlsStatus = false;
  }

  let name = props.name;
  if (props.nameItems && props.nameItems.length > 0) {
    name = "";
    props.nameItems.forEach((item) => {
      if (item.type === "element") {
        name = name + String(props.element[item.value]);
      } else {
        name = name + item.value;
      }
    });
  }

  let nameClass = "element__titleLeft";
  if (props.nameSize) {
    switch (props.nameSize) {
      case "small":
        nameClass = nameClass + " element__titleLeft--small";
        break;
      default:
        break;
    }
  }

  const arrayId = props.parentElement.items.indexOf(props.element);

  const creatorActionsDataArray = [
    props.actions,
    props.actionsOfChange,
    props.uniqueActionsOfChange,
    props.element,
    props.parentElement,
    props.articleState,
    props.parentCreatorBlocks,
    arrayId,
  ];

  let readOnlyImportItemsStatus = props.readOnlyImportItemsStatus ?? false;
  if (props.theme?.globalItemsConfig?.readOnly && props.element?.config?.itemId) {
    readOnlyImportItemsStatus = true;
  }

  //Действия по кнопкам справа
  let onClickTop = () => {
    let element = props.element;
    let parentElement = props.parentElement;
    props.clickTop(element, parentElement);
    launchCreatorActions("top", ...creatorActionsDataArray);
  };
  let onClickDown = () => {
    let element = props.element;
    let parentElement = props.parentElement;
    props.clickDown(element, parentElement);
    launchCreatorActions("down", ...creatorActionsDataArray);
  };
  let onClickCopy = () => {
    let element = props.element;
    let parentElement = props.parentElement;
    props.clickCopy(element, parentElement);
    launchCreatorActions("copy", ...creatorActionsDataArray);
  };
  let onClickCopyAdmin = () => {
    let element = props.element;
    let parentElement = props.parentElement;
    props.clickCopy(element, parentElement, "copy");
    launchCreatorActions("copy", ...creatorActionsDataArray);
  };
  let onClickDel = async () => {
    let element = props.element;
    let parentElement = props.parentElement;
    await removeAllPicturesInsideTheBlock();
    props.clickDel(element, parentElement);
    launchCreatorActions("del", ...creatorActionsDataArray);
  };

  const removeAllPicturesInsideTheBlock = async () => {
    const removeImagesList = getAllRemovePictures(props.element, props.themeItems);

    if (removeImagesList.length > 0) {
      let data = new FormData();
      data.append("links", removeImagesList);
      data.append("site", props.site);
      let body = data;
      let headers = {
        Authorization: `Bearer ${token}`,
      };

      let res = await fetch(`/api/upload/removeImg`, {
        method: "POST",
        body,
        headers,
      });
      if (res.ok) {
        let json = await res.json();
        if (json.status === "error") {
          new Error("Возникла ошибка, попробуйте еще раз.");
        }
      } else {
        new Error("Возникла ошибка, попробуйте еще раз.");
      }
    }

    function getAllRemovePictures(element, theme, removeImagesList = []) {
      if (element?.config?.imported) {
        return removeImagesList;
      }

      theme.forEach((item) => {
        if (item.type === "block") {
          item.items.forEach((item) => {
            if (item.type === "imgBlock") {
              const imgData = item.nestedElement ? element[item.nestedElement] : element;

              const imgName = imgData[item.imgName];
              const patch = item.patch;
              const dateCode = imgData.dateCode ? imgData.dateCode : "01";

              const imgUrl =
                imgData.imagesLink ??
                `https://enotznaet.ru/dist/images/${dateCode}/${patch}/orig/${imgName}.jpg`;

              removeImagesList.push(imgUrl);
            }
          });
        } else if (item.type === "creatorBlock") {
          let elementItems;
          if (item.itemsName) {
            elementItems = element[item.itemsName].items;
          } else {
            elementItems = element.items;
          }

          if (elementItems) {
            elementItems.forEach((elementItem) => {
              getAllRemovePictures(elementItem, item.items, removeImagesList);
            });
          }
        }
      });
      return removeImagesList;
    }
  };

  let BlockContent = props.themeItems.map((item, index) => {
    let access = item.access;
    let adminStatus = props.isAdmin;
    let accessStatus = true;
    if ((access === "admin") & (adminStatus !== true)) {
      accessStatus = false;
    }
    if (!accessStatus) {
      return "";
    }

    if (item.type === "block") {
      return (
        <BlockItems
          key={index}
          item={item}
          element={props.element}
          parentCreatorBlocks={props.parentCreatorBlocks}
          parentElement={props.parentElement}
          actions={props.actions}
          site={props.site}
          isAdmin={props.isAdmin}
          articleState={props.articleState}
          charactersItemsName={props.charactersItemsName}
          сharactersGlobal={props.сharactersGlobal}
          level={props.level + 1}
          readOnlyImportItemsStatus={readOnlyImportItemsStatus}
          themePrototype={props.themePrototype}
          createNewLastCreatorBlock={props.createNewLastCreatorBlock}
        />
      );
    } else if (item.type === "creatorBlock") {
      return (
        <CreatorBlock
          key={index}
          actions={props.actions}
          state={props.element}
          parentCreatorBlocks={props.parentCreatorBlocks}
          theme={item}
          themeItems={item.items}
          themeItemsHidden={item.itemsHidden}
          themePrototype={item.prototype}
          site={props.site}
          itemsName={item.itemsName}
          name={item.name}
          nameItems={item.nameItems}
          nameSize={item.nameSize}
          uniqueActionsOfChange={item.uniqueActions}
          actionsOfChange={item.actions}
          exactDependencies={item.exactDependencies}
          copy={item.copy}
          isAdmin={props.isAdmin}
          articleState={props.articleState}
          charactersItemsName={props.charactersItemsName}
          сharactersGlobal={props.сharactersGlobal}
          level={props.level + 1}
          addSpecificationsToPrototype={item.addSpecificationsToPrototype}
          specificationsOfChildren={item.specificationsOfChildren}
          globalItemsConfig={item.globalItemsConfig}
          readOnlyImportItemsStatus={readOnlyImportItemsStatus}
        />
      );
    }
    return "";
  });

  let HiddenBlockContent = "";
  if (itemHiddenStatus) {
    HiddenBlockContent = props.themeItemsHidden.map((item, index) => {
      let access = item.access;
      let adminStatus = props.isAdmin;
      let accessStatus = true;
      if ((access === "admin") & (adminStatus !== true)) {
        accessStatus = false;
      }
      if (!accessStatus) {
        return "";
      }

      if (item.type === "block") {
        return (
          <BlockItems
            key={index}
            item={item}
            element={props.element}
            parentCreatorBlocks={props.parentCreatorBlocks}
            actions={props.actions}
            site={props.site}
            isAdmin={props.isAdmin}
            articleState={props.articleState}
            charactersItemsName={props.charactersItemsName}
            сharactersGlobal={props.сharactersGlobal}
            level={props.level}
            readOnlyImportItemsStatus={readOnlyImportItemsStatus}
            themePrototype={props.themePrototype}
            createNewLastCreatorBlock={props.createNewLastCreatorBlock}
          />
        );
      } else if (item.type === "creatorBlock") {
        return (
          <CreatorBlock
            key={index}
            actions={props.actions}
            state={props.state}
            parentCreatorBlocks={props.parentCreatorBlocks}
            theme={item}
            themeItems={item.items}
            themeItemsHidden={item.itemsHidden}
            themePrototype={item.prototype}
            name={item.name}
            nameItems={item.nameItems}
            nameSize={item.nameSize}
            uniqueActionsOfChange={item.uniqueActions}
            actionsOfChange={item.actions}
            exactDependencies={item.exactDependencies}
            creator={item.creator}
            copy={item.copy}
            site={props.site}
            itemsName={item.itemsName}
            articleState={props.articleState}
            charactersItemsName={props.charactersItemsName}
            сharactersGlobal={props.сharactersGlobal}
            level={props.level + 1}
            addSpecificationsToPrototype={item.addSpecificationsToPrototype}
            specificationsOfChildren={item.specificationsOfChildren}
            globalItemsConfig={item.globalItemsConfig}
            readOnlyImportItemsStatus={readOnlyImportItemsStatus}
          />
        );
      }
      return "";
    });
  }

  return (
    <div className={ShowHiddenElementClass}>
      <div className="element__title element__title--group">
        <div className={nameClass}>
          {elementID + ". "}
          {name}
          <div onClick={onClickHiddenElement} className="element__hiddenElement">
            {HiddenElementText}
          </div>
        </div>
        {controlsStatus && (
          <div className="element__titleRight">
            {props.element?.config?.itemId && (
              <a
                target="_blank"
                rel="noreferrer"
                className="element__hiddenElement element__hiddenElement--text nolink"
                href={`/item/${props.element?.config?.itemId}`}
              >
                Открыть
              </a>
            )}
            {!props.readOnlyImportItemsStatus && (
              <>
                <div onClick={onClickTop} className="element__hiddenElement">
                  ▲
                </div>
                <div onClick={onClickDown} className="element__hiddenElement">
                  ▼
                </div>
                <div
                  onClick={onClickCopy}
                  className="element__hiddenElement element__hiddenElement--text"
                >
                  ✂
                </div>
              </>
            )}
            {props.isAdmin === true && (
              <div
                onClick={onClickCopyAdmin}
                className="element__hiddenElement element__hiddenElement--text"
              >
                К
              </div>
            )}
            {!props.readOnlyImportItemsStatus && (
              <div
                onClick={onClickDel}
                className="element__hiddenElement element__hiddenElement--text"
              >
                X
              </div>
            )}
          </div>
        )}
      </div>

      {BlockContent}

      {itemHiddenStatus && (
        <div className="element__blockHidden">
          <div className={ShowHiddenBlockClass}>{HiddenBlockContent}</div>
          <div onClick={onClickHiddenButton} className="element__hiddenButton button">
            {blockHiddenText}
          </div>
        </div>
      )}
    </div>
  );
};

export default Block;
