import Select from "react-select";
import React from "react";

const SelectBlock = (props) => {
  //Вносим изменения в текст
  let onChangeSelect = (date) => {
    let newText = "";
    if (props.multi) {
      if (date && typeof date === "object") {
        date.forEach((element) => {
          if (element.value !== undefined) {
            newText += element.value + ",";
          }
        });
        newText = newText.substring(0, newText.length - 1);
      }
    } else {
      newText = date.value;
    }

    let name = props.item;
    //Получаем название вложенного объекта в state
    let parent = null;
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.changeText(newText, element, name);
  };

  //Совмещаем значение из поле с оциями, чтобы верно отобразить текущую опцию
  let selectValue;
  let selectValueItems;
  if (props.multi) {
    if (props.value) {
      selectValue = [];
      selectValueItems = props.value.split(",");
      selectValueItems.forEach((element) => {
        selectValue.push(props.options.find((item) => item.value === element));
      });
    }
  } else {
    selectValue = props.options.find((item) => item.value === props.value);
  }

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <Select
        className="element__select"
        value={selectValue}
        onChange={onChangeSelect}
        options={props.options}
        isMulti={props.multi}
        closeMenuOnSelect={props.closeMenuOnSelect}
        placeholder="Выбор"
        isDisabled={props.readOnly}
      />
    </div>
  );
};

export default SelectBlock;
