const DASHBOARD_INITIAL_DATA_REQUEST_REQUEST = "DASHBOARD_INITIAL_DATA_REQUEST_REQUEST";
const DASHBOARD_INITIAL_DATA_REQUEST_SUCCESS = "DASHBOARD_INITIAL_DATA_REQUEST_SUCCESS";
const DASHBOARD_INITIAL_DATA_REQUEST_ERROR = "DASHBOARD_INITIAL_DATA_REQUEST_ERROR";

let initialState = {
  data: null,
  pagination: null,
  activeUserId: null,
  isLoading: true,
  isError: false,
  error: null,
  errorCode: null,
};

const dashboardReducer = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case DASHBOARD_INITIAL_DATA_REQUEST_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case DASHBOARD_INITIAL_DATA_REQUEST_SUCCESS:
      //Проверяем, вернулись ли верные данные
      if (payload.data) {
        //Если в локальном хранилище версию новее, получаем данные оттуда
        state.data = payload.data;
        state.activeUserId = payload.activeUserId;
        state.pagination = payload.pagination;

        state.isError = false;
        state.isLoading = false;
        state.errorCode = null;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
      }
      return state;

    case DASHBOARD_INITIAL_DATA_REQUEST_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    default:
      return state;
  }
};

export const dashboardInitialData = (token, params) => {
  return (dispatch) => {
    dispatch(dashboardInitialDataRequest());

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/dashboard`, { method: "POST", body: params, headers })
      .then((res) => res.json())
      .then((result) => {
        dispatch(dashboardInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(dashboardInitialDataError(err));
      });
  };
};

export const dashboardInitialDataRequest = () => ({
  type: DASHBOARD_INITIAL_DATA_REQUEST_REQUEST,
});

export const dashboardInitialDataSuccess = (data) => ({
  type: DASHBOARD_INITIAL_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const dashboardInitialDataError = (error) => ({
  type: DASHBOARD_INITIAL_DATA_REQUEST_ERROR,
  payload: error,
});

export default dashboardReducer;
