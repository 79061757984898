import "./timelineTune.css";

class TimelineTune {
  static get isTune() {
    return true;
  }

  constructor({ api, data, block }) {
    this.api = api;
    this.data = data || { timelineStatus: false };
    this.block = block;
    this.icon = `<svg height="14px" viewBox="0 -10 511.99143 511" width="14px" xmlns="http://www.w3.org/2000/svg"><path d="m510.652344 185.882812c-3.371094-10.367187-12.566406-17.707031-23.402344-18.6875l-147.796875-13.417968-58.410156-136.75c-4.3125-10.046875-14.125-16.53125-25.046875-16.53125s-20.738282 6.484375-25.023438 16.53125l-58.410156 136.75-147.820312 13.417968c-10.835938 1-20.011719 8.339844-23.402344 18.6875-3.371094 10.367188-.257813 21.738282 7.9375 28.925782l111.722656 97.964844-32.941406 145.085937c-2.410156 10.667969 1.730468 21.699219 10.582031 28.097656 4.757813 3.457031 10.347656 5.183594 15.957031 5.183594 4.820313 0 9.644532-1.28125 13.953125-3.859375l127.445313-76.203125 127.421875 76.203125c9.347656 5.585938 21.101562 5.074219 29.933593-1.324219 8.851563-6.398437 12.992188-17.429687 10.582032-28.097656l-32.941406-145.085937 111.722656-97.964844c8.191406-7.1875 11.308594-18.535156 7.9375-28.925782zm-252.203125 223.722657"/></svg>`;
  }

  wrap(blockContent) {
    this.wrapper = document.createElement("div");
    this.wrapper.classList.add("timelineBlock");

    if (this.data.timelineStatus === true) {
      const iconBlock = document.createElement("div");
      iconBlock.classList.add("timelineBlock__icon");
      iconBlock.innerHTML = this.icon;
      this.wrapper.append(iconBlock);
    }

    this.wrapper.append(blockContent);
    return this.wrapper;
  }

  render() {
    const button = document.createElement("button");

    button.classList.add(this.api.styles.settingsButton);

    button.classList.toggle(
      this.api.styles.settingsButtonActive,
      this.data.timelineStatus === true
    );

    button.innerHTML = this.icon;

    button.addEventListener("click", () => {
      this.data = {
        timelineStatus: this.data.timelineStatus ? false : true,
      };
      button.classList.toggle(
        this.api.styles.settingsButtonActive,
        this.data.timelineStatus === true
      );

      if (this.data.timelineStatus === true) {
        const createIconBlock = document.createElement("div");
        createIconBlock.classList.add("timelineBlock__icon");
        createIconBlock.innerHTML = this.icon;
        this.wrapper.append(createIconBlock);
      } else {
        const iconBlock = this.wrapper.querySelector(".timelineBlock__icon");
        if (iconBlock) iconBlock.remove();
      }
    });

    this.api.tooltip.onHover(button, this.api.i18n.t("Вывести в ленту"));

    return button;
  }

  save() {
    return this.data;
  }
}

export default TimelineTune;
