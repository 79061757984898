import React from "react";
import InputBlock from "src/components/Content/items/items/InputBlock";

const SpecificationsBlockItems = (props) => {
  let Items = props.items.map((item, index) => {
    return (
      <InputBlock
        key={item.globalId}
        actionsReducer={props.actions}
        parentCreatorBlocks={props.parentCreatorBlocks}
        changeText={props.changeText}
        onChangeActionsStatus={item.onChangeActionsStatus}
        name={item.name}
        item="content"
        value={item.content}
        element={item}
        type="text"
        readOnly={props.readOnly}
      />
    );
  });

  return <>{Items}</>;
};

export default SpecificationsBlockItems;
