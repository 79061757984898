const ETIMEDOUT = "Request timed out.";

async function post(params) {
  const form = new URLSearchParams(params.form);
  const url = `https://${params.host}${params.path}`;

  const options = {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: form,
  };

  let controller = new AbortController();
  const timeoutId = setTimeout(() => controller.abort(), params.timeout || 5000);

  try {
    const response = await fetch(url, { ...options, signal: controller.signal });
    clearTimeout(timeoutId);

    const textBuffer = await response.text();

    let json;
    try {
      json = JSON.parse(textBuffer);
    } catch (e) {
      throw new Error(
        `Yandex.Speller API:\n-------------------\nStatus code: ${response.status}\nResponse: ${textBuffer}\nJSON.parse(Response): ${e}\n`
      );
    }

    return json;
  } catch (e) {
    if (e.name === "AbortError") {
      throw new Error(ETIMEDOUT);
    } else {
      throw e;
    }
  }
}

function postRetryingDecorator(params, callback) {
  let tryNumber = 1;
  let requestLimit = params.requestLimit || 2;
  let timeout = params.timeout || 500;

  const attempt = () => {
    post(params)
      .then((result) => callback(null, { statusCode: 200 }, result))
      .catch((err) => {
        if (err.message === ETIMEDOUT && tryNumber <= requestLimit) {
          tryNumber++;
          setTimeout(attempt, timeout);
        } else {
          callback(err);
        }
      });
  };

  attempt();
}

module.exports = postRetryingDecorator;
