import { getMaxValue } from "src/functions/getMaxValue";

//Получаем самый большой id в массиве элементов
export function newId(pastElementArray) {
  //Получаем id братьев
  let idis = pastElementArray.map((item) => item.id);
  //Получаем максимальный id уже существующих объектов
  let id = getMaxValue(idis);
  // Если объекты уже были, то прибавляем к масимальному, если нет, задаем 1
  if (id) {
    id = id + 1;
  } else {
    id = 1;
  }
  return id;
}
