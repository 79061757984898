import { IsJsonString } from "src/functions/IsJsonString";
import { newId } from "src/functions/newId";
import { swapArray } from "src/functions/swapArray";
import { getAllChartsInEditor } from "src/functions/unique/getAllCharts";
import { nanoid } from "nanoid";
import toaster from "src/hooks/toaster.hook";

const CREATE_INITIAL_DATA_REQUEST_REQUEST = "CREATE_INITIAL_DATA_REQUEST_REQUEST";
const CREATE_INITIAL_DATA_REQUEST_SUCCESS = "CREATE_INITIAL_DATA_REQUEST_SUCCESS";
const CREATE_INITIAL_DATA_REQUEST_ERROR = "CREATE_INITIAL_DATA_REQUEST_ERROR";
const CREATE_CHANGE_TEXT = "CREATE_CHANGE_TEXT";
const CREATE_PAST_STATE = "CREATE_PAST_STATE";
const CREATE_HIDDEN_ELEMENT = "CREATE_HIDDEN_ELEMENT";
const CREATE_CLICK_HIDDEN_BUTTON = "CREATE_CLICK_HIDDEN_BUTTON";
const CREATE_UPDATE_EDITOR = "CREATE_UPDATE_EDITOR";
const CREATE_ADD_ACTICLE = "CREATE_ADD_ACTICLE";
const CREATE_UPDATE_LIST = "CREATE_UPDATE_LIST";
const CREATE_ADD_ITEM_NEW = "CREATE_ADD_ITEM_NEW";
const CREATE_TOP = "CREATE_TOP";
const CREATE_DOWN = "CREATE_DOWN";
const CREATE_COPY = "CREATE_COPY";
const CREATE_DEL = "CREATE_DEL";
const CREATE_PAST_ELEMENT = "CREATE_PAST_ELEMENT";
const CREATE_ADD_ACTICLES = "CREATE_ADD_ACTICLES";

//Добавляем во вставляемый state новые элементы на основе плана статьи
function addPlanItems(state) {
  //Добавляем новый материал, в зависимости от перадаваемого типа
  function getPrototypeItem(type, state, action) {
    let mainObject = "";
    let prototype = "";
    let thisObject = "";

    let todayDate = "01";

    switch (type) {
      case "Choice":
        mainObject = "choices";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          percent: "",
          config: {
            type: "choice",
          },
        };
        break;

      case "Ending":
        mainObject = "ending";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          config: {
            type: "endingItem",
          },
        };
        break;

      case "Categories":
        mainObject = "categories";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          images: "",
          imagesName: "c_" + state.data.main.id + "_" + nanoid(),
          config: {
            type: "category",
          },
        };
        break;

      case "Trends":
        mainObject = "trends";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          images: "",
          imagesName: "t_" + state.data.main.id + nanoid(),
          config: {
            type: "trend",
          },
        };
        break;

      case "Questions":
        mainObject = "questions";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          hidden: false,
          config: {
            type: "question",
            hidden: false,
          },
        };
        break;

      case "Brands":
        mainObject = "brands";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          country: "",
          content: "",
          dateCode: todayDate,
          config: {
            type: "brand",
          },
        };
        break;

      case "Products":
        mainObject = "products";
        thisObject = state.data[mainObject];
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          content: "",
          dateCode: todayDate,
          items: [],
          relatedArticles: {
            items: [],
            config: {
              hidden: false,
              hiddenItems: false,
              type: "relatedArticles",
              typeItems: "articleItem",
            },
          },
          relatedVideo: [],
          config: {
            hidden: false,
            hiddenItems: true,
            hiddenInnerBlocks: false,
            type: "productsCat",
            typeItems: "product",
          },
        };
        break;
      case "ProductItems":
        //Задаем объект первого уровня
        mainObject = "products";
        thisObject = state.data[mainObject].items.find(
          (item) => item.id === action.position.id
        );
        prototype = {
          id: "",
          globalId: nanoid(),
          name: "",
          nameAlternative: "",
          nameYa: "",
          brand: "",
          rating: "",
          ratingUsers: "",
          price: "",
          country: "",
          feature: "",
          link: "",
          linkAliexpress: "",
          content: "",
          positive: "",
          negative: "",
          dateCode: todayDate,
          specifications: {
            items: [],
            source: "",
            config: {
              hidden: false,
              hiddenItems: true,
              important: false,
              type: "specifications",
              typeItems: "specification",
            },
          },
          specificationsImport: {
            items: [],
            source: "",
            config: {
              hidden: false,
              hiddenItems: true,
              important: false,
              type: "specifications",
              typeItems: "specification",
            },
          },
          images: {
            items: [],
            config: {
              hidden: false,
              hiddenItems: false,
              type: "images",
              typeItems: "image",
            },
          },
          slider: {
            items: [],
            config: {
              hidden: false,
              hiddenItems: false,
              type: "slider",
              typeItems: "sliderItem",
            },
          },
          video: {
            items: [],
            config: {
              hidden: false,
              hiddenItems: false,
              type: "video",
              typeItems: "videoItem",
            },
          },
          related: {
            MainArticl: {},
            articles: {},
            products: {},
          },
          config: {
            hidden: false,
            hiddenItems: true,
            hiddenInnerBlocks: false,
            type: "product",
          },
        };
        //Добавляем спецификации
        let specificationProductNames = state.data.specifications.items.map((item) => ({
          id: item.id,
          globalId: nanoid(),
          name: item.name,
          importName: item.importName,
          showInProductlist: item.showInProductlist,
          content: "",
          config: {
            hidden: false,
            type: "specification",
          },
        }));
        prototype.specifications.items = specificationProductNames;
        break;
      case "Videoitems":
        prototype = {
          id: 1,
          globalId: nanoid(),
          name: "",
          link: "",
          images: "",
          imagesName: "v_" + state.data.main.id + "_" + nanoid(),
          time: "",
          dateCode: todayDate,
          config: {
            hidden: false,
            hiddenItems: false,
            type: "videoItem",
          },
        };
        thisObject = action.element;
        break;
      case "Articleitems":
        prototype = {
          id: 1,
          globalId: nanoid(),
          name: "",
          wpId: "",
          images: "",
          imagesName: "i_" + state.data.main.id + "_" + nanoid(),
          dateCode: todayDate,
          config: {
            hidden: false,
            hiddenItems: false,
            type: "articleItem",
          },
        };
        thisObject = action.element;
        break;
      case "Slideritems":
        prototype = {
          id: 1,
          globalId: nanoid(),
          images: "s_" + state.data.main.id + "_" + nanoid(),
          dateCode: todayDate,
          config: {
            hidden: false,
            type: "sliderItem",
          },
        };
        thisObject = action.element;
        break;
      case "Imagesitems":
        prototype = {
          id: 1,
          globalId: nanoid(),
          images: "i_" + state.data.main.id + "_" + nanoid(),
          dateCode: todayDate,
          config: {
            hidden: false,
            type: "image",
          },
        };
        thisObject = action.element;
        break;
      case "Sourceitems":
        prototype = {
          id: 1,
          globalId: nanoid(),
          name: "",
          link: "",
          config: {
            hidden: false,
            type: "sourceItem",
          },
        };
        thisObject = action.element;
        break;
      case "Specification":
        prototype = {
          id: 1,
          globalId: nanoid(),
          name: "",
          content: "",
          importName: "",
          showInProductlist: true,
          dateCode: todayDate,
          config: {
            hidden: false,
            type: "specification",
          },
        };
        //Костыль, thisObject вообще нужно отсюда убрать
        if (typeof action !== "undefined") {
          thisObject = action.element;
        }
        break;

      default:
        break;
    }

    return { prototype, thisObject };
  }

  //Получаем все объекты плана
  let planObjects = [
    {
      planItems: state.data.specifications.plan.items,
      typeItems: "Specification",
      type: "specifications",
    },
    {
      planItems: state.data.choices.plan.items,
      typeItems: "Choice",
      type: "choices",
    },
    {
      planItems: state.data.categories.plan.items,
      typeItems: "Categories",
      type: "categories",
    },
    {
      planItems: state.data.trends.plan.items,
      typeItems: "Trends",
      type: "trends",
    },
    {
      planItems: state.data.questions.plan.items,
      typeItems: "Questions",
      type: "questions",
    },
    {
      planItems: state.data.brands.plan.items,
      typeItems: "Brands",
      type: "brands",
    },
    {
      planItems: state.data.products.plan.items,
      typeItems: "Products",
      type: "products",
    },
    {
      planItems: state.data.ending.plan.items,
      typeItems: "Ending",
      type: "ending",
    },
    {
      planItems: state.data.videos.plan.items,
      typeItems: "Videoitems",
      type: "videos",
    },
  ];

  //Декларируем нужные переменные
  let prototype = "";
  let allNewItems = "";
  let typeItems = "";
  let ItemElements = "";

  //Перебираем все объекты с планом статей и вставляем план в items, туда, где он есть
  planObjects.map((item) => {
    if (item.planItems !== "" && state.data[item.type].items.length === 0) {
      //получаем нужный тип для прототипа
      typeItems = item.typeItems;
      //Получаем родительский элемент, в котый будет вставляться блок
      ItemElements = state.data[item.type];
      //Перебираем строку с планом, превратив ее в массив
      allNewItems = item.planItems.split(", ").map((item, index) => {
        //Записываем в экшен элемент, в который добавляются новые блоки, нужно для получения прототипа
        let action = { element: ItemElements, position: "" };
        //Получаем прототип объекта
        prototype = getPrototypeItem(typeItems, state, action).prototype;
        //Задаем ему новый id, в зависимости от индекса
        prototype.id = index + 1;
        //Записываем имя
        prototype.name = item;
        //Возвращаем
        return prototype;
      });
      //Создаем все объекты
      state.data[item.type].items = allNewItems;

      //Обнуляем план
      //state.data[item.type].plan.items = ''
    }
    return state;
  });
  return state;
}

let initialState = {
  main: {
    project: "",
    contentType: "",
    ownerName: "",
    moderatorName: "",
    status: "",
    category: "",
    date: "",
    data: "",
    name: "",
    title: "",
    description: "",
    keys: "",
    keysAll: "",
    text: "",
    planItems: "",
    allCharts: "",
    characters: 0,
    allCurrentCountCharts: 0,
    excludeCountCharts: 0,
    plan_specifications: "",
    tableId: "",
    publishId: "",
    publishLink: "",
    countPublishGames: "",
    datePublishGames: "",
    updateItemsStatus: false,
    createItemsStatus: true,
    updateOnTheSiteItemsStatus: true,
    options: {
      users: "",
    },
    id: 0,
    plan: {
      items: "",
      keys: "",
      text: "",
      corrections: "",
      keysAll: "",
    },
    planText: {
      items: [],
      config: {
        chartsProperty: {},
        active: true,
        hidden: false,
        hiddenItems: true,
        hiddenInnerBlocks: true,
        type: "planText",
        typeItems: "planItem",
        charts: "300",
      },
    },
    config: {
      chartsProperty: {},
      active: true,
      hidden: false,
      hiddenItems: true,
      hiddenInnerBlocks: true,
      type: "main",
      charts: "300",
    },
  },
  data: {},
  items: [],
  isLoading: true,
  isError: false,
  error: null,
};

const createReducer = (state = initialState, action) => {
  const { payload } = action;
  let { name, element, newText, thisObject, data, users } = action;

  switch (action.type) {
    case CREATE_UPDATE_LIST:
      data = action.data.article;
      let status = action.data.status;
      let indexItem = state.items.findIndex((item) => item._id === data._id);
      if (status !== "delete") {
        state.items[indexItem] = data;
      } else {
        state.items.splice(indexItem, 1);
      }
      return state;

    case CREATE_ADD_ACTICLE:
      state.items.push(data);
      state.users = users;
      return state;

    case CREATE_ADD_ACTICLES:
      data.forEach((item) => {
        state.items.push(item);
      });
      state.users = users;
      return state;

    case CREATE_UPDATE_EDITOR:
      data = action.data;
      state.data.editor = data;
      //Обновляем количество символов
      updateCharts(state);
      return state;

    case CREATE_CHANGE_TEXT:
      //Вносим изменение в этот блок
      if (newText === "true") {
        newText = true;
      } else if (newText === "false") {
        newText = false;
      }
      element[name] = newText;
      state = updateCharts(state);
      return state;

    case CREATE_PAST_STATE:
      //Записываем новый state
      let newState = action.data;

      ////Преобразовываем код, чтобы он воспринимался из гугл докс
      newState = newState.replace(/"true"/g, true).replace(/"false"/g, false);

      //проверяем, получили ли мы json
      if (IsJsonString(newState) === true) {
        //Преобразуем в объект
        newState = JSON.parse(newState);
        //Проверяем правильно ли задан state и передан ли json формат
        if (
          typeof newState.code === "undefined" &&
          typeof newState.main === "undefined" &&
          typeof newState.videos === "undefined" &&
          typeof newState.choices === "undefined" &&
          typeof newState.trends === "undefined" &&
          typeof newState.questions === "undefined" &&
          typeof newState.brands === "undefined" &&
          typeof newState.products === "undefined" &&
          typeof newState.ending === "undefined"
        ) {
          toaster.notify(
            "Код вставлен неверно, попробуйте скопировать и вставить код еще раз",
            {
              duration: 3000,
            }
          );
        } else {
          //Заменяем стейт
          state.data = newState;
          //Создаем блоки элементов на основе плана статьи
          state = addPlanItems(state);
          //Изменяем в объекте main, значения со всеми символами статьи и их количеством
          state = updateCharts(state);
          //Добавляем отетку, о добавлении
          state.main.data = "Добавлено";
          toaster.notify("Добавление удачно завершено!", {
            duration: 3000,
          });
        }
      } else {
        toaster.notify(
          "Код вставлен неверно, попробуйте скопировать и вставить код еще раз",
          {
            duration: 3000,
          }
        );
      }
      return state;

    //Скрывает блок элемента
    case CREATE_HIDDEN_ELEMENT:
      //Текущий объект
      thisObject = action.element;
      //Если скрываются, показываем блок, если показывается, скрываем
      if (thisObject.config.hidden === true) {
        thisObject.config.hidden = false;
      } else {
        thisObject.config.hidden = true;
      }
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    //Показ и скрытие блока с доп полями
    case CREATE_CLICK_HIDDEN_BUTTON:
      //Текущий объект
      thisObject = action.element;
      //Если скрываются, показываем блок, если показывается, скрываем
      if (thisObject.config.hiddenItems === true) {
        thisObject.config.hiddenItems = false;
      } else {
        thisObject.config.hiddenItems = true;
      }
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_INITIAL_DATA_REQUEST_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case CREATE_INITIAL_DATA_REQUEST_SUCCESS:
      //Проверяем, вернулись ли верные данные
      if (payload.data) {
        //Если в локальном хранилище версию новее, получаем данные оттуда
        state.data = payload.data;

        state.isError = false;
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
      }
      return state;

    case CREATE_INITIAL_DATA_REQUEST_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    case CREATE_ADD_ITEM_NEW:
      let todayDate = "01";
      let prototypeItem = action.prototype;
      element = action.element;
      //Задаем новый id для объекта, глобальный id и дату
      prototypeItem.id = newId(element.items);
      prototypeItem.globalId = nanoid();
      prototypeItem.dateCode = todayDate;
      if (prototypeItem.imagesName !== undefined) {
        prototypeItem.imagesName =
          prototypeItem.imagesName + state.main.id + "_" + nanoid();
      }
      //Добавляем новый объект
      element.items.push(prototypeItem);
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_PAST_ELEMENT:
      //Получаем вставленный элемент
      let pastElement = localStorage.getItem("copyElement");

      //Проверяем на наличие элемента для вставки
      if (IsJsonString(pastElement) !== false && pastElement !== null) {
        pastElement = JSON.parse(pastElement);
        //Задаем новый id для объекта
        pastElement.id = newId(action.element.items);
        //Добавляем в элемент в массив, если тип соответствует
        if (pastElement.config.type === action.element.config.typeItems) {
          action.element.items.push(pastElement);
          //Удаляем из памяти этот элемент
          localStorage.removeItem("copyElement");
        } else {
          toaster.notify("Выбрано неправильное место для вставки", {
            duration: 3000,
          });
        }
      } else {
        toaster.notify("Неверный объект для вставки", {
          duration: 3000,
        });
      }
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_TOP:
      //Получаем индекс поднимаемого элемента
      let topElementID = action.parentElement.items.indexOf(action.element);
      swapArray(action.parentElement.items, topElementID, topElementID - 1);
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_DOWN:
      //Получаем опускаемого элемента
      let downElementID = action.parentElement.items.indexOf(action.element);
      swapArray(action.parentElement.items, downElementID, downElementID + 1);
      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_COPY:
      //Получаем сохраняемый элемент
      let copyElement = action.element;

      //Преобразуем в JSON
      let copyElementJson = JSON.stringify(copyElement);

      //Получаем индекс удаляемое элемента
      let copyElementID = action.parentElement.items.indexOf(copyElement);
      //Удаляем элемент
      action.parentElement.items.splice(copyElementID, 1);

      //Сохраняем в localStorage
      localStorage.setItem("copyElement", copyElementJson);

      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    case CREATE_DEL:
      //Получаем удаляемый элемент
      let delElement = action.element;
      //Преобразуем в json
      let delElementJson = JSON.stringify(delElement);
      //На всякий слачай сохраняем в localStorage
      localStorage.setItem("delElement", delElementJson);
      //Получаем индекс удаляемое элемента
      let delElementID = action.parentElement.items.indexOf(delElement);
      //Удаляем элемент
      action.parentElement.items.splice(delElementID, 1);
      toaster.notify(
        "Удалено! Если вы удалили случайно, нажмите скопировать удаленный элемент (внизу страницы) и вставьте в нужное место.",
        {
          duration: 3000,
        }
      );

      //Обновляем версию
      state.version = new Date().getTime();
      return state;

    default:
      return state;
  }
};

function updateCharts(state) {
  const allCharts = getAllChartsInEditor(state, "count");
  // if (state?.data?.main) {
  //   state.data.main.allCharts = allCharts;
  //   //Изменяем в объекте main, значение с количеством символов в статье
  //   state.data.main.allCurrentCountCharts = state.data.main.allCharts.replace(
  //     / *\n*\r*\t*/g,
  //     ""
  //   ).length;
  // }
  state.main.allCharts = allCharts;
  state.main.allCurrentCountCharts = allCharts.replace(/ *\n*\r*\t*/g, "").length;

  //Возвращаем измененный state
  return state;
}

export const createInitialData = (token) => {
  return (dispatch) => {
    dispatch(createInitialDataRequest());

    fetch(`/api/article/all`, {
      method: "GET",
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(createInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(createInitialDataError(err));
      });
  };
};

export const createInitialDataRequest = () => ({
  type: CREATE_INITIAL_DATA_REQUEST_REQUEST,
});

export const createInitialDataSuccess = (data) => ({
  type: CREATE_INITIAL_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const createInitialDataError = (error) => ({
  type: CREATE_INITIAL_DATA_REQUEST_ERROR,
  payload: error,
});

export const createChangeTextAction = (text, element, name) => ({
  type: CREATE_CHANGE_TEXT,
  newText: text,
  element: element,
  name: name,
});

export const createPastStateAction = (data) => ({
  type: CREATE_PAST_STATE,
  data,
});

export const createHiddenElement = (element) => ({
  type: CREATE_HIDDEN_ELEMENT,
  element,
});

export const createHiddenButton = (element) => ({
  type: CREATE_CLICK_HIDDEN_BUTTON,
  element,
});

export const createUpdateEditor = (data) => ({
  type: CREATE_UPDATE_EDITOR,
  data: data,
});

export const createAddArticle = (data, users) => ({
  type: CREATE_ADD_ACTICLE,
  data: data,
  users,
});

export const createAddArticles = (data, users) => ({
  type: CREATE_ADD_ACTICLES,
  data: data,
  users,
});

export const createUpdateList = (data) => ({
  type: CREATE_UPDATE_LIST,
  data: data,
});

export const createClickAddItemNewAction = (prototype, element) => ({
  type: CREATE_ADD_ITEM_NEW,
  prototype,
  element,
});

export const createClickTopAction = (element, parentElement) => ({
  type: CREATE_TOP,
  element: element,
  parentElement: parentElement,
});

export const createClickDownAction = (element, parentElement) => ({
  type: CREATE_DOWN,
  element: element,
  parentElement: parentElement,
});

export const createClickCopyAction = (element, parentElement) => ({
  type: CREATE_COPY,
  element: element,
  parentElement: parentElement,
});

export const createClickDelAction = (element, parentElement) => ({
  type: CREATE_DEL,
  element: element,
  parentElement: parentElement,
});

export const createClickPastElementAction = (element, parentElement) => ({
  type: CREATE_PAST_ELEMENT,
  element: element,
  parentElement: parentElement,
});

export default createReducer;
