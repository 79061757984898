const USERS_INITIAL_DATA_REQUEST_REQUEST = "USERS_INITIAL_DATA_REQUEST_REQUEST";
const USERS_INITIAL_DATA_REQUEST_SUCCESS = "USERS_INITIAL_DATA_REQUEST_SUCCESS";
const USERS_INITIAL_DATA_REQUEST_ERROR = "USERS_INITIAL_DATA_REQUEST_ERROR";

let initialState = {
  data: null,
  pagination: null,
  isLoading: true,
  isError: false,
  error: null,
  errorCode: null,
};

const usersReducer = (state = initialState, action) => {
  const payload = action.payload;

  switch (action.type) {
    case USERS_INITIAL_DATA_REQUEST_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case USERS_INITIAL_DATA_REQUEST_SUCCESS:
      //Проверяем, вернулись ли верные данные
      if (payload.data) {
        state.data = payload.data;
        state.pagination = payload.pagination;

        state.isError = false;
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
      }
      return state;

    case USERS_INITIAL_DATA_REQUEST_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    default:
      return state;
  }
};

export const usersInitialData = (token, params) => {
  return (dispatch) => {
    dispatch(usersInitialDataRequest());

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/users/all`, {
      method: "POST",
      body: params,
      headers,
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(usersInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(usersInitialDataError(err));
      });
  };
};

export const usersInitialDataRequest = () => ({
  type: USERS_INITIAL_DATA_REQUEST_REQUEST,
});

export const usersInitialDataSuccess = (data) => ({
  type: USERS_INITIAL_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const usersInitialDataError = (error) => ({
  type: USERS_INITIAL_DATA_REQUEST_ERROR,
  payload: error,
});

export default usersReducer;
