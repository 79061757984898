const PAYS_INITIAL_DATA_REQUEST_REQUEST = "PAYS_INITIAL_DATA_REQUEST_REQUEST";
const PAYS_INITIAL_DATA_REQUEST_SUCCESS = "PAYS_INITIAL_DATA_REQUEST_SUCCESS";
const PAYS_INITIAL_DATA_REQUEST_ERROR = "PAYS_INITIAL_DATA_REQUEST_ERROR";
const PAYS_UPDATE_LIST = "PAYS_UPDATE_LIST";

let initialState = {
  data: null,
  pagination: null,
  isLoading: true,
  isError: false,
  error: null,
  errorCode: null,
};

const paysReducer = (state = initialState, action) => {
  let data;
  const payload = action.payload;

  switch (action.type) {
    case PAYS_UPDATE_LIST:
      data = action.data.article;
      let indexItem = state.data.findIndex((item) => item._id === data._id);
      state.data[indexItem] = data;
      return state;

    case PAYS_INITIAL_DATA_REQUEST_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case PAYS_INITIAL_DATA_REQUEST_SUCCESS:
      //Проверяем, вернулись ли верные данные
      if (payload.data) {
        //Если в локальном хранилище версию новее, получаем данные оттуда
        state.data = payload.data;
        state.pagination = payload.pagination;

        state.isError = false;
        state.isLoading = false;
        state.errorCode = null;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
      }
      return state;

    case PAYS_INITIAL_DATA_REQUEST_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    default:
      return state;
  }
};

export const paysInitialData = (token, params) => {
  return (dispatch) => {
    dispatch(paysInitialDataRequest());

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    fetch(`/api/pays/all`, { method: "POST", body: params, headers })
      .then((res) => res.json())
      .then((result) => {
        dispatch(paysInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(paysInitialDataError(err));
      });
  };
};

export const paysInitialDataRequest = () => ({
  type: PAYS_INITIAL_DATA_REQUEST_REQUEST,
});

export const paysInitialDataSuccess = (data) => ({
  type: PAYS_INITIAL_DATA_REQUEST_SUCCESS,
  payload: data,
});

export const paysInitialDataError = (error) => ({
  type: PAYS_INITIAL_DATA_REQUEST_ERROR,
  payload: error,
});

export const paysUpdateList = (data) => ({
  type: PAYS_UPDATE_LIST,
  data: data,
});

export default paysReducer;
