import Select from "react-select";
import React from "react";

const SelectTableBlock = (props) => {
  let onChangeSelect = (date) => {
    props.callBack(date, props.callBackData);
  };

  //Совмещаем значение из поле с оциями, чтобы верно отобразить текущую опцию
  let selectValue;
  let selectValueItems;

  if (props.options) {
    if (props.multi) {
      if (props.value) {
        selectValue = [];
        selectValueItems = props.value.split(",");
        selectValueItems.forEach((element) => {
          selectValue.push(props.options.find((item) => item.value === element));
        });
      }
    } else {
      selectValue = props.options.find((item) => item.value === props.value);
    }
  }

  let placeholderName = props.placeholder || "Выбор";

  if (!selectValue) {
    selectValue = null;
  }

  return (
    <Select
      className="article__select"
      value={selectValue}
      onChange={onChangeSelect}
      options={props.options}
      placeholder={placeholderName}
      isClearable={props.isClearable}
      isMulti={props.multi}
      closeMenuOnSelect={props.closeMenuOnSelect}
    />
  );
};

export default SelectTableBlock;
