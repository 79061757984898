import React from "react";
import { useAuth } from "src/hooks/auth.hook";
import { AuthContext } from "src/context/AuthContext";
import { Loader } from "src/components/Loader";
import { RouteItems } from "src/Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App(props) {
  //Получаем данные об авторизации

  const { token, login, logout, userId, sistemName, ready, status } = useAuth();
  const isAdmin = status === "admin" ? true : false;

  //Пока данные аунтефикации не получены, показываем прелоадер
  if (!ready) {
    return <Loader />;
  }

  //Получаем стату авторизации
  const isAuthenticated = !!token;

  return (
    //Добавляем в контекст данные пользователя
    <AuthContext.Provider
      value={{ token, login, logout, userId, sistemName, isAuthenticated, status }}
    >
      <div className="App">
        <RouteItems status={status} isAdmin={isAdmin} isAuth={isAuthenticated} />
        <ToastContainer />
      </div>
    </AuthContext.Provider>
  );
}

export default App;
