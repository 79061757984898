import React from "react";

const AddStateBlock = (props) => {
  const onChangePastState = (e) => {
    let data = e.target.value;
    props.pastState(data);
  };

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <input
        readOnly={props.readOnly}
        autoComplete="off"
        onChange={onChangePastState}
        value={props.value}
        className="element__input"
      />
    </div>
  );
};

export default AddStateBlock;
