import React from "react";
import ReactDOM from "react-dom";
import "src/index.sass";
import App from "src/App";
import * as serviceWorker from "src/serviceWorker";
import store from "src/redux/reduxStore";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";

//Рендерим страница
let rerenderEntireTree = (state) => {
  ReactDOM.render(
    <BrowserRouter>
      <Provider store={store}>
        <App state={state} />
      </Provider>
    </BrowserRouter>,
    document.getElementById("root")
  );

  //Создаем глобальный объект для просмотра стейта
  window.state = state;
};

//Запуска отрисовку дерева элементов
rerenderEntireTree(store.getState());

//Создаем колбек для возможности перерисовски дерева, при изменении стейта
store.subscribe(() => {
  let state = store.getState();
  rerenderEntireTree(state);
  //Обновляем локальную версию state productList у пользователя
  let id = state.articlesReducer._id;
  if (id && state.articlesReducer.articleType) {
    const savedArticles = JSON.parse(localStorage.getItem(`savedArticles`));

    if (savedArticles) {
      const articleIndex = savedArticles?.findIndex((item) => item._id === id);

      if (articleIndex >= 0) {
        savedArticles[articleIndex] = state.articlesReducer;
      } else {
        savedArticles.unshift(state.articlesReducer);
        savedArticles.splice(5, savedArticles.length);
      }

      localStorage.setItem(`savedArticles`, JSON.stringify(savedArticles));
    } else {
      const newSavedArticles = [];
      newSavedArticles.push(state.articlesReducer);
      console.log(newSavedArticles);
      localStorage.setItem(`savedArticles`, JSON.stringify(newSavedArticles));
    }
  }
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
