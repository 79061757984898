import React from "react";
import { ElementBlock } from "src/components/Category/ElementBlock/ElementBlock";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { allInitialData } from "src/redux/allReducer";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";
import AsyncSelecTableBlock from "src/components/Category/TableBlock/AsyncSelecTableBlock";

export const AllArticles = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let isAdmin = props.isAdmin;

  const page = Number(searchParams.get("page"));
  const urlProject = searchParams.get("project");
  const urlUser = searchParams.get("user");
  const urlModerator = searchParams.get("moderator");
  const urlCategory = searchParams.get("category");
  const urlId = searchParams.get("id");

  const articleItemById = props?.articles?.find((item) => item._id === urlId);
  const urlSelectValue = articleItemById
    ? { value: urlId, label: articleItemById.name }
    : null;

  let sortProjectOptions = props.themeGlobal.options.projects;
  let sortUsersOptions = props.themeGlobal.options.users;
  let sortCategoryOptions = props.themeGlobal.options.category[urlProject];

  let tableNames = [
    "№",
    "Название",
    "Автор",
    "Модератор",
    "Срок",
    "Символы",
    "Статус",
    "Статус оплаты",
    "Проект",
    "Тип",
    "Категория",
    "Удаление",
    "Публикация",
    "Ссылка",
  ];
  const options = props.themeGlobal.options;

  const pagination = {
    page,
    pageName: "page",
    limit: props.pagination.limit,
    next: props.pagination.next,
  };

  let chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      if (type === "project") {
        newParams.delete("category");
      }
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  return (
    <>
      {isAdmin && (
        <div className="articleFilter">
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"project"}
            options={sortProjectOptions}
            value={urlProject}
            placeholder={"Проект"}
            isClearable={true}
            multi={true}
          />
          {sortCategoryOptions !== undefined &&
            urlProject !== null &&
            urlProject !== "all" && (
              <SelectTableBlock
                callBack={chengeFilter}
                callBackData={"category"}
                options={sortCategoryOptions}
                value={urlCategory}
                placeholder={"Категория"}
                isClearable={true}
                multi={true}
              />
            )}
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"user"}
            options={sortUsersOptions}
            value={urlUser}
            placeholder={"Автор"}
            isClearable={true}
          />
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"moderator"}
            options={sortUsersOptions}
            value={urlModerator}
            placeholder={"Модератор"}
            isClearable={true}
          />
          <AsyncSelecTableBlock
            callBack={chengeFilter}
            callBackData={"id"}
            value={urlSelectValue}
            placeholder={"Название"}
            isClearable={true}
            apiPatch="/api/article/all"
            optionsPatch={{ items: "data", itemValue: "_id", itemLabel: "name" }}
          />
        </div>
      )}
      <div className="articleList">
        {!props.articles.length && <p className="textCenter">Ничего не найдено</p>}

        {!isAdmin && props.articles.length > 0 && (
          <>
            <h2 className="articleList__title">Архив</h2>
            <ElementBlock
              isAdmin={isAdmin}
              deadlineStatus={false}
              linkStatus={false}
              items={props.articles}
              chengeFilter={chengeFilter}
              pagination={pagination}
              isLoading={props.isLoading}
            />
          </>
        )}

        {isAdmin && props.articles.length > 0 && (
          <>
            <h2 className="articleList__title">Архив</h2>
            <TableBlock
              deadlineStatus={false}
              isAdmin={isAdmin}
              names={tableNames}
              items={props.articles}
              options={options}
              updater={allInitialData}
              chengeFilter={chengeFilter}
              pagination={pagination}
              isLoading={props.isLoading}
            />
          </>
        )}
      </div>
    </>
  );
};
