import React, { useContext } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import HeaderProductListData from "src/components/Header/data/HeaderProductListData";
import HeaderCreatorData from "src/components/Header/data/HeaderCreatorData";
import HeaderEditorData from "src/components/Header/data/HeaderEditorData";

const HeaderInner = (props) => {
  //Получаем доступ к истории и получаем данные
  const navigate = useNavigate();
  const auth = useContext(AuthContext);
  const location = useLocation();
  //При выходе выполняем выход черех хук и переводим страницу на домашнюю
  const logoutHandler = (event) => {
    event.preventDefault();
    auth.logout();
    navigate("/");
  };

  let editorStatus = false;
  if (props.state.data && props.state.data.editor) {
    editorStatus = true;
  }

  //Получаем все блоки со ссылками в зависимости от проекта и типа записи
  let linksObject = props.theme?.links?.filter((item) => {
    let status = false;
    if (
      item.project.includes(props.state.project) &&
      item.contentType.includes(props.state.articleType)
    ) {
      status = true;
    }
    if (item.project.includes(props.state.project) && item.contentType === "all") {
      status = true;
    }
    return status;
  });
  //Если что-то найдено
  let LinksBlock = [];
  if (linksObject?.length > 0) {
    //Объединяем найденные ссылки в один массив
    let allLinks = [];
    linksObject.forEach((item) => {
      allLinks = allLinks.concat(item.items);
    });
    //Преобразуем в jsx объекты
    LinksBlock = allLinks.map((item, index) => (
      <a
        key={index}
        className="header__link"
        target="_blank"
        rel="noopener noreferrer"
        href={item.link}
      >
        {item.name}
      </a>
    ));
  }

  let dashboardLink = "/dashboard";
  if (props.isAdmin) {
    dashboardLink =
      "/dashboard?status=editing%2Cmoderation%2Ccorrections&notMeModerationActive=yes";
  }

  return (
    <div className="header__inner">
      <div className="header__left">
        {location.pathname.includes("/article") &&
          props.state.version <= props.state.lastUpdate && (
            <span className="header__editStatus">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 295.485 295.485"
                xmlSpace="preserve"
              >
                <g>
                  <path
                    d="M288.865,65.628L232.917,9.681c-4.239-4.239-9.988-6.62-15.983-6.62h-9.329v83.63c0,5.599-4.539,10.138-10.138,10.138
		H62.548c-5.599,0-10.138-4.539-10.138-10.138V3.06H22.603C10.12,3.06,0,13.18,0,25.664v244.158
		c0,12.483,10.12,22.603,22.603,22.603h250.279c12.483,0,22.603-10.12,22.603-22.603V81.611
		C295.485,75.617,293.104,69.867,288.865,65.628z"
                  />
                  <path
                    d="M164.076,76.829h17.333c3.498,0,6.333-2.836,6.333-6.333V23.829c0-3.498-2.835-6.333-6.333-6.333h-17.333
		c-3.498,0-6.333,2.835-6.333,6.333v46.667C157.743,73.993,160.579,76.829,164.076,76.829z"
                  />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </span>
          )}
        {location.pathname.includes("/article") &&
          !props.state.lastUpdate &&
          props.state.lastUpdate !== 0 && (
            <span className="header__editStatus">
              <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 512.018 512.018"
                xmlSpace="preserve"
              >
                <g>
                  <g>
                    <path
                      d="M509.769,480.665L275.102,11.331c-7.253-14.464-30.933-14.464-38.187,0L2.249,480.665
			c-3.307,6.613-2.944,14.464,0.939,20.757c3.904,6.272,10.752,10.112,18.155,10.112h469.333c7.403,0,14.251-3.84,18.155-10.112
			C512.713,495.129,513.075,487.278,509.769,480.665z M256.009,426.201c-11.776,0-21.333-9.557-21.333-21.333
			s9.557-21.333,21.333-21.333s21.333,9.557,21.333,21.333S267.785,426.201,256.009,426.201z M277.342,340.867
			c0,11.776-9.536,21.333-21.333,21.333c-11.797,0-21.333-9.557-21.333-21.333V191.534c0-11.776,9.536-21.333,21.333-21.333
			c11.797,0,21.333,9.557,21.333,21.333V340.867z"
                    />
                  </g>
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </span>
          )}
        {location.pathname.includes("/article") &&
          props.state.version > props.state.lastUpdate && (
            <span className="header__editStatus">
              <svg
                version="1.1"
                id="Capa_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 59.985 59.985"
                xmlSpace="preserve"
              >
                <g>
                  <path d="M5.243,44.844L42.378,7.708l9.899,9.899L15.141,54.742L5.243,44.844z" />
                  <path
                    d="M56.521,13.364l1.414-1.414c1.322-1.322,2.05-3.079,2.05-4.949s-0.728-3.627-2.05-4.949S54.855,0,52.985,0
		s-3.627,0.729-4.95,2.051l-1.414,1.414L56.521,13.364z"
                  />
                  <path
                    d="M4.099,46.527L0.051,58.669c-0.12,0.359-0.026,0.756,0.242,1.023c0.19,0.19,0.446,0.293,0.707,0.293
		c0.106,0,0.212-0.017,0.316-0.052l12.141-4.047L4.099,46.527z"
                  />
                  <path d="M43.793,6.294l1.415-1.415l9.899,9.899l-1.415,1.415L43.793,6.294z" />
                </g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
                <g></g>
              </svg>
            </span>
          )}

        <span className="header__name">Редактор страниц</span>

        {props.isAuth === true && (
          <>
            <NavLink className="header__link" to={dashboardLink}>
              В работе
            </NavLink>
            {!props.isAdmin && (
              <NavLink className="header__link" to="/available">
                Доступно
              </NavLink>
            )}
            <NavLink className="header__link" to="/items">
              Элементы
            </NavLink>
            <NavLink className="header__link" to="/all">
              Архив
            </NavLink>
            {/* <NavLink className="header__link" to="/pays">
              Вознаграждения
            </NavLink> */}
          </>
        )}

        {props.isAuth === true && props.isAdmin === true && (
          <>
            <NavLink className="header__link" to="/create">
              Создать
            </NavLink>
            <NavLink className="header__link" to="/users?activity=true">
              Авторы
            </NavLink>
            <NavLink className="header__link" to="/pays">
              Платежи
            </NavLink>
            <NavLink className="header__link" to="/reg">
              Регистрация
            </NavLink>
          </>
        )}

        {location.pathname.includes("/article") && LinksBlock}

        {props.isAuth === true && (
          <a className="header__link" href="/" onClick={logoutHandler}>
            Выйти
          </a>
        )}
      </div>

      {location.pathname.includes("/article") && (
        <>
          {" "}
          {props.state.isLoading === false &&
            props.state.isError === false &&
            props.state.articleType === "productlist" && (
              <HeaderProductListData state={props.state.data} />
            )}
          {props.state.isLoading === false &&
            props.state.isError === false &&
            editorStatus && <HeaderEditorData state={props.state.data} />}
        </>
      )}
      {location.pathname === "/create" && <HeaderCreatorData state={props.state} />}
    </div>
  );
};

export default HeaderInner;
