import launchUniqueActions from "src/functions/unique/launchUniqueActions";

const launchCreatorActions = (
  type,
  actions,
  actionsOfChange,
  uniqueActionsOfChange,
  element,
  parentElement,
  articleState,
  parentCreatorBlocks,
  arrayId,
  prototype = null
) => {
  if (uniqueActionsOfChange?.length > 0) {
    uniqueActionsOfChange.forEach((actionList) => {
      if (actionList.types.includes(type)) {
        launchUniqueActions(
          actions,
          element,
          articleState,
          parentCreatorBlocks,
          actionList.actions
        );
      }
    });
  }
  if (actionsOfChange?.length > 0) {
    actionsOfChange.forEach((actionList) => {
      if (actionList.types.includes(type)) {
        actionList.actions.forEach((action) => {
          switch (action.name) {
            case "updateSpecifications":
              actions.updateSpecifications({
                type,
                element,
                parentElement,
                arrayId,
                prototype,
                ...action.config,
              });
              break;

            default:
              break;
          }
        });
      }
    });
  }
};

export default launchCreatorActions;
