import React, { useContext } from "react";
import { useDispatch } from "react-redux";
import { AuthContext } from "src/context/AuthContext";
import { NavLink, useSearchParams } from "react-router-dom";
import getSearchParamsObject from "src/functions/getSearchParamsObject";
import { addZero } from "src/functions/addZero";
import toaster from "src/hooks/toaster.hook";

export const ItemsTable = (props) => {
  const [searchParams] = useSearchParams();
  const { token } = useContext(AuthContext);
  const searchParamsObject = getSearchParamsObject(searchParams.toString());
  const dispatch = useDispatch();

  const date = new Date(props.state.created);
  const dateFormated =
    date.getDate() +
    "." +
    addZero(date.getMonth() + 1) +
    "." +
    date.getFullYear() +
    " " +
    addZero(date.getHours()) +
    ":" +
    addZero(date.getMinutes());

  const onRemoveItem = () => {
    let result = window.confirm(`Вы действительно хотите удалить "${props.state.name}"?`);
    if (!result) {
      return;
    }

    let data = {
      id: props.state._id,
    };

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/items/deleteOne`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (props.updater) {
          dispatch(props.updater(token, searchParamsObject));
        }
        if (props.callBack) {
          dispatch(props.callBack(result));
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  const optionsProjects = props.themeGlobal.options.projects;
  const project = optionsProjects[props.state.projectId - 1].label;

  return (
    <tr>
      <td className="tableBlock__td tableBlock__td--id">{props.num}</td>
      <td className="tableBlock__td">{props.state.name}</td>
      <td className="tableBlock__td">{project}</td>
      <td className="tableBlock__td">{props.state.type}</td>
      <td className="tableBlock__td">{dateFormated}</td>
      <td className="tableBlock__td">
        <NavLink
          to={`/item/${props.state._id}`}
          className="article__value--linkTable article__value"
        >
          Перейти
        </NavLink>
      </td>
      {props.isAdmin && (
        <td className="tableBlock__td">
          <div
            onClick={onRemoveItem}
            className="article__value article__value--active article__value--warning"
          >
            Удалить
          </div>
        </td>
      )}
    </tr>
  );
};
