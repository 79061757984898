import { useContext, useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { AuthContext } from "src/context/AuthContext";
import { dashboardInitialData } from "src/redux/dashboardReducer";
import toaster from "src/hooks/toaster.hook";

const DashboardAdmin = ({
  isAdmin,
  themeGlobal,
  articles,
  pagination: paginationGlobal,
  isLoading,
}) => {
  const { token, sistemName } = useContext(AuthContext);
  const [searchParams, setSearchParams] = useSearchParams();

  const count = articles.count;
  const artclesAll = articles.all;
  //   let dateSort = (a, b) => {
  //     let aTime = new Date(a.deadline).getTime();
  //     let bTime = new Date(b.deadline).getTime();
  //     return aTime - bTime;
  //   };
  //   artclesAll.sort(dateSort);

  const page = Number(searchParams.get("page"));
  const urlProject = searchParams.get("project");
  const urlStatus = searchParams.get("status");
  const urlPayStatus = searchParams.get("payStatus");
  const urlUser = searchParams.get("user");
  const urlModerator = searchParams.get("moderator");
  const urlCategory = searchParams.get("category");
  const urlNotMeModerationActive = searchParams.get("notMeModerationActive");

  const sortProjectOptions = themeGlobal.options.projects;
  const sortStatusOptions = themeGlobal.options.status;
  const sortPayStatusOptions = themeGlobal.options.payStatus;
  const sortNotMeModerationOptions = themeGlobal.options.notMeModeration;
  const sortUsersOptions = themeGlobal.options.users;
  const sortCategoryOptions = themeGlobal.options.category[urlProject];

  const pagination = {
    page,
    pageName: "page",
    limit: paginationGlobal.limit,
    next: paginationGlobal.nextItems.allAdmin,
  };

  const dashboardAdminCategory = [
    {
      id: 1,
      name: "В работе",
      params: { status: "editing,moderation,corrections", notMeModerationActive: "yes" },
    },
    {
      id: 2,
      name: "На оплату",
      params: {
        status: "completed,published",
        payStatus: "notPaid",
      },
    },
    {
      id: 3,
      name: "На модерации",
      params: { status: "moderation", moderator: sistemName },
    },
    {
      id: 4,
      name: "На добавление",
      params: { status: "completed", payStatus: "paid" },
    },
    {
      id: 5,
      name: "Ожидают назначения",
      params: { status: "availableForSelection" },
    },
    {
      id: 6,
      name: "Запланировано",
      params: { status: "planned" },
    },
  ];

  const [activeAdminCategory, setActiveAdminCategory] = useState(
    dashboardAdminCategory[0]
  );

  let tableNames = [
    "№",
    "Название",
    "Автор",
    "Модератор",
    "Срок",
    "Символы",
    "Статус",
    "Статус оплаты",
    "Проект",
    "Тип",
    "Категория",
    "Удаление",
    "Публикация",
    "Ссылка",
  ];

  const options = themeGlobal.options;

  const chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      if (type === "project") {
        newParams.delete("category");
      }
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  const changeCategory = (num) => {
    const category = dashboardAdminCategory[Number(num) - 1];

    if (category) {
      const newParams = createSearchParams(category.params);
      setSearchParams(newParams);
      setActiveAdminCategory(category);
    }
  };

  let updateAllItems = () => {
    let result = window.confirm(`Вы действительно хотите обновить все посты?`);
    if (!result) {
      return;
    }

    let data = {};

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/allReplace`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== 1) {
          toaster.notify("Обновлено", {
            duration: 3000,
          });
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  return (
    <>
      <div className="articleFilter">
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"project"}
          options={sortProjectOptions}
          value={urlProject}
          placeholder={"Проект"}
          isClearable={true}
          multi={true}
        />
        {sortCategoryOptions !== undefined &&
          urlProject !== null &&
          urlProject !== "all" && (
            <SelectTableBlock
              callBack={chengeFilter}
              callBackData={"category"}
              options={sortCategoryOptions}
              value={urlCategory}
              placeholder={"Категория"}
              isClearable={true}
              multi={true}
            />
          )}
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"status"}
          options={sortStatusOptions}
          value={urlStatus}
          placeholder={"Статус"}
          isClearable={true}
          multi={true}
        />
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"payStatus"}
          options={sortPayStatusOptions}
          value={urlPayStatus}
          placeholder={"Статус оплаты"}
          isClearable={true}
        />
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"user"}
          options={sortUsersOptions}
          value={urlUser}
          placeholder={"Автор"}
          isClearable={true}
        />
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"moderator"}
          options={sortUsersOptions}
          value={urlModerator}
          placeholder={"Модератор"}
          isClearable={true}
        />
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"notMeModerationActive"}
          options={sortNotMeModerationOptions}
          value={urlNotMeModerationActive}
          placeholder={"Модерируемые статьи"}
          isClearable={true}
        />
      </div>
      <div className="articleCategory">
        <div className="articleCategory__fixer">
          <div className="articleCategory__scroll">
            <div className="articleCategory__items">
              <div
                onClick={() => changeCategory(1)}
                className="button button--ots_top button--ots_right"
              >
                В работе
                <div className="button__bage">{count.active}</div>
              </div>
              <div
                onClick={() => changeCategory(2)}
                className="button button--ots_top button--ots_right"
              >
                На оплату
                <div className="button__bage button__bage--red">{count.notPaid}</div>
              </div>
              <div
                onClick={() => changeCategory(3)}
                className="button button--ots_top button--ots_right"
              >
                На модерации
                <div className="button__bage button__bage--red">{count.moderation}</div>
              </div>
              <div
                onClick={() => changeCategory(4)}
                className="button button--ots_top button--ots_right"
              >
                На добавление
                <div className="button__bage">{count.notPublished}</div>
              </div>
              <div
                onClick={() => changeCategory(5)}
                className="button button--ots_top button--ots_right"
              >
                Ожидают назначения
                <div className="button__bage">{count.availableForSelection}</div>
              </div>
              <div
                onClick={() => changeCategory(6)}
                className="button button--ots_top button--ots_right"
              >
                Запланировано
                <div className="button__bage">{count.planned}</div>
              </div>
              <div
                onClick={updateAllItems}
                className="button button--ots_top button--red"
              >
                Обновить статьи
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="articleList">
        {!artclesAll.length && <p className="textCenter">Ничего не найдено</p>}

        {artclesAll.length > 0 && (
          <>
            <h2 className="articleList__title">{activeAdminCategory.name}</h2>
            <TableBlock
              deadlineStatus={true}
              isAdmin={isAdmin}
              names={tableNames}
              items={artclesAll}
              options={options}
              updater={dashboardInitialData}
              pagination={pagination}
              chengeFilter={chengeFilter}
              isLoading={isLoading}
            />
          </>
        )}
      </div>
    </>
  );
};

export default DashboardAdmin;
