function getSearchParamsObject(searchParamsString) {
  if (!searchParamsString) {
    return {};
  }
  return JSON.parse(
    '{"' + searchParamsString.replace(/&/g, '","').replace(/=/g, '":"') + '"}',
    function (key, value) {
      return key === "" ? value : decodeURIComponent(value);
    }
  );
}

export default getSearchParamsObject;
