import React, { useState, useEffect, useContext } from "react";
import { useHttp } from "src/hooks/http.hook";
import { useMessage } from "src/hooks/message.hook";
import { AuthContext } from "src/context/AuthContext";

export const AuthPage = () => {
  const auth = useContext(AuthContext);
  //Получаем данные и функции из хуков
  const message = useMessage();
  const { error, request, clearError } = useHttp();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  //Проверка данных
  useEffect(() => {
    message(error);
    clearError();
  }, [error, message, clearError]);

  //Записываем данные в локальный state
  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  const loginHandler = async () => {
    try {
      const data = await request("api/auth/login", "POST", { ...form });
      auth.login(data.token, data.userId, data.sistemName, data.userStatus);
    } catch (error) {}
  };

  return (
    <div className="content">
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Авторизация</div>
        </div>
        <div className="element__item">
          <div className="element__block">
            <span className="element__blockName">Логин</span>
            <input
              className="element__input"
              placeholder="Введите логин"
              id="email"
              name="email"
              type="text"
              value={form.email}
              onChange={changeHandler}
            />
            <span className="element__blockName">Пароль</span>
            <input
              className="element__input"
              placeholder="Введите пароль"
              id="password"
              name="password"
              type="password"
              value={form.password}
              onChange={changeHandler}
            />
          </div>
          <div onClick={loginHandler} className="button button--ots_top">
            Войти
          </div>
        </div>
      </div>
    </div>
  );
};
