import React from "react";

const HeaderProductListData = (props) => {
  //Получаем общее количество символов и лимит для статьи
  let countChart =
    Number(props.state.main.allCurrentCountCharts) -
    Number(props.state.main.excludeCountCharts);
  let limitChart = props.state.main.allCountCharts;

  //Считаем количество товаров
  let countProduct = props.state.products.items.reduce(
    (sum, item) => sum + item.items.reduce((sum) => sum + 1, 0),
    0
  );

  let countProductImage = props.state.products.items.reduce((sum, item) => {
    return (
      sum +
      item.items.reduce((sum, item) => {
        return (
          sum +
          item.images.items.reduce((sum, item) => {
            if (item.status === null || item.status === undefined) {
              return sum + 1;
            }
            if (item.status && item.status === true) {
              return sum + 1;
            } else {
              return sum;
            }
          }, 0)
        );
      }, 0)
    );
  }, 0);

  let countProductImageBlock = props.state.products.items.reduce((sum, item) => {
    return (
      sum +
      item.items.reduce((sum, item) => {
        let imageStatus = item.images.items.find((item) => {
          if (item.status === null || item.status === undefined) {
            return true;
          }
          if (item.status && item.status === true) {
            return true;
          } else {
            return false;
          }
        });
        if (imageStatus) {
          return sum + 1;
        } else {
          return sum;
        }
      }, 0)
    );
  }, 0);

  let countProductVideo = props.state.products.items.reduce((sum, item) => {
    return (
      sum +
      item.items.reduce((sum, item) => {
        if (item?.config?.imported) {
          return sum;
        }

        return (
          sum +
          item.video.items.reduce((sum, item) => {
            if (item.link && item.link !== "") {
              return sum + 1;
            } else {
              return sum;
            }
          }, 0)
        );
      }, 0)
    );
  }, 0);

  let countProductVideoBlock = props.state.products.items.reduce((sum, item) => {
    return (
      sum +
      item.items.reduce((sum, item) => {
        if (item?.config?.imported) {
          return sum;
        }

        let videoStatus = item.video.items.find((item) => {
          return item.link && item.link !== "" ? true : false;
        });

        if (videoStatus) {
          return sum + 1;
        } else {
          return sum;
        }
      }, 0)
    );
  }, 0);

  let countButtonVideo = props.state.videos.items.reduce((sum, item) => {
    if (item.link && item.link !== "") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);

  let countChoicesVideo = props.state.choices.relatedVideos.items.reduce((sum, item) => {
    if (item.link && item.link !== "") {
      return sum + 1;
    } else {
      return sum;
    }
  }, 0);
  let countAllVideo = countProductVideo + countButtonVideo + countChoicesVideo;
  let GetCountVideoBlock = () => {
    let countButtonVideoItem = countButtonVideo > 0 ? 1 : 0;
    let countChoicesVideoItem = countChoicesVideo > 0 ? 1 : 0;
    return countProductVideoBlock + countButtonVideoItem + countChoicesVideoItem;
  };
  let countVideoBlock = GetCountVideoBlock();

  const fullLimitChart = Math.round(Number(limitChart) * 0.05 + Number(limitChart));

  return (
    <div className="header__right">
      <div className="header__textItem">
        Товаров <span className="bold">{countProduct}</span> | Фото{" "}
        <span className="bold">{countProductImage}</span> ({countProductImageBlock}) |
        Видео: <span className="bold">{countAllVideo}</span> ({countVideoBlock})
      </div>
      <div className="header__textItem">
        Количество символов: <span className="bold">{countChart}</span> / {limitChart}-
        {fullLimitChart}
      </div>
    </div>
  );
};

export default HeaderProductListData;
