import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import { useContext } from "react";
import { useSearchParams } from "react-router-dom";
import { AuthContext } from "src/context/AuthContext";
import getSearchParamsObject from "src/functions/getSearchParamsObject";
import getObjectByStringPath from "src/functions/getObjectByStringPath";
import toaster from "src/hooks/toaster.hook";

const AsyncSelecTableBlock = (props) => {
  const { token } = useContext(AuthContext);
  const [searchParams] = useSearchParams();

  const [lastResult, setLastResult] = useState(null);

  let value = null;

  if (
    props?.optionsPatch?.items &&
    props.optionsPatch.itemValue &&
    props.optionsPatch.itemLabel &&
    lastResult &&
    props.value
  ) {
    const valueItem = getObjectByStringPath(lastResult, props.optionsPatch.items).find(
      (item) => {
        let searchValue = props.value;
        if (typeof props.value === "object") {
          searchValue = props.value.value;
        }
        return getObjectByStringPath(item, props.optionsPatch.itemValue) === searchValue;
      }
    );
    if (valueItem) {
      value = {
        value: getObjectByStringPath(valueItem, props.optionsPatch.itemValue),
        label: getObjectByStringPath(valueItem, props.optionsPatch.itemLabel),
      };
    }
  } else {
    if (typeof props.value === "object") {
      value = props.value;
    } else {
      value = { value: props.value, label: props.value };
    }
  }

  //Вносим изменения в текст
  let onChangeSelect = (date) => {
    props.callBack(date, props.callBackData);
  };

  let filterItems = (data) => {
    if (
      props?.optionsPatch?.items &&
      props.optionsPatch.itemValue &&
      props.optionsPatch.itemLabel
    ) {
      return getObjectByStringPath(data, props.optionsPatch.items).map((item) => {
        return {
          value: getObjectByStringPath(item, props.optionsPatch.itemValue),
          label: getObjectByStringPath(item, props.optionsPatch.itemLabel),
        };
      });
    }
  };

  let loadOptions = (inputValue, callback) => {
    const searchParamsObject = getSearchParamsObject(searchParams.toString());

    let params = {
      ...searchParamsObject,
      text: inputValue,
    };

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    fetch(props.apiPatch, { method: "POST", body: params, headers })
      .then((res) => res.json())
      .then((result) => {
        callback(filterItems(result));
        setLastResult(result);
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let placeholderName = props.placeholder || "Выбор";

  return (
    <AsyncSelect
      className="article__select"
      cacheOptions={false}
      loadOptions={loadOptions}
      onChange={onChangeSelect}
      placeholder={placeholderName}
      value={value}
      isClearable={props.isClearable}
    />
  );
};

export default AsyncSelecTableBlock;
