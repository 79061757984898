import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  articleInitialData,
  clickHiddenButtonAction,
  changeTextAction,
  clickHiddenElementAction,
  clickHiddenElementItemAction,
  clickHiddenElementItemAllAction,
  clickTopAction,
  clickDownAction,
  clickCopyAction,
  clickDelAction,
  clickPastElementAction,
  clickAddItemNewAction,
  updateEditorAction,
  saveLastUpdateAction,
  changePlanCharts,
  changeSpecifications,
} from "src/redux/articlesReducer";

import { changeThemeAction } from "src/redux/themReducer";
import ContentBlocks from "src/components/Content/ContentBlocks";
import toaster from "src/hooks/toaster.hook";
import { useDispatch } from "react-redux";

const Content = (props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let dashboardLink = "/dashboard";
  if (props.isAdmin) {
    dashboardLink =
      "/dashboard?status=editing%2Cmoderation%2Ccorrections&notMeModerationActive=yes";
  }

  let actions = useCallback(() => {
    //Сохранение изменений
    let saveLastUpdate = (data) => {
      dispatch(saveLastUpdateAction(data));
    };
    let actionChangePlanCharts = (data) => {
      dispatch(changePlanCharts(data));
    };
    //Показываем/скрываем доп данные
    let clickHiddenButton = (element) => {
      dispatch(clickHiddenButtonAction(element));
    };
    //Показываем/скрываем элемент
    let clickHiddenElement = (element) => {
      dispatch(clickHiddenElementAction(element));
    };
    //Показываем/скрываем блок внутри элемента
    let clickHiddenElementItem = (element, parentElement) => {
      dispatch(clickHiddenElementItemAction(element, parentElement));
    };
    //Показываем/скрываем все вложенные элементы
    let clickHiddenElementItemAll = (element) => {
      dispatch(clickHiddenElementItemAllAction(element));
    };
    //Вносим изменения в текст
    let changeText = (text, element, name, status = null) => {
      dispatch(changeTextAction(text, element, name, status));
    };
    //Вверх
    let clickTop = (element, parentElement) => {
      dispatch(clickTopAction(element, parentElement));
    };
    //Вниз
    let clickDown = (element, parentElement) => {
      dispatch(clickDownAction(element, parentElement));
    };
    //Копировать
    let clickCopy = (element, parentElement, action = null) => {
      dispatch(clickCopyAction(element, parentElement, action));
    };
    //Удалить
    let clickDel = (element, parentElement) => {
      dispatch(clickDelAction(element, parentElement));
    };
    //Вставить
    let clickPastElement = (
      element,
      prototype,
      specificationsOfChildren,
      specificationsToPrototype,
      item = null
    ) => {
      dispatch(
        clickPastElementAction(
          element,
          prototype,
          specificationsOfChildren,
          specificationsToPrototype,
          item
        )
      );
    };
    let clickAddItemNew = (prototype, element) => {
      dispatch(clickAddItemNewAction(prototype, element));
    };
    //Обновляем редактор
    let updateEditor = (data) => {
      dispatch(updateEditorAction(data));
    };
    let changeTheme = (element, name, value) => {
      let data = { element, name, value };
      dispatch(changeThemeAction(data));
    };
    let reloadArticle = (id, token) => {
      dispatch(articleInitialData(id, token));
    };
    let updateSpecifications = (type) => {
      dispatch(changeSpecifications(type));
    };
    let changeModal = (name, status) => {
      changeTheme(props.themeGlobal.modal, name, status);
    };
    //Обновление статьи в бд
    let updateArticle = async (data, token, callback = null) => {
      if (data.isLoading === true || data.isError === true) {
        return console.log("Обновление данных невозможно");
      }

      let body = JSON.stringify(data);
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      fetch(`/api/article/update`, { method: "POST", body, headers })
        .then((res) => res.json())
        .then((result) => {
          //Если есть проблема с авторизацией
          if (result.error === 401) {
            localStorage.removeItem("userData");
            return changeTheme(props.themeGlobal.modal, "authError", true);
          }
          //Если данные в браузере устарели
          if (result.error === 1) {
            return changeTheme(props.themeGlobal.modal, "lastUpdate", true);
          }
          //Если данные не требуют обновления
          if (result.error === 2) {
            if (callback !== null) {
              callback(data, token);
            }
            return;
          }
          //Если произошла ошибка
          if (!result.lastUpdate) {
            return;
          }
          //Если все ок, обновляем версию
          saveLastUpdate(result.lastUpdate);
          if (callback !== null) {
            callback(data, token);
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    };
    let completeAritcle = (data, token, newstatus) => {
      let newStatus = async (data, token) => {
        data.newstatus = newstatus;
        let id = data._id;
        let body = JSON.stringify(data);
        let headers = {};
        headers["Authorization"] = `Bearer ${token}`;
        headers["Content-Type"] = "application/json";
        fetch(`/api/article/newStatus`, { method: "POST", body, headers })
          .then((res) => res.json())
          .then((result) => {
            console.log(result.message);
            navigate(dashboardLink);
            localStorage.removeItem(`article-${id}`);
          })
          .catch((err) => {
            console.log(err.message);
          });
      };

      updateArticle(data, token, newStatus);
    };
    const abandonTheArticle = (id, token) => {
      let dataObject = {
        id,
        action: "abandon",
      };

      let body = JSON.stringify(dataObject);
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      fetch(`/api/article/assignment`, { method: "POST", body, headers })
        .then((res) => res.json())
        .then((result) => {
          if (result.error !== true) {
            navigate(dashboardLink);
          } else {
            toaster.notify(result.message, {
              duration: 2000,
            });
          }
        })
        .catch((err) => {
          toaster.notify(err.message, {
            duration: 2000,
          });
        });
    };
    return {
      clickPastElement,
      clickDel,
      clickCopy,
      clickDown,
      clickTop,
      changeText,
      clickHiddenElementItemAll,
      clickHiddenElementItem,
      clickHiddenElement,
      clickHiddenButton,
      updateArticle,
      completeAritcle,
      clickAddItemNew,
      updateEditor,
      saveLastUpdate,
      changeTheme,
      actionChangePlanCharts,
      reloadArticle,
      abandonTheArticle,
      updateSpecifications,
      changeModal,
    };
  }, [props, dispatch, navigate, dashboardLink]);

  useEffect(() => {
    if (props.type === "article") {
      let timerId = setInterval(
        () => actions().updateArticle(props.state, props.token),
        15000
      );

      return () => {
        clearInterval(timerId);
      };
    }
  }, [props.state, props.type, props.token, actions]);

  return (
    <ContentBlocks
      theme={props.theme}
      isAdmin={props.isAdmin}
      actions={actions}
      state={props.state}
    />
  );
};

export default Content;
