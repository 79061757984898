import React from "react";
import { useSearchParams } from "react-router-dom";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import toaster from "src/hooks/toaster.hook";
import { AuthContext } from "src/context/AuthContext";
import getSearchParamsObject from "src/functions/getSearchParamsObject";

export const AvailableArticlesTable = (props) => {
  //Задаем параметры
  const [searchParams] = useSearchParams();
  const { token } = useContext(AuthContext);
  const searchParamsObject = getSearchParamsObject(searchParams.toString());
  const dispatch = useDispatch();

  //Получаем верный объект с датой
  let deadline = props.state.deadline;

  //Преобразуем в дату
  let deadlineTime = new Date(deadline);

  let deadlineFormated =
    deadlineTime.getDate() +
    "." +
    addZero(deadlineTime.getMonth() + 1) +
    "." +
    deadlineTime.getFullYear() +
    " " +
    addZero(deadlineTime.getHours()) +
    ":" +
    addZero(deadlineTime.getMinutes());

  //Форматируем дату
  function addZero(num) {
    var str = num.toString();
    return str.length === 1 ? "0" + str : str;
  }

  //Определяем просрочку дедлайна
  let deadlineStatus = "";
  if (props.deadlineStatus === true) {
    let deadlineMark = deadlineTime.getTime();
    let thisDateMark = Date.now();
    if (Number(thisDateMark) >= Number(deadlineMark)) {
      deadlineStatus = " article__value--time article__value--warning";
    } else if (Number(thisDateMark) >= Number(deadlineMark) - 86400000) {
      deadlineStatus = " article__value--time article__value--alert";
    } else {
      deadlineStatus = " article__value--time";
    }
  }

  const onTakeToWork = (data, callBackData) => {
    let dataObject = {
      id: props.state._id,
      action: "add",
    };

    let body = JSON.stringify(dataObject);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/assignment`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
          toaster.notify(`Работа ${props.state.name} взята успешно`, {
            duration: 2000,
          });
        } else {
          toaster.notify(result.message, {
            duration: 2000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 2000,
        });
      });
  };

  //Получаем опции
  let optionsProjects = props.options.projects;

  //Функция преобразования
  let getName = (options, sisName) => {
    let name = options.find((item) => {
      if (item.value === sisName) {
        return true;
      }
      return false;
    });
    //Если ничего не найдено
    if (name === undefined) {
      name = { label: "Нет" };
    }
    return name.label;
  };
  //Заменяем значеня на названия
  let project = props.state.project;
  let projectName = getName(optionsProjects, project);

  const categories = props.state.category
    .split(",")
    .map((artileCategoryItem) => {
      const name = props.options.category[project].find((optionsCategoryItem) => {
        if (artileCategoryItem.trim() === optionsCategoryItem.value) {
          return true;
        }
        return false;
      });
      if (name === undefined) {
        return "Нет";
      }
      return name.label;
    })
    .join(", ");

  return (
    <tr>
      <td className="tableBlock__td tableBlock__td--id">{props.num}</td>
      <td className="tableBlock__td">
        <div className="article__itemName article__value">{props.state.name}</div>
      </td>

      <td className="tableBlock__td">
        <div className={"article__value" + deadlineStatus}>{deadlineFormated}</div>
      </td>
      <td className="tableBlock__td">
        <div className="article__value">{props.state.payCount.charactersPlan}</div>
      </td>
      <td className="tableBlock__td">
        <div className="article__value">{projectName}</div>
      </td>
      <td className="tableBlock__td">
        <div className="article__value">{props.state.articleType}</div>
      </td>
      <td className="tableBlock__td tableBlock__td--select tableBlock__td--category">
        <div className="article__value">{categories}</div>
      </td>
      <td className="tableBlock__td">
        <div
          onClick={onTakeToWork}
          className="article__value article__value--active publishButtom--add"
        >
          Добавить к себе
        </div>
      </td>
    </tr>
  );
};
