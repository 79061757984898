import MarkdownIt from "markdown-it";
import markdownItMark from "markdown-it-mark";

const md = new MarkdownIt({ html: true }).use(markdownItMark);

export function getHtmlInMarkdown(data, config) {
  if (typeof data !== "string") {
    return data;
  }

  data = md.render(data);
  if (config.withoutParagraphs) {
    data = data.replace(/<p>/g, "");
    data = data.replace(/<\/p>/g, "");
  }
  return data;
}

export function replaceBbCodes(data) {
  if (!data) {
    return "";
  }

  data = data.replace(/\[\/ul\]/g, "");
  data = data.replace(/\[ul\]/g, "");

  data = data.replace(/\[\/ol\]/g, "");
  data = data.replace(/\[ol\]/g, "");

  data = data.replace(/\[\/li\]/g, "");
  data = data.replace(/\[li\]/g, "");

  data = data.replace(/\[\/h3\]/g, "");
  data = data.replace(/\[h3\]/g, "");

  data = data.replace(/\[\/bold\]/g, "");
  data = data.replace(/\[bold\]/g, "");

  data = data.replace(/\[\/headline\]/g, "");
  data = data.replace(/\[headline\]/g, "");

  return data;
}

//Фунция замены данных на входе
export function replaceGetData(data) {
  if (!data) {
    return "";
  }

  data = data.replace(/\[\/ul\]/g, "</ul>");
  data = data.replace(/\[ul\]/g, "<ul>");

  data = data.replace(/\[\/ol\]/g, "</ol>");
  data = data.replace(/\[ol\]/g, "<ol>");

  data = data.replace(/\[\/li\]/g, "</li>");
  data = data.replace(/\[li\]/g, "<li>");

  data = data.replace(/\[\/h3\]/g, "</h3>");
  data = data.replace(/\[h3\]/g, "<h3>");

  data = data.replace(/\[\/bold\]/g, "</strong>");
  data = data.replace(/\[bold\]/g, "<strong>");

  data = data.replace(/\[\/headline\]/g, "</mark>");
  data = data.replace(/\[headline\]/g, "<mark>");

  data = data.replace(/\r\n\r\n|\r\r|\n\n/g, "<br><br>");
  data = data.replace(/\r\n|\r|\n/g, "<br>");
  return data;
}

export function replaceBackData(data) {
  if (!data) {
    return "";
  }

  data = data.replace(/<h3>/g, "[h3]");
  data = data.replace(/<\/h3>/g, "[/h3]");

  data = data.replace(/<ul>/g, "[ul]");
  data = data.replace(/<\/ul>/g, "[/ul]");

  data = data.replace(/<ol>/g, "[ol]");
  data = data.replace(/<\/ol>/g, "[/ol]");

  data = data.replace(/<li>/g, "[li]");
  data = data.replace(/<\/li>/g, "[/li]");

  data = data.replace(/<strong>/g, "[bold]");
  data = data.replace(/<\/strong>/g, "[/bold]");

  data = data.replace(/<strong>/g, "[bold]");
  data = data.replace(/<\/strong>/g, "[/bold]");

  data = data.replace(/<mark>/g, "[headline]");
  data = data.replace(/<\/mark>/g, "[/headline]");

  data = data.replace(/&nbsp;/g, " ");
  data = data.replace(/&amp;/g, "&");
  data = data.replace(/&quot;/g, '"');

  data = data.replace(/<br><br>/g, "\n\n");
  data = data.replace(/<br>/g, "\n");
  return data;
}
