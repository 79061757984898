import React, { useState } from "react";
import Modal from "react-modal";
import toaster from "src/hooks/toaster.hook";
import copy from "copy-to-clipboard";
import checkExactDependencies from "src/functions/checkExactDependencies";
import Block from "src/components/Content/items/items/creatorBlock/Block";
import launchCreatorActions from "src/functions/launchCreatorActions";
import getObjectByStringPath from "src/functions/getObjectByStringPath";
import AddItemModal from "src/components/modal/AddItemModal";
import { nanoid } from "nanoid";

export const CreatorBlock = (props) => {
  const [itemsModalStatus, setItemsModalStatus] = useState(false);

  let creatorStatus = true;
  if (props.creator === false) {
    creatorStatus = false;
  }

  let copyStatus = false;
  if (props.copy === true) {
    copyStatus = true;
  }

  let actions = props.actions;

  const name = props.name || "Элементы";

  //Получаем имя массива, в котором содержаться элементы
  let itemsPatch;
  let itemsElement;
  if (props.itemsName) {
    if (!props.state[props.itemsName]) {
      return "";
    }
    itemsPatch = props.state[props.itemsName].items;
    itemsElement = props.state[props.itemsName];
  } else {
    if (!props.state.items) {
      return "";
    }
    itemsPatch = props.state.items;
    itemsElement = props.state;
  }

  let hiddenElementItemAllText = "--";
  if (itemsElement?.config?.hiddenInnerBlocks === true) {
    hiddenElementItemAllText = "++";
  }

  const creatorActionsDataArray = [
    props.actions,
    props.actionsOfChange,
    props.uniqueActionsOfChange,
    props.state,
    null,
    props.articleState,
    props.parentCreatorBlocks,
    null,
  ];

  //Добавляем новый элемент при клике
  let onClickAddItem = (data) => {
    let prototype = JSON.stringify(data?.localPrototype ?? props.themePrototype);
    prototype = JSON.parse(prototype);

    if (props.addSpecificationsToPrototype) {
      props.addSpecificationsToPrototype.forEach((item) => {
        const newSpec = JSON.parse(
          JSON.stringify(getObjectByStringPath(props.articleState.data, item.pathToSpecs))
        );
        const prototypeSpec = getObjectByStringPath(prototype, item.pathToPrototype);

        newSpec.forEach((item) => {
          item.globalId = nanoid();
          prototypeSpec.push(item);
        });
      });
    }

    const element = itemsElement;
    actions.clickAddItemNew(prototype, element);
    launchCreatorActions("add", ...creatorActionsDataArray, prototype);
  };

  const onClickPastElement = () => {
    const prototype = JSON.parse(JSON.stringify(props.themePrototype));
    const element = itemsElement;

    actions.clickPastElement(
      element,
      prototype,
      props.specificationsOfChildren,
      props.addSpecificationsToPrototype
    );
    launchCreatorActions("past", ...creatorActionsDataArray, prototype);
  };

  let onClickPastElementInItems = () => {
    setItemsModalStatus(true);
  };

  let onClickCopyElement = () => {
    let copyElement = JSON.stringify(itemsElement);
    copy(copyElement, {
      debug: true,
      message: "Нажмите #{key} чтобы скопировать",
    });
    toaster.notify("Cкопировано", {
      duration: 2000,
    });
    launchCreatorActions("copyCreator", ...creatorActionsDataArray);
  };

  //Кликаем на кнопку, для скрытия всех вложенных элементов
  let onClickHiddenElementItemAll = () => {
    let element = itemsElement;
    actions.clickHiddenElementItemAll(element);
  };

  // Проверка зависимости для креатора
  const blockDependenciesStatus = checkExactDependencies(
    props.exactDependencies,
    props.articleState,
    props.state
  );
  if (!blockDependenciesStatus) {
    return "";
  }

  const parentCreatorBlocks = props.parentCreatorBlocks ? props.parentCreatorBlocks : [];

  let Items = itemsPatch.map((item) => (
    <Block
      key={item.id}
      name={item.name}
      nameItems={props.nameItems}
      nameSize={props.nameSize}
      uniqueActionsOfChange={props.uniqueActionsOfChange}
      actionsOfChange={props.actionsOfChange}
      content={item.content}
      id={item.id}
      element={item}
      parentElement={itemsElement}
      parentCreatorBlocks={[...parentCreatorBlocks, props.state]}
      theme={props.theme}
      themeItems={props.themeItems}
      themeItemsHidden={props.themeItemsHidden}
      themePrototype={props.themePrototype}
      articleState={props.articleState}
      charactersItemsName={props.charactersItemsName}
      сharactersGlobal={props.сharactersGlobal}
      site={props.site}
      isAdmin={props.isAdmin}
      controls={props.controlItems}
      actions={props.actions}
      clickTop={props.actions.clickTop}
      clickDown={props.actions.clickDown}
      clickCopy={props.actions.clickCopy}
      clickDel={props.actions.clickDel}
      changeText={props.actions.changeText}
      clickHiddenElementItem={props.actions.clickHiddenElementItem}
      clickHiddenButton={props.actions.clickHiddenButton}
      level={props.level}
      readOnlyImportItemsStatus={props.readOnlyImportItemsStatus}
      createNewLastCreatorBlock={onClickAddItem}
    />
  ));

  return (
    <div className="element__block">
      <div className="element__header">
        {name && (
          <span className="element__blockName element__blockName--rmg">{name}</span>
        )}
        <div
          onClick={onClickHiddenElementItemAll}
          className="element__hiddenElement element__hiddenElement--nolmg"
        >
          {hiddenElementItemAllText}
        </div>
      </div>

      <div className="element__items">{Items}</div>
      {creatorStatus && !props.readOnlyImportItemsStatus && (
        <>
          <button onClick={onClickAddItem} className="element__button button">
            Добавить
          </button>
          <button
            onClick={onClickPastElement}
            className="element__button button--ots_left button--past button"
          >
            Вставить
          </button>
          {props.globalItemsConfig?.active && (
            <button
              onClick={onClickPastElementInItems}
              className="element__button button--ots_left button--past button"
            >
              Вставить существующие
            </button>
          )}
          {copyStatus && (
            <button
              onClick={onClickCopyElement}
              className="element__button button--ots_left button--past button"
            >
              Копировать
            </button>
          )}
        </>
      )}
      {props.globalItemsConfig?.active && (
        <Modal
          style={{
            content: {
              top: "65px",
              maxWidth: "650px",
              maxHeight: "300px",
              margin: "0 auto",
            },
            overlay: {
              zIndex: 5,
            },
          }}
          isOpen={itemsModalStatus}
          ariaHideApp={false}
          contentLabel="Example Modal"
        >
          <AddItemModal
            project={props.articleState.project}
            type={props.globalItemsConfig.type}
            setStatus={setItemsModalStatus}
            prototype={props.themePrototype}
            element={itemsElement}
            launchCreatorActions={launchCreatorActions}
            clickPastElement={actions.clickPastElement}
            creatorActionsDataArray={creatorActionsDataArray}
            specificationsToPrototype={props.addSpecificationsToPrototype}
            specificationsOfChildren={props.specificationsOfChildren}
          />
        </Modal>
      )}
    </div>
  );
};
