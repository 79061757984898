import React from "react";

const HeaderCreatorData = (props) => {
  //Получаем общее количество символов и лимит для статьи
  let countChart =
    Number(props.state.main.allCurrentCountCharts) -
    Number(props.state.main.excludeCountCharts);
  let limitChart = props.state.main.characters;
  const fullLimitChart = Math.round(Number(limitChart) * 0.05 + Number(limitChart));

  // //Считаем количество товаров
  // let countProduct = props.state.products.items.reduce((sum, item) => sum + item.items.reduce((sum) => sum + 1 , 0), 0)
  // let countAllProductVideo = props.state.products.items.reduce((sum, item) => sum + item.items.reduce((sum, item) => sum + item.video.items.reduce((sum) => sum + 1, 0), 0), 0)
  // let countProductVideo = props.state.products.items.reduce((sum, item) => sum + item.items.reduce((sum, item) => sum + (typeof(item.video.items[0]) === 'object' ? 1:0), 0), 0)
  // let countAllProductImage = props.state.products.items.reduce((sum, item) => sum + item.items.reduce((sum, item) => sum + item.images.items.reduce((sum) => sum + 1, 0), 0), 0)
  // let countProductImage = props.state.products.items.reduce((sum, item) => sum + item.items.reduce((sum, item) => sum + (typeof(item.images.items[0]) === 'object' ? 1:0), 0), 0)
  // let countAllVideoUniq = countProductVideo + props.state.videos.items.reduce((sum) => sum + 1 , 0) + props.state.choices.relatedVideos.items.reduce((sum) => sum + 1 , 0)
  // let countAllVideoMax = countProductVideo + (countProductVideo > 0 ? 5 : 0)
  return (
    <div className="header__right">
      {/* <div className="header__textItem">
                Товаров <span className="bold">{countProduct}</span> | Фото <span className="bold">{countAllProductImage}</span> ({countProductImage}) | Видео:  <span className="bold">{countAllProductVideo}</span> ({countProductVideo} | {countAllVideoUniq} / {countAllVideoMax})
            </div> */}
      <div className="header__textItem">
        Количество символов:{" "}
        <span className="bold">
          {countChart}{" "}
          {!!limitChart && (
            <>
              {" "}
              / {limitChart}-{fullLimitChart}
            </>
          )}
        </span>
      </div>
    </div>
  );
};

export default HeaderCreatorData;
