import { useState, useCallback } from "react";

//Хук для запросов на сервер
export const useHttp = () => {
  //Используем хуки Реакта для записи состояния загрузки и ошибки
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const request = useCallback(async (url, method = "GET", body = null, headers = {}) => {
    setLoading(true);

    try {
      //Если есть тело запроса, переводим его данные в строку
      if (body) {
        body = JSON.stringify(body);
        headers["Content-Type"] = "application/json";
      }

      //Делаем запрос на сервер и получаем ответ в json формате
      const response = await fetch(url, { method, body, headers });
      const data = await response.json();

      //Если данные не были передманы, возвращаем ошибку
      if (!response.ok) {
        throw new Error(data.message || "Что-то пошло не так");
      }

      setLoading(false);
      //Возвращаем данные
      return data;
    } catch (error) {
      setLoading(false);
      setError(error.message);
      throw error;
    }
  }, []);

  const clearError = useCallback(() => setError(null), []);

  return { loading, request, error, clearError };
};
