import { useContext, useState } from "react";
import AsyncSelect from "react-select/async";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const SelectItems = ({
  project,
  type,
  setMainState,
  value,
  classNames,
  valueName,
  readOnly,
}) => {
  const { token } = useContext(AuthContext);
  const [state, setState] = useState(value || []);

  if (!valueName) {
    valueName = "_id";
  }

  const loadItemsOptions = (inputValue, callback) => {
    let params = {
      text: inputValue,
      project,
      type,
    };

    params = JSON.stringify(params);

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    fetch("/api/items/all", { method: "POST", body: params, headers })
      .then((res) => res.json())
      .then((result) => {
        const items = result.data.map((item) => {
          return {
            _id: item._id,
            value: item[valueName],
            label: item.name,
          };
        });

        callback(items);
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  return (
    <AsyncSelect
      className={classNames}
      cacheOptions={false}
      loadOptions={loadItemsOptions}
      onChange={(value) => {
        setState(value);
        setMainState(value);
      }}
      placeholder={"Название"}
      value={state}
      isClearable={true}
      isMulti={true}
      isDisabled={readOnly}
    />
  );
};

export default SelectItems;
