function getVideoLink(link) {
  //Получаес картинку к видео
  let videoImg = link
    .replace("https://www.youtube.com/watch?v=", "")
    .replace("https://www.youtube.com/embed/", "")
    .replace("https://youtu.be/", "")
    .replace("www.youtube.com/embed/", "");
  videoImg = videoImg.replace(/&[\s\S]*/g, "").replace(/\?[\s\S]*/g, "");
  videoImg = "https://img.youtube.com/vi/" + videoImg + "/sddefault.jpg";
  return videoImg;
}

export default getVideoLink;
