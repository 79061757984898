import { connect, useDispatch } from "react-redux";
import Modal from "react-modal";
import { useContext, useEffect } from "react";
import { articleInitialData, articleInitialDataRequest } from "src/redux/articlesReducer";
import { useParams } from "react-router";
import { AuthContext } from "src/context/AuthContext";
import Content from "src/components/Content/Content";
import { Loader } from "src/components/Loader";

let modalStyle = {
  content: {
    top: "65px",
    maxWidth: "300px",
    maxHeight: "300px",
    margin: "0 auto",
    textAlign: "center",
    fontWeight: "bold",
  },
  overlay: {
    zIndex: 5,
  },
};

const ArticlePage = (props) => {
  const dispatch = useDispatch();

  const articleId = useParams().id;
  const { token, status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  useEffect(() => {
    dispatch(articleInitialData(articleId, token));
    return () => {
      dispatch(articleInitialDataRequest());
    };
  }, [articleId, token, dispatch]);

  if (props.state.isLoading === true) {
    return <Loader type="full" />;
  }

  if (props.state.isError === true) {
    if (props.state.errorCode === 401) {
      localStorage.removeItem("userData");
      return <p className="textCenter">Ошибка авторизации, перезагрузите эту страницу</p>;
    } else {
      return (
        <p className="textCenter">
          Ошибка загрузки данных. Если ошибка повторяется нажмите кнопку "Выйти" в самом
          верху и зайдите заново в редактор.
        </p>
      );
    }
  }

  const theme = props.theme[props.state.project.toLowerCase()];

  return (
    <>
      <div className="content">
        <Content
          isAdmin={isAdmin}
          token={token}
          theme={theme}
          state={props.state}
          themeGlobal={props.themeGlobal}
          type="article"
        />
      </div>
      <Modal
        isOpen={props.themeGlobal.modal.lastUpdate}
        style={modalStyle}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <p>Версия в браузере устарела, обновите страницу</p>
      </Modal>
      <Modal
        isOpen={props.themeGlobal.modal.authError}
        style={modalStyle}
        ariaHideApp={false}
        contentLabel="Example Modal"
      >
        <p>Ошибка авторизации, обновите страницу</p>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.articlesReducer,
    theme: state.themReducer.data.content,
    themeGlobal: state.themReducer.data.global,
  };
};

export default connect(mapStateToProps)(ArticlePage);
