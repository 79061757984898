import { AuthContext } from "src/context/AuthContext";
import React, { useContext } from "react";
import getObjectByStringPath from "src/functions/getObjectByStringPath";
import launchUniqueActions from "src/functions/unique/launchUniqueActions";
import toaster from "src/hooks/toaster.hook";

const InputBlock = (props) => {
  const { token } = useContext(AuthContext);

  const onChangeText = (e) => {
    let newText = e.target.value;

    if (props.type === "number") {
      newText = Number(e.target.value);
    }

    let name = e.target.name;
    //Получаем название вложенного объекта в state
    let parent = e.target.getAttribute("parent");
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.actionsReducer.changeText(newText, element, name);

    if (props.uniqueActions) {
      launchUniqueActions(
        props.actionsReducer,
        props.element,
        props.articleState,
        props.parentCreatorBlocks,
        props.uniqueActions
      );
    }
  };

  const onChangeActions = (e) => {
    props.actions.forEach((action) => {
      switch (action.name) {
        case "fetch":
          fetchAction(e, action.config);
          break;
        case "updateSpecifications":
          if (props.parentCreatorBlocks?.length > 0) {
            onChangeText(e);
            const parentElement =
              props.parentCreatorBlocks[props.parentCreatorBlocks.length - 1];
            const arrayId = parentElement.items.indexOf(props.element);

            props.actionsReducer.updateSpecifications({
              type: "update",
              element: props.element,
              text: e.target.value,
              arrayId,
              ...action.config,
            });
          }
          break;

        default:
          onChangeText(e);
          break;
      }
    });
  };

  async function fetchAction(e, config) {
    const value = e.target.value;
    const name = e.target.name;

    let data = new FormData();
    data.append("actionName", config.name);
    data.append("name", name);
    data.append("value", value);
    data.append("project", props.articleState.project);
    data.append("element", JSON.stringify(props.element));
    let body = data;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    fetch(config.url, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.success === 0) {
          toaster.notify(result?.message, {
            duration: 3000,
          });
          return;
        }

        props.actionsReducer.changeText(value, props.element, name);

        if (result.items) {
          result.items.forEach((item) => {
            if (item.uniqueActions) {
              return launchUniqueActions(
                props.actionsReducer,
                props.element,
                props.articleState,
                props.parentCreatorBlocks,
                item.uniqueActions
              );
            }

            if (item.global === true && item.path) {
              const element = getObjectByStringPath(props.articleState, item.path);
              if (!element) {
                return;
              }

              props.actionsReducer.changeText(item.value, element, item.name);
            } else {
              props.actionsReducer.changeText(item.value, props.element, item.name);
            }
          });
        }

        if (props.uniqueActions) {
          launchUniqueActions(
            props.actionsReducer,
            props.element,
            props.articleState,
            props.parentCreatorBlocks,
            props.uniqueActions
          );
        }
      })
      .catch((err) => {
        toaster.notify(err?.message, {
          duration: 3000,
        });
      });
  }

  if (props.access === "admin" && !props.isAdmin) {
    return "";
  }

  if (props.actions) {
    let inputAtr = { onChange: onChangeActions, value: props.value };
    if (props.onChangeActionsStatus === false) {
      inputAtr = { onBlur: onChangeActions, defaultValue: props.value };
    }

    return (
      <div className="element__block">
        <span className="element__blockName">{props.name}</span>
        <input
          type={props.type}
          autoComplete="off"
          name={props.item}
          className="element__input"
          {...inputAtr}
        />
      </div>
    );
  }

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <input
        type={props.type}
        autoComplete="off"
        onBlur={onChangeText}
        name={props.item}
        defaultValue={props.value}
        readOnly={props.readOnly}
        className="element__input"
      />
    </div>
  );
};

export default InputBlock;
