import React from "react";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { paysUpdateList } from "src/redux/paysReducer";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";
import AsyncSelecTableBlock from "src/components/Category/TableBlock/AsyncSelecTableBlock";

export const PaysItems = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  let isAdmin = props.isAdmin;

  let urlProject = searchParams.get("project");
  let urlUser = searchParams.get("user");
  let urlStatus = searchParams.get("status");
  let urlId = searchParams.get("id");

  let sortProjectOptions = props.themeGlobal.options.projects;
  let sortUsersOptions = props.themeGlobal.options.users;
  let sortStatusOptions = [
    { label: "Автор", value: "author" },
    { label: "Модератор", value: "moderator" },
  ];

  const page = Number(searchParams.get("page"));

  const pagination = {
    page,
    pageName: "page",
    limit: props.pagination.limit,
    next: props.pagination.next,
  };

  let tableNames = ["Дата", "Проект", "Название статьи", "Сумма", "Статус"];
  if (isAdmin) {
    tableNames = ["Дата", "Проект", "Название статьи", "Имя", "Сумма", "Статус"];
  }
  const options = props.themeGlobal.options;

  const chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  return (
    <>
      {isAdmin && (
        <div className="articleFilter">
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"project"}
            options={sortProjectOptions}
            value={urlProject}
            placeholder={"Проект"}
            isClearable={true}
            multi={true}
          />
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"status"}
            options={sortStatusOptions}
            value={urlStatus}
            placeholder={"Статус"}
            isClearable={true}
          />
          <SelectTableBlock
            callBack={chengeFilter}
            callBackData={"user"}
            options={sortUsersOptions}
            value={urlUser}
            placeholder={"Человек"}
            isClearable={true}
          />
          <AsyncSelecTableBlock
            callBack={chengeFilter}
            callBackData={"id"}
            value={urlId}
            placeholder={"Название"}
            isClearable={true}
            apiPatch="/api/article/findByProject"
            optionsPatch={{ items: "data", itemValue: "_id", itemLabel: "name" }}
          />
        </div>
      )}
      <div className="articleList">
        {!props.items.length && <p className="textCenter">Ничего не найдено</p>}

        {props.items.length > 0 && (
          <>
            <h2 className="articleList__title">Платежи</h2>
            <TableBlock
              type="pays"
              isAdmin={isAdmin}
              names={tableNames}
              items={props.items}
              options={options}
              callBack={paysUpdateList}
              pagination={pagination}
              chengeFilter={chengeFilter}
              isLoading={props.isLoading}
            />
          </>
        )}
      </div>
    </>
  );
};
