import { createSearchParams, useSearchParams } from "react-router-dom";
import { ElementBlock } from "src/components/Category/ElementBlock/ElementBlock";

const DashboardUser = ({
  articles,
  isAdmin,
  pagination: paginationGlobal,
  isLoading,
}) => {
  let articlesModerationActive = articles.moderationActive;
  let articlesCorrections = articles.corrections;
  let articlesModeration = articles.moderation;
  let articlesEditing = articles.editing;
  let articlesNotPaid = articles.notPaid;

  const [searchParams, setSearchParams] = useSearchParams();

  const pageModerationActive = Number(searchParams.get("pageModerationActive"));
  const pageCorrections = Number(searchParams.get("pageCorrections"));
  const pageEditing = Number(searchParams.get("pageEditing"));
  const pageModeration = Number(searchParams.get("pageModeration"));
  const pageNotPaid = Number(searchParams.get("pageNotPaid"));

  const pagination = {
    limit: paginationGlobal.limit,
  };

  let chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (data?.value) {
      let value = data.value;

      newParams.set(type, value);
    } else {
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  if (
    !articlesNotPaid?.length &&
    !articlesEditing?.length &&
    !articlesModeration?.length &&
    !articlesCorrections?.length &&
    !articlesModerationActive?.length
  ) {
    return <p className="textCenter">Ничего не найдено</p>;
  }

  return (
    <div className="articleList">
      {articlesModerationActive.length > 0 && (
        <>
          <h2 className="articleList__title">Модерация статей</h2>
          <ElementBlock
            deadlineStatus={true}
            linkStatus={true}
            isAdmin={isAdmin}
            items={articlesModerationActive}
            chengeFilter={chengeFilter}
            isLoading={isLoading}
            pagination={{
              ...pagination,
              pageName: "pageModerationActive",
              page: pageModerationActive,
              next: paginationGlobal.nextItems.moderationActive,
            }}
          />
        </>
      )}

      {articlesCorrections.length > 0 && (
        <>
          <h2 className="articleList__title">Необходимо исправить</h2>
          <ElementBlock
            deadlineStatus={true}
            linkStatus={true}
            isAdmin={isAdmin}
            items={articlesCorrections}
            chengeFilter={chengeFilter}
            isLoading={isLoading}
            pagination={{
              ...pagination,
              pageName: "pageCorrections",
              page: pageCorrections,
              next: paginationGlobal.nextItems.corrections,
            }}
          />
        </>
      )}

      {articlesEditing.length > 0 && (
        <>
          <h2 className="articleList__title">В работе</h2>
          <ElementBlock
            deadlineStatus={true}
            linkStatus={true}
            isAdmin={isAdmin}
            items={articlesEditing}
            chengeFilter={chengeFilter}
            isLoading={isLoading}
            pagination={{
              ...pagination,
              pageName: "pageEditing",
              page: pageEditing,
              next: paginationGlobal.nextItems.editing,
            }}
          />
        </>
      )}

      {articlesModeration.length > 0 && (
        <>
          <h2 className="articleList__title">На модерации</h2>
          <ElementBlock
            deadlineStatus={true}
            linkStatus={false}
            isAdmin={isAdmin}
            items={articlesModeration}
            chengeFilter={chengeFilter}
            isLoading={isLoading}
            pagination={{
              ...pagination,
              pageName: "pageModeration",
              page: pageModeration,
              next: paginationGlobal.nextItems.moderation,
            }}
          />
        </>
      )}

      {articlesNotPaid.length > 0 && (
        <>
          <h2 className="articleList__title">Ожидают оплаты</h2>
          <ElementBlock
            deadlineStatus={false}
            linkStatus={false}
            isAdmin={isAdmin}
            items={articlesNotPaid}
            chengeFilter={chengeFilter}
            isLoading={isLoading}
            pagination={{
              ...pagination,
              pageName: "pageNotPaid",
              page: pageNotPaid,
              next: paginationGlobal.nextItems.notPaid,
            }}
          />
        </>
      )}
    </div>
  );
};

export default DashboardUser;
