//Фунция перемещения элемента массива вверх и вниз
export function swapArray(arr, oldPlace, newPlace) {
  // Проверим выход за пределы массива
  if (Math.min(oldPlace, newPlace) < 0 || Math.max(oldPlace, newPlace) >= arr.length) {
    return null;
  }
  const item = arr.splice(oldPlace, 1);
  arr.splice(newPlace > 0 ? newPlace : 0, 0, item[0]);
  return arr;
}
