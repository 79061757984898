import { useCallback } from "react";
import toaster from "src/hooks/toaster.hook";

export const useMessage = () => {
  return useCallback((text) => {
    if (text) {
      toaster.notify(text, {
        duration: 2000,
      });
    }
  }, []);
};
