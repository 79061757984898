import React from "react";
import { NavLink } from "react-router-dom";

export const PayTable = (props) => {
  //Преобразуем в дату
  let date = new Date(props.state.date);

  //Форматируем дату
  function addZero(num) {
    var str = num.toString();
    return str.length === 1 ? "0" + str : str;
  }

  let dateFormated =
    date.getDate() +
    "." +
    (date.getMonth() + 1) +
    "." +
    date.getFullYear() +
    " " +
    addZero(date.getHours()) +
    ":" +
    addZero(date.getMinutes());

  //Получаем опции
  let optionsProjects = props.options.projects;

  //Заменяем значеня на названия
  let project = props.state.project;
  let name = optionsProjects.find((item) => {
    if (item.value === project) {
      return true;
    }
    return false;
  });
  //Если ничего не найдено
  if (name === undefined) {
    name = { label: "Нет" };
  }
  project = name.label;

  return (
    <tr>
      <td className="tableBlock__td">{dateFormated}</td>
      <td className="tableBlock__td">{project}</td>
      {!props.isAdmin && <td className="tableBlock__td">{props.state.title}</td>}
      {props.isAdmin && (
        <>
          <td className="tableBlock__td">
            {props.state.articleId && (
              <NavLink to={`/article/${props.state.articleId}`}>
                {props.state.title}
              </NavLink>
            )}
            {!props.state.articleId && props.state.title}
          </td>
          <td className="tableBlock__td">{props.state.name}</td>{" "}
        </>
      )}
      <td className="tableBlock__td">{props.state.count}</td>
      <td className="tableBlock__td">{props.state.type}</td>
    </tr>
  );
};
