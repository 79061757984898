import React from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
// import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
registerLocale("ru", ru);
setDefaultLocale(ru);

const DateBlock = (props) => {
  let dateValue;
  if (props.value && props.value !== "") {
    dateValue = new Date(props.value);
  } else {
    dateValue = new Date();
  }

  //Вносим изменения в текст
  let onChangeDate = (date) => {
    let newText = date;
    let name = props.item;
    //Получаем название вложенного объекта в state
    let parent = null;
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.changeText(newText, element, name);
  };

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <DatePicker
        className="element__input"
        onChange={(date) => onChangeDate(date)}
        selected={dateValue}
        showTimeSelect
        timeIntervals={60}
        dateFormat="dd.M.yyyy HH:mm"
        readOnly={props.readOnly}
      />
    </div>
  );
};

export default DateBlock;
