//Удаляем неуникальные ключи
export function removeDuplicates(books) {
  // Create an array of objects
  // let books = props.state.result.orfo

  // Display the list of array objects
  // console.log(books);

  // Declare a new array
  let newArray = [];

  // Declare an empty object
  let uniqueObject = {};

  // Loop for the array elements
  let i;
  for (i in books) {
    // Extract the title
    let objTitle = books[i]["word"];

    // Use the title as the index
    uniqueObject[objTitle] = books[i];
  }

  // Loop to push unique object into array
  for (i in uniqueObject) {
    newArray.push(uniqueObject[i]);
  }

  // Display the unique objects
  // console.log(newArray);
  return newArray;
}
