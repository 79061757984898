import React, { useState } from "react";
import { createSearchParams, useSearchParams } from "react-router-dom";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { usersInitialData } from "src/redux/usersReducer";

export const UsersItems = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  let isAdmin = props.isAdmin;
  let tableNames = [
    "Имя",
    "Телеграм",
    "Проекты",
    "Категории",
    "В работе",
    "На исправлении",
    "На проверке",
    "Проверяется",
    "Не оплачено",
    "Оплачено",
    "Активность",
    "Редактирование",
    "Удаление",
  ];

  const page = Number(searchParams.get("page"));

  const pagination = {
    page,
    pageName: "page",
    limit: props.pagination.limit,
    next: props.pagination.next,
  };

  const dashboardAdminCategory = [
    {
      id: 1,
      name: "Активные авторы",
      params: {
        activity: "true",
      },
    },
    {
      id: 2,
      name: "Неактивные авторы",
      params: {
        activity: "false",
      },
    },
  ];

  const [activeAdminCategory, setActiveAdminCategory] = useState(
    dashboardAdminCategory[0]
  );

  const itemsStatus = props.users.length > 0 ? true : false;

  const chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  const changeCategory = (num) => {
    const category = dashboardAdminCategory[Number(num) - 1];

    if (category) {
      const newParams = createSearchParams(category.params);
      setSearchParams(newParams);
      setActiveAdminCategory(category);
    }
  };

  return (
    <>
      <div className="articleCategory">
        <div className="articleCategory__fixer">
          <div className="articleCategory__scroll">
            <div className="articleCategory__items">
              <div
                onClick={() => changeCategory(1)}
                className="button button--ots_top button--ots_right"
              >
                Активные
              </div>
              <div
                onClick={() => changeCategory(2)}
                className="button button--ots_top button--ots_right"
              >
                Неактивные
              </div>
            </div>
          </div>
        </div>
      </div>
      {!itemsStatus && <p className="textCenter">Ничего не найдено</p>}
      {itemsStatus && (
        <div className="articleList">
          {isAdmin && (
            <>
              <h2 className="articleList__title">{activeAdminCategory.name}</h2>
              <TableBlock
                type="users"
                themeGlobal={props.themeGlobal}
                isAdmin={isAdmin}
                names={tableNames}
                items={props.users}
                pagination={pagination}
                chengeFilter={chengeFilter}
                isLoading={props.isLoading}
                updater={usersInitialData}
              />
            </>
          )}
        </div>
      )}
    </>
  );
};
