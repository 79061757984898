import React from "react";

const RadioBlock = (props) => {
  //Если есть диспатч каллбек добавляем его
  // if(props.dispatchCallback){
  //     props.actions[props.dispatchCallback]()
  // }

  //Изменяем значение radio в state
  let onChangeradio = (e) => {
    let value = e.target.value;
    let element = props.element;
    let name = e.target.name.split("-")[0];
    props.changeText(value, element, name);

    if (props.dispatchCallback) {
      let data = { element, config: props.dispatchCallbackConfig, value };
      props.actions[props.dispatchCallback](data);
    }
  };

  let Items = props.items.map((item, index) => {
    let globalId = "-" + String(props.element.globalId) || "";

    let nameItem;
    if (item.addId) {
      nameItem = item.item + globalId + "-" + String(props.element.id);
    } else {
      nameItem = item.item;
    }

    return (
      <div key={index}>
        <input
          readOnly={props.readOnly}
          autoComplete="off"
          type="radio"
          name={nameItem}
          element="active"
          value={item.value}
          checked={props.element[item.item] === item.value}
          onChange={onChangeradio}
        />
        {item.name}
      </div>
    );
  });

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <div className="element__radioButtons">{Items}</div>
    </div>
  );
};

export default RadioBlock;
