import { useState, useCallback, useEffect } from "react";

//Название объекта в localStorage
const storageName = "userData";

//Методы авторизации пользоватедей
export const useAuth = () => {
  const [token, setToken] = useState(null);
  const [userId, setUserId] = useState(null);
  const [sistemName, setSistemName] = useState(null);
  const [ready, setReady] = useState(false);
  const [status, setStatus] = useState(null);

  // Записываем в стейт и локалсторедж токен и ид пользователя
  const login = useCallback((jwtToken, id, sistemName, status) => {
    setToken(jwtToken);
    setUserId(id);
    setSistemName(sistemName);
    setStatus(status);
    localStorage.setItem(
      storageName,
      JSON.stringify({
        userId: id,
        sistemName,
        token: jwtToken,
        status: status,
      })
    );
  }, []);

  //Удаляем отовсуду значения
  const logout = useCallback(() => {
    setToken(null);
    setUserId(null);
    setSistemName(null);
    localStorage.clear();
    window.location.reload();
  }, []);

  const getMeStatus = useCallback(
    async (role, token) => {
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      fetch(`/api/users/meStatus`, { method: "GET", headers })
        .then((res) => res.json())
        .then((result) => {
          if (result.status !== true || result?.role !== role) {
            logout();
          }
        })
        .catch((err) => {
          console.log(err.message);
        });
    },
    [logout]
  );

  //Если у нас в локалсторедж есть токен, то входим в систему
  useEffect(() => {
    const data = JSON.parse(localStorage.getItem(storageName));

    if (data && data.token) {
      login(data.token, data.userId, data.sistemName, data.status);
      getMeStatus(data.status, data.token);
    }

    setReady(true);
  }, [login, getMeStatus]);

  //Возкращаем все данные
  return { login, logout, token, userId, sistemName, ready, status };
};
