import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import CreateArticlePage from "src/pages/CreateArticlePage";
import { AuthPage } from "src/pages/AuthPage";
import Header from "src/components/Header/Header";
import RegPage from "src/pages/RegPage";
import DashboardPage from "src/pages/DashboardPage";
import AllPage from "src/pages/AllPage";
import UsersPage from "src/pages/UsersPage";
import PaysPage from "src/pages/PaysPage";
import ProfilePage from "src/pages/ProfilePage";
import { IframeGamePage } from "src/pages/IframeGamePage";
import ThemeCreator from "src/ThemeCreator";
import AvailableArticlesPage from "src/pages/AvailableArticlesPage";
import ItemsPage from "src/pages/ItemsPage";
import ArticlePage from "src/pages/ArticlePage";
import ItemPage from "src/pages/ItemPage";

export const RouteItems = (props) => {
  let dashboardLink = "/dashboard";
  if (props.isAdmin) {
    dashboardLink =
      "/dashboard?status=editing%2Cmoderation%2Ccorrections&notMeModerationActive=yes";
  }

  if (props.isAuth) {
    return (
      <>
        {props.isAuth && <Header />}
        <div className="content">
          <ThemeCreator isAuth={props.isAuth}>
            <Routes>
              <Route path="/dashboard" element={<DashboardPage />} />
              <Route path="/available" element={<AvailableArticlesPage />} />
              <Route path="/items" element={<ItemsPage />} />
              <Route path="/item/:id" element={<ItemPage />} />
              <Route path="/all" element={<AllPage />} />
              <Route path="/create" element={<CreateArticlePage />} />
              <Route path="/users" element={<UsersPage />} />
              <Route path="/profile/:username" element={<ProfilePage />} />
              <Route path="/pays" element={<PaysPage />} />
              <Route path="/iframeGame" element={<IframeGamePage />} />
              <Route
                path="/reg"
                element={
                  <>
                    {props.status === "admin" && <RegPage />}
                    {props.status !== "admin" && "Нет доступа"}
                  </>
                }
              />
              <Route path="/article/:id" element={<ArticlePage />} />
              <Route path="*" element={<Navigate to={dashboardLink} />} />
            </Routes>
          </ThemeCreator>
        </div>
      </>
    );
  }

  return (
    <>
      <Header />
      <div className="content">
        <Routes>
          <Route path="/" element={<AuthPage />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </div>
    </>
  );
};
