import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import BlockItems from "src/components/Content/items/items/BlockItems";
import { CreatorBlock } from "src/components/Content/items/items/creatorBlock/CreatorBlock";

const GlobalBlock = (props) => {
  let actions = props.actions;

  let itemHiddenStatus = false;
  if (props.themeItemsHidden && props.themeItemsHidden.length > 0) {
    itemHiddenStatus = true;
  }

  //Логика активности/неактиновности раздела
  let inactiveText = "";
  let inactiveClass = "";
  if (props.state.config.active === false) {
    inactiveText = "(Не заполняется)";
    inactiveClass = " element__noActiveTitle--active";
  }

  //Скрываем и показываем блок с доп данными в зависимости от стейта
  let ShowHiddenBlockClass = "element__hidden";
  let blockHiddenText = "Дополнительные элементы";
  if (props.state.config.hiddenItems === false) {
    ShowHiddenBlockClass = "element__hidden element__hidden--active";
    blockHiddenText = "Скрыть дополнительные элементы";
  }

  //Кликаем на кнопку показа/скрытия доп данных
  let onClickHiddenButton = () => {
    let element = props.state;
    actions.clickHiddenButton(element);
  };

  //Скрываем и показываем блок элемента
  let ShowHiddenElementClass = "element " + props.theme.class;
  let HiddenElementText = "-";
  if (props.state.config.hidden === true) {
    ShowHiddenElementClass = "element element--hide " + props.theme.class;
    HiddenElementText = "+";
  }

  //Кликаем на кнопку показа/скрытия элемента
  let onClickHiddenElement = () => {
    let element = props.state;
    actions.clickHiddenElement(element);
  };

  //Вносим изменения в текст
  let onChangeText = (e) => {
    let newText = e.target.value;
    let name = e.target.name;
    //Получаем название вложенного объекта в state
    let parent = e.target.getAttribute("parent");
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.state[parent];
    } else {
      element = props.state;
    }
    actions.changeText(newText, element, name);
  };

  //Изменяем значение radio в state (Config)
  let onChangeradioInConfig = (e) => {
    let value = e.target.value;
    let element = props.state.config;
    let name = e.target.getAttribute("element");
    actions.changeText(value, element, name);
  };

  //План
  //Получаем критерии из плана
  let planKeys = props.state.plan?.keys;
  let planKeysAll = props.state.plan?.keysAll;
  let planText = props.state.plan?.text;
  let planCorrections = props.state.plan?.corrections;
  let planItems = props.state.plan?.items;
  let planFeatures = props.state.plan?.features;
  //Проверяем активность плана
  let planActive = false;
  if (
    planKeys ||
    planKeysAll ||
    planCorrections ||
    planItems ||
    planText ||
    planFeatures
  ) {
    planActive = true;
  }
  //Получаем название феатурес блока из плана
  const planFeaturesName = props.theme?.planFeaturesName || "";

  //Проверяем включенность компонента и наличие админки
  let ComponentStatus = props.state.config.active;

  const charactersItemsName = props.theme.charactersItemsName || "сharactersItems";

  let BlockContent = props.themeItems.map((item, index) => {
    let access = item.access;
    let adminStatus = props.isAdmin;
    let accessStatus = true;
    if ((access === "admin") & (adminStatus !== true)) {
      accessStatus = false;
    }
    if (!accessStatus) {
      return "";
    }

    if (item.type === "block") {
      return (
        <BlockItems
          key={index}
          item={item}
          element={props.state}
          parentCreatorBlocks={[props.state]}
          actions={props.actions}
          site={props.site}
          isAdmin={props.isAdmin}
          articleState={props.articleState}
          charactersItemsName={charactersItemsName}
          сharactersGlobal={props.state.config[charactersItemsName]}
          level={1}
        />
      );
    } else if (item.type === "creatorBlock") {
      return (
        <CreatorBlock
          key={index}
          actions={props.actions}
          state={props.state}
          parentCreatorBlocks={null}
          theme={item}
          themeItems={item.items}
          themeItemsHidden={item.itemsHidden}
          themePrototype={item.prototype}
          site={props.site}
          itemsName={item.itemsName}
          name={item.name}
          nameItems={item.nameItems}
          nameSize={item.nameSize}
          uniqueActionsOfChange={item.uniqueActions}
          actionsOfChange={item.actions}
          exactDependencies={item.exactDependencies}
          creator={item.creator}
          copy={item.copy}
          controlItems={item.controlItems}
          isAdmin={props.isAdmin}
          articleState={props.articleState}
          charactersItemsName={charactersItemsName}
          сharactersGlobal={props.state.config[charactersItemsName]}
          level={1}
          addSpecificationsToPrototype={item.addSpecificationsToPrototype}
          specificationsOfChildren={item.specificationsOfChildren}
          globalItemsConfig={item.globalItemsConfig}
        />
      );
    }
    return "";
  });

  let HiddenBlockContent = "";
  if (itemHiddenStatus) {
    HiddenBlockContent = props.themeItemsHidden.map((item, index) => {
      let access = item.access;
      let adminStatus = props.isAdmin;
      let accessStatus = true;
      if ((access === "admin") & (adminStatus !== true)) {
        accessStatus = false;
      }
      if (!accessStatus) {
        return "";
      }

      if (item.type === "block") {
        return (
          <BlockItems
            key={index}
            item={item}
            element={props.state}
            parentCreatorBlocks={[props.state]}
            actions={props.actions}
            site={props.site}
            isAdmin={props.isAdmin}
            articleState={props.articleState}
            charactersItemsName={charactersItemsName}
            сharactersGlobal={props.state.config[charactersItemsName]}
            level={1}
          />
        );
      } else if (item.type === "creatorBlock") {
        return (
          <CreatorBlock
            key={index}
            actions={props.actions}
            state={props.state}
            parentCreatorBlocks={null}
            theme={item}
            themeItems={item.items}
            themeItemsHidden={item.itemsHidden}
            themePrototype={item.prototype}
            site={props.site}
            itemsName={item.itemsName}
            name={item.name}
            nameItems={item.nameItems}
            nameSize={item.nameSize}
            uniqueActionsOfChange={item.uniqueActions}
            actionsOfChange={item.actions}
            exactDependencies={item.exactDependencies}
            creator={item.creator}
            copy={item.copy}
            controlItems={item.controlItems}
            isAdmin={props.isAdmin}
            articleState={props.articleState}
            charactersItemsName={charactersItemsName}
            сharactersGlobal={props.state.config[charactersItemsName]}
            level={1}
            addSpecificationsToPrototype={item.addSpecificationsToPrototype}
            specificationsOfChildren={item.specificationsOfChildren}
            globalItemsConfig={item.globalItemsConfig}
          />
        );
      }
      return "";
    });
  }

  if (ComponentStatus === false && props.isAdmin === false) {
    return "";
  } else {
    return (
      <div className={ShowHiddenElementClass}>
        <div className="element__title element__title--group">
          <div className="element__titleLeft">
            {props.theme.name}{" "}
            <span className={"element__noActiveTitle" + inactiveClass}>
              {inactiveText}
            </span>
            <div onClick={onClickHiddenElement} className="element__hiddenElement">
              {HiddenElementText}
            </div>
          </div>
          <div className="element__titleRight">
            {/* <div className="element__countChart">                        {Number(countChart) > Number(limitChart) &&
                        <>Символов:&nbsp;<span className="alert">{countChart}</span>{'/' + limitChart}</>
                        }
                        {Number(countChart) <= Number(limitChart) &&
                        <>Символов:&nbsp;{countChart + '/' + limitChart}</>
                        }</div> */}
          </div>
        </div>

        {planActive !== false && (
          <div className="element__plan">
            {planCorrections && (
              <div className="element__planItem element__planItem--alert">
                <span className="bold">Нужно исправить:</span> {planCorrections}
              </div>
            )}
            {planItems && (
              <div className="element__planItem  element__planItem--warning">
                <span className="bold">План:</span> {planItems}
              </div>
            )}
            {planKeys && (
              <div className="element__planItem element__planItem--warning">
                <span className="bold">Добавьте во вступление слова и фразы:</span>{" "}
                {planKeys}
              </div>
            )}
            {planKeysAll && (
              <div className="element__planItem element__planItem--warning">
                <span className="bold">
                  Необходимо добавить в текст минимум 1 раз (* - точное вхождение):
                </span>{" "}
                {planKeysAll}
              </div>
            )}
            {planText && (
              <div className="element__planItem element__planItem--warning">
                <span className="bold">Рекоменации:</span> {planText}
              </div>
            )}
            {planFeatures && (
              <div className="element__planItem element__planItem--warning">
                <span className="bold">{planFeaturesName}</span> {planFeatures}
              </div>
            )}
          </div>
        )}

        {BlockContent}

        {props.articleStatus === "moderation" && props.state.config.type === "main" && (
          <div className="element__block element__item">
            <span className="element__blockName">Доработки</span>
            <TextareaAutosize
              onBlur={onChangeText}
              name="corrections"
              parent="plan"
              defaultValue={props.state.plan.corrections}
              className="element__textarea"
            />
          </div>
        )}

        <div className="element__blockHidden">
          <div className={ShowHiddenBlockClass}>
            {HiddenBlockContent}

            {props.isAdmin === true && !window.location.href.includes("create") && (
              <div className="element__hiddenUsers">
                <div className="element__block element__item">
                  <span className="element__blockName">Статус элемента</span>
                  <div className="element__radioButtons">
                    <div>
                      <input
                        autoComplete="off"
                        type="radio"
                        name={props.state.config.type + "Status"}
                        element="active"
                        value="true"
                        checked={props.state.config.active === true}
                        onChange={onChangeradioInConfig}
                      />
                      Активный
                    </div>
                    <div>
                      <input
                        autoComplete="off"
                        type="radio"
                        name={props.state.config.type + "Status"}
                        element="active"
                        value="false"
                        checked={props.state.config.active === false}
                        onChange={onChangeradioInConfig}
                      />
                      Не активный
                    </div>
                  </div>
                </div>
                {props.state.config.type === "main" && (
                  <div className="element__block element__item">
                    <span className="element__blockName">Все слова</span>
                    <TextareaAutosize
                      onBlur={onChangeText}
                      name="keysAll"
                      parent="plan"
                      defaultValue={props.state.plan.keysAll}
                      className="element__textarea"
                    />
                    <span className="element__blockName">Слова для встулпения</span>
                    <TextareaAutosize
                      onBlur={onChangeText}
                      name="keys"
                      parent="plan"
                      defaultValue={props.state.plan.keys}
                      className="element__textarea"
                    />
                    <span className="element__blockName">Рекоменации</span>
                    <TextareaAutosize
                      onBlur={onChangeText}
                      name="text"
                      parent="plan"
                      defaultValue={props.state.plan.text}
                      className="element__textarea"
                    />
                    <span className="element__blockName">План</span>
                    <TextareaAutosize
                      onBlur={onChangeText}
                      name="items"
                      parent="plan"
                      defaultValue={props.state.plan.items}
                      className="element__textarea"
                    />
                    <span className="element__blockName">Доработки</span>
                    <TextareaAutosize
                      onBlur={onChangeText}
                      name="corrections"
                      parent="plan"
                      defaultValue={props.state.plan.corrections}
                      className="element__textarea"
                    />
                  </div>
                )}
              </div>
            )}
          </div>
          <div onClick={onClickHiddenButton} className="element__hiddenButton button">
            {blockHiddenText}
          </div>
        </div>
      </div>
    );
  }
};

export default GlobalBlock;
