import React from "react";
import { TableBlock } from "src/components/Category/TableBlock/TableBlock";
import { createSearchParams, useSearchParams } from "react-router-dom";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";
import Creatable from "react-select/creatable";
import AsyncSelecTableBlock from "src/components/Category/TableBlock/AsyncSelecTableBlock";
import { itemsInitialData } from "src/redux/itemsReducer";

export const AllItems = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const isAdmin = props.isAdmin;

  const page = Number(searchParams.get("page"));
  const urlProject = searchParams.get("project");
  const urlType = searchParams.get("type");
  const urlId = searchParams.get("id");

  const articleItemById = props?.items?.find((item) => item._id === urlId);
  const urlSelectValue = articleItemById
    ? { value: urlId, label: articleItemById.name }
    : null;

  const sortProjectOptions = props.themeGlobal.options.projects;

  const tableNames = ["№", "Название", "Проект", "Тип", "Дата создания", "Открыть"];

  if (isAdmin) {
    tableNames.push("Удалить");
  }

  const pagination = {
    page,
    pageName: "page",
    limit: props.pagination.limit,
    next: props.pagination.next,
  };

  let chengeFilter = (data, type) => {
    const newParams = createSearchParams(searchParams);

    if (page && type !== "page") {
      newParams.delete("page");
    }

    let arrayStatus = false;
    if (Array.isArray(data) && data.length > 0) {
      arrayStatus = true;
    }

    if (data?.value || arrayStatus) {
      let value = data.value;
      if (arrayStatus) {
        data = data.map((item) => {
          return item.value;
        });
        value = data.join(",");
      }

      newParams.set(type, value);
    } else {
      if (type === "project") {
        newParams.delete("category");
      }
      newParams.delete(type);
    }
    setSearchParams(newParams);
  };

  return (
    <>
      <div className="articleFilter">
        <SelectTableBlock
          callBack={chengeFilter}
          callBackData={"project"}
          options={sortProjectOptions}
          value={urlProject}
          placeholder={"Проект"}
          isClearable={true}
          multi={true}
        />
        <Creatable
          onChange={(data) => {
            chengeFilter(data, "type");
          }}
          value={urlType ? { label: urlType, value: urlType } : null}
          placeholder={"Тип"}
          isClearable={true}
        />
        <AsyncSelecTableBlock
          callBack={chengeFilter}
          callBackData={"id"}
          value={urlSelectValue}
          placeholder={"Название"}
          isClearable={true}
          apiPatch="/api/items/all"
          optionsPatch={{ items: "data", itemValue: "_id", itemLabel: "name" }}
        />
      </div>

      <div className="articleList">
        {!props.items.length && <p className="textCenter">Ничего не найдено</p>}

        {props.items.length > 0 && (
          <>
            <h2 className="articleList__title">Элементы</h2>
            <TableBlock
              deadlineStatus={false}
              isAdmin={isAdmin}
              names={tableNames}
              items={props.items}
              themeGlobal={props.themeGlobal}
              updater={itemsInitialData}
              chengeFilter={chengeFilter}
              pagination={pagination}
              isLoading={props.isLoading}
              type={"items"}
            />
          </>
        )}
      </div>
    </>
  );
};
