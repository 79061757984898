import React from "react";
import { ArticleTable } from "src/components/Category/TableBlock/ArticleTable";
import { UserTable } from "src/components/Category/TableBlock/UserTable";
import { PayTable } from "src/components/Category/TableBlock/PayTable";
import { Loader } from "src/components/Loader";
import { AvailableArticlesTable } from "src/components/Category/TableBlock/AvailableArticlesTable";
import { ItemsTable } from "src/components/Category/TableBlock/ItemsTable";

export const TableBlock = (props) => {
  let tableNames = props.names;
  let tableItems = props.items;

  const page = props.pagination?.page;

  return (
    <div className="articleList__tablerWraper">
      <div className="articleList__table">
        {props.isLoading && (
          <div>
            <Loader />
          </div>
        )}
        <table className="tableBlock">
          <thead>
            <tr>
              {tableNames.map((item, index) => {
                return <th key={index}>{item}</th>;
              })}
            </tr>
          </thead>
          <tbody>
            {props.type === "users" && (
              <>
                {tableItems.map((item, index) => {
                  return (
                    <UserTable
                      key={item._id}
                      state={item}
                      linkStatus={true}
                      deadlineStatus={true}
                      themeGlobal={props.themeGlobal}
                      isAdmin={props.isAdmin}
                      callBack={props.callBack}
                      updater={props.updater}
                    />
                  );
                })}
              </>
            )}
            {props.type === "pays" && (
              <>
                {tableItems.map((item, index) => {
                  return (
                    <PayTable
                      key={item._id}
                      state={item}
                      options={props.options}
                      callBack={props.callBack}
                      isAdmin={props.isAdmin}
                    />
                  );
                })}
              </>
            )}
            {props.type === "availableArticles" && (
              <>
                {tableItems.map((item, index) => {
                  return (
                    <AvailableArticlesTable
                      key={item._id}
                      num={index + 1}
                      state={item}
                      deadlineStatus={true}
                      isAdmin={props.isAdmin}
                      options={props.options}
                      callBack={props.callBack}
                      updater={props.updater}
                    />
                  );
                })}
              </>
            )}
            {props.type === "items" && (
              <>
                {tableItems.map((item, index) => {
                  return (
                    <ItemsTable
                      key={item._id}
                      num={index + 1}
                      state={item}
                      linkStatus={true}
                      deadlineStatus={false}
                      themeGlobal={props.themeGlobal}
                      isAdmin={props.isAdmin}
                      callBack={props.callBack}
                      updater={props.updater}
                    />
                  );
                })}
              </>
            )}
            {props.type !== "users" &&
              props.type !== "pays" &&
              props.type !== "items" &&
              props.type !== "availableArticles" && (
                <>
                  {tableItems.map((item, index) => {
                    return (
                      <ArticleTable
                        key={item._id}
                        num={index + 1}
                        state={item}
                        linkStatus={true}
                        deadlineStatus={props.deadlineStatus}
                        isAdmin={props.isAdmin}
                        options={props.options}
                        callBack={props.callBack}
                        updater={props.updater}
                      />
                    );
                  })}
                </>
              )}
          </tbody>
        </table>
      </div>
      {props.pagination && (
        <div className="paginationButtons">
          {page > 1 && (
            <div
              onClick={() => {
                let value;
                if (page <= 2) {
                  value = null;
                } else {
                  value = String(page - 1);
                }
                props.chengeFilter({ value }, props.pagination.pageName);
              }}
              className="button"
            >
              Предыдущая страница
            </div>
          )}
          {page > 0 && <div className="paginationButtons__counter">{page}</div>}
          {props.pagination.next && (
            <div
              onClick={() => {
                let value = String(page + 1);
                if (!page || page < 2) {
                  value = "2";
                }

                props.chengeFilter({ value }, props.pagination.pageName);
              }}
              className="button"
            >
              Следующая страница
            </div>
          )}
        </div>
      )}
    </div>
  );
};
