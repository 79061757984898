import { connect, useDispatch } from "react-redux";
import { useContext, useEffect } from "react";
import {
  itemInitialData,
  itemInitialDataRequest,
  saveLastUpdateAction,
} from "src/redux/articlesReducer";
import { useParams } from "react-router";
import { AuthContext } from "src/context/AuthContext";
import Content from "src/components/Content/Content";
import { Loader } from "src/components/Loader";
import objectPath from "object-path";
import toaster from "src/hooks/toaster.hook";

const ItemPage = (props) => {
  const dispatch = useDispatch();

  const itemId = useParams().id;
  const { token, status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  useEffect(() => {
    dispatch(itemInitialData(itemId, token));
    return () => {
      dispatch(itemInitialDataRequest());
    };
  }, [itemId, token, dispatch]);

  if (props.state.isLoading === true) {
    return <Loader type="full" />;
  }

  if (props.state.isError === true) {
    if (props.state.errorCode === 401) {
      localStorage.removeItem("userData");
      return <p className="textCenter">Ошибка авторизации, перезагрузите эту страницу</p>;
    } else {
      return (
        <p className="textCenter">
          {props.state.error.message ||
            `Ошибка загрузки данных. Если ошибка повторяется нажмите кнопку "Выйти" в самом
          верху и зайдите заново в редактор.`}
        </p>
      );
    }
  }

  const theme = props.theme[props.state.project.toLowerCase()];

  const pathItemTheme = theme?.main?.articleItems?.templates?.find(
    (template) => template.type === props.state.templateType
  )?.prototypePath;
  const itemTheme = JSON.parse(JSON.stringify(objectPath.get(theme, pathItemTheme)));

  const localTheme = {
    item: {
      ...itemTheme,
      class: "editorBlock",
      name: props.state?.data?.name || props.state.name || "Элемент",
    },
  };
  const localState = {
    data: { item: props.state.data },
  };

  const onSaveItem = () => {
    let body = JSON.stringify(props.state);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/items/updateOne`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (!result.lastUpdate) {
          throw new Error("Возникла ошибка при обновлении");
        }
        dispatch(saveLastUpdateAction(result.lastUpdate));
        toaster.notify(result.message, {
          duration: 5000,
        });
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 5000,
        });
      });
  };

  return (
    <div className="content">
      <Content
        isAdmin={isAdmin}
        token={token}
        theme={localTheme}
        state={localState}
        themeGlobal={props.themeGlobal}
        type="item"
      />
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Связанные статьи</div>
        </div>
        <ul>
          {props.state.articles.map((item, index) => (
            <li key={index}>
              <a href={`/article/${item._id}`}>{item.name}</a>
            </li>
          ))}
        </ul>
      </div>
      {isAdmin && (
        <div className="element editorBlock">
          <div onClick={onSaveItem} className="button button--colors">
            Сохранить
          </div>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.articlesReducer,
    theme: state.themReducer.data.content,
    themeGlobal: state.themReducer.data.global,
  };
};

export default connect(mapStateToProps)(ItemPage);
