import React from "react";
import InputBlock from "src/components/Content/items/items/InputBlock";
import TextareaBlock from "src/components/Content/items/items/TextareaBlock";
import YotubePlaceholder from "src/components/Content/items/items/YotubePlaceholder";
import ImgBlockNew from "src/components/Content/items/items/ImgBlockNew";
import RadioBlock from "src/components/Content/items/items/RadioBlock";
import DateBlock from "src/components/Content/items/items/DateBlock";
import SelectBlock from "src/components/Content/items/items/SelectBlock";
import AddStateBlock from "src/components/Content/items/items/AddStateBlock";
import AsyncSelectBlock from "src/components/Content/items/items/AsyncSelectBlock";
import LoadSelectBlock from "src/components/Content/items/items/LoadSelectBlock";
import SpecificationsBlockItems from "src/components/Content/items/items/SpecificationsBlockItems";
import { CreatorBlock } from "src/components/Content/items/items/creatorBlock/CreatorBlock";
import LinkBlock from "src/components/Content/items/items/LinkBlock";
import TextBlock from "src/components/Content/items/items/TextBlock";
import checkExactDependencies from "src/functions/checkExactDependencies";
import ItemsSelectBlock from "src/components/Content/items/items/ItemsSelectBlock";
import getObjectByStringPath from "src/functions/getObjectByStringPath";
import objectPath from "object-path";
import { EditorJsWrapper } from "src/components/Content/items/EditorJsWrapper";

const BlockItems = (props) => {
  const articleState = props.articleState;
  const blockItems = props.item.items;

  //Проверка зависимостей для блоков
  const blockDependenciesStatus = checkExactDependencies(
    props.item.exactDependencies,
    articleState,
    props.element
  );
  if (!blockDependenciesStatus) {
    return "";
  }

  let emptyCount = 0;
  let BlockContent = blockItems.map((item, index) => {
    //Проверка зависимостей для отдельных полей
    const itemStatus = checkExactDependencies(
      item.exactDependencies,
      articleState,
      props.element
    );
    if (!itemStatus) {
      emptyCount++;
      return "";
    }

    let readOnly = false;
    if (item.readOnly) {
      readOnly = true;
    }
    if (props.readOnlyImportItemsStatus) {
      readOnly = props.readOnlyImportItemsStatus;
      if (item.notReadOnlyImportItem) {
        readOnly = false;
      }
    }

    let сharactersItems = {};

    if (item.type === "input") {
      return (
        <InputBlock
          key={index}
          actionsReducer={props.actions}
          name={item.name}
          item={item.item}
          type={item.typeInfo}
          access={item.access}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          value={
            item.nestedElement
              ? props.element[item.nestedElement][item.item]
              : props.element[item.item]
          }
          parentCreatorBlocks={props.parentCreatorBlocks}
          articleState={articleState}
          isAdmin={props.isAdmin}
          actions={item.actions}
          uniqueActions={item.uniqueActions}
          onChangeActionsStatus={item.onChangeActionsStatus}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "date") {
      return (
        <DateBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          item={item.item}
          value={props.element[item.item]}
          element={props.element}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "select") {
      return (
        <SelectBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          item={item.item}
          options={item.options}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          value={
            item.nestedElement
              ? props.element[item.nestedElement][item.item]
              : props.element[item.item]
          }
          multi={item.multi}
          closeMenuOnSelect={item.closeMenuOnSelect}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "category") {
      return (
        <SelectBlock
          key={index}
          changeText={props.actions.changeText}
          name={"Категории"}
          item={"category"}
          options={item.options}
          value={articleState.category}
          element={articleState}
          multi={true}
          closeMenuOnSelect={false}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "selectLoad") {
      return (
        <LoadSelectBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          item={item.item}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          value={
            item.nestedElement
              ? props.element[item.nestedElement][item.item]
              : props.element[item.item]
          }
          loadPatch={item.patch}
          multi={item.multi}
          closeMenuOnSelect={item.closeMenuOnSelect}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "selectAsync") {
      return (
        <AsyncSelectBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          item={item.item}
          options={item.options}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          value={
            item.nestedElement
              ? props.element[item.nestedElement][item.item]
              : props.element[item.item]
          }
          multi={item.multi}
          closeMenuOnSelect={item.closeMenuOnSelect}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "textarea") {
      сharactersItems = props.element?.config[props.charactersItemsName];

      if (!сharactersItems || !сharactersItems[item.item]) {
        сharactersItems =
          getCharactersGlobalItemsLevel(props.сharactersGlobal, props.level) || {};

        function getCharactersGlobalItemsLevel(сharactersGlobal, level) {
          let charactersGlobalItems = сharactersGlobal;

          for (let i = 1; i < level; i++) {
            if (charactersGlobalItems) {
              charactersGlobalItems = charactersGlobalItems?.items;
            }
          }

          return charactersGlobalItems;
        }
      }

      return (
        <TextareaBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          item={item.item}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          value={
            item.nestedElement
              ? props.element[item.nestedElement][item.item]
              : props.element[item.item]
          }
          сharacters={
            сharactersItems[item.item]
              ? сharactersItems[item.item]
              : item.defaultCharacters
          }
          readOnly={readOnly}
        />
      );
    } else if (item.type === "yotubePlaceholder") {
      return <YotubePlaceholder key={index} link={props.element.link} />;
    } else if (item.type === "imgBlock") {
      return (
        <ImgBlockNew
          key={index}
          blockName={item.name}
          patch={item.patch}
          changeText={props.actions.changeText}
          actions={props.actions}
          changeImgStatus={true}
          element={item.nestedElement ? props.element[item.nestedElement] : props.element}
          origElement={props.element}
          namePropertyName={item.imgName}
          noNestedName={item.noNestedName}
          site={props.site}
          readOnly={readOnly}
          isMulti={item.isMulti}
          themePrototype={props.themePrototype}
          createNewLastCreatorBlock={props.createNewLastCreatorBlock}
        />
      );
    } else if (item.type === "radio") {
      return (
        <RadioBlock
          key={index}
          changeText={props.actions.changeText}
          name={item.name}
          items={item.items}
          element={props.element}
          dispatchCallback={item.dispatchCallback}
          dispatchCallbackConfig={item.dispatchCallbackConfig}
          actions={props.actions}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "addState") {
      return (
        <AddStateBlock
          key={index}
          pastState={props.actions.pastState}
          name={item.name}
          value={props.element[item.item]}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "specifications") {
      return (
        <SpecificationsBlockItems
          key={index}
          actions={props.actions}
          changeText={props.actions.changeText}
          items={objectPath.get(props.element, item.pathToItemsFromElement)}
          element={props.element}
          parentCreatorBlocks={props.parentCreatorBlocks}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "creatorBlock") {
      return (
        <CreatorBlock
          key={index}
          actions={props.actions}
          state={props.element}
          parentCreatorBlocks={props.parentCreatorBlocks}
          theme={item}
          themeItems={item.items}
          themeItemsHidden={item.itemsHidden}
          themePrototype={item.prototype}
          site={props.site}
          itemsName={item.itemsName}
          name={item.name}
          nameItems={item.nameItems}
          nameSize={item.nameSize}
          uniqueActionsOfChange={item.uniqueActions}
          actionsOfChange={item.actions}
          exactDependencies={item.exactDependencies}
          isAdmin={props.isAdmin}
          creator={item.creator}
          copy={item.copy}
          articleState={props.articleState}
          charactersItemsName={props.charactersItemsName}
          сharactersGlobal={props.сharactersGlobal}
          addSpecificationsToPrototype={item.addSpecificationsToPrototype}
          specificationsOfChildren={item.specificationsOfChildren}
          globalItemsConfig={item.globalItemsConfig}
          readOnlyImportItemsStatus={props.readOnlyImportItemsStatus}
        />
      );
    } else if (item.type === "block") {
      return (
        <BlockItems
          key={index}
          item={item}
          element={props.element}
          parentCreatorBlocks={props.parentCreatorBlocks}
          parentElement={props.parentElement}
          actions={props.actions}
          site={props.site}
          isAdmin={props.isAdmin}
          articleState={props.articleState}
          charactersItemsName={props.charactersItemsName}
          сharactersGlobal={props.сharactersGlobal}
          level={props.level}
          readOnlyImportItemsStatus={props.readOnlyImportItemsStatus}
          themePrototype={props.themePrototype}
          createNewLastCreatorBlock={props.createNewLastCreatorBlock}
        />
      );
    } else if (item.type === "link") {
      return (
        <LinkBlock
          key={index}
          item={item.item}
          name={item.name}
          element={props.element}
        />
      );
    } else if (item.type === "text") {
      return (
        <TextBlock
          key={index}
          item={item.item}
          element={props.element}
          name={item.name}
        />
      );
    } else if (item.type === "relatedItems") {
      return (
        <ItemsSelectBlock
          key={index}
          item={item.item}
          name={item.name}
          element={props.element}
          project={articleState.project}
          type={
            item.itemsTypePath
              ? getObjectByStringPath(articleState, item.itemsTypePath)
              : item.itemsType
          }
          actions={props.actions}
          readOnly={readOnly}
        />
      );
    } else if (item.type === "editor") {
      return (
        <EditorJsWrapper
          key={index}
          updateEditor={(data) => {
            return props.actions.changeText(data, props.element, "data");
          }}
          state={props.element.data}
          classItem={"element__block element__block--editor"}
          editorClass={`editorBlocks-${props.element?.config?.type}-${index}`}
          site={articleState.project}
          readOnly={readOnly}
        />
      );
    }
    return "";
  });

  //Если пустой массив, не выводим блок
  if (blockItems.length === 0 || emptyCount === blockItems.length) {
    return "";
  }

  return (
    <div className={"element__item " + props.item.class}>
      {props.item.name && <div className="element__name">{props.item.name}</div>}
      {BlockContent}
    </div>
  );
};

export default BlockItems;
