const THEME_INITIAL_DATA_REQUEST = "THEME_INITIAL_DATA_REQUEST";
const THEME_INITIAL_DATA_SUCCESS = "THEME_INITIAL_DATA_SUCCESS";
const THEME_INITIAL_DATA_ERROR = "THEME_INITIAL_DATA_ERROR";
const CHANGE_THEME = "CHANGE_THEME";

let initialState = {
  data: null,
  isLoading: true,
  isError: false,
  error: null,
  errorCode: null,
};

const themReducer = (state = initialState, action) => {
  const payload = action.payload;
  const data = action.data;

  switch (action.type) {
    case CHANGE_THEME:
      data.element[data.name] = data.value;
      return state;

    case THEME_INITIAL_DATA_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case THEME_INITIAL_DATA_SUCCESS:
      if (payload.data) {
        state.data = {
          global: {
            options: payload.data.options,
            modal: {
              lastUpdate: false,
              authError: false,
            },
          },
          header: payload.data.header,
          content: payload.data.themes,
        };

        state.isError = false;
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
      }
      return state;

    case THEME_INITIAL_DATA_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    default:
      return state;
  }
};

export const themeInitialData = (token) => {
  return (dispatch) => {
    dispatch(themeInitialDataRequest());

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    fetch(`/api/theme/getTheme`, {
      method: "GET",
      headers,
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(themeInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(themeInitialDataError(err));
      });
  };
};

export const themeInitialDataRequest = () => ({
  type: THEME_INITIAL_DATA_REQUEST,
});

export const themeInitialDataSuccess = (data) => ({
  type: THEME_INITIAL_DATA_SUCCESS,
  payload: data,
});

export const themeInitialDataError = (error) => ({
  type: THEME_INITIAL_DATA_ERROR,
  payload: error,
});

export const changeThemeAction = (data) => ({
  type: CHANGE_THEME,
  data,
});

export default themReducer;
