import { useContext, useState } from "react";
import SelectItems from "src/components/other/SelectItems";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const AddItemModal = ({
  setStatus,
  project,
  type,
  element,
  prototype,
  specificationsOfChildren,
  specificationsToPrototype,
  creatorActionsDataArray,
  launchCreatorActions,
  clickPastElement,
}) => {
  const [selectState, setSelectState] = useState([]);
  const { token } = useContext(AuthContext);

  const closeModal = () => {
    setStatus(false);
  };

  const setMainState = (items) => {
    setSelectState(items.map((item) => item.value));
  };

  const pastItems = () => {
    let params = {
      ids: selectState,
    };

    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";

    fetch("/api/items/all", { method: "POST", body: JSON.stringify(params), headers })
      .then((res) => res.json())
      .then((result) => {
        if (!result?.data?.length) {
          new Error("Возникла ошибка, попробуйте еще раз.");
        }

        const uniqPrototype = JSON.parse(JSON.stringify(prototype));
        const items = result.data.map((item) => item.data);

        items.forEach((item) => {
          clickPastElement(
            element,
            uniqPrototype,
            specificationsOfChildren,
            specificationsToPrototype,
            item
          );
        });

        launchCreatorActions("past", ...creatorActionsDataArray, uniqPrototype);

        setStatus(false);
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  return (
    <>
      <div className="modal__header">
        <div onClick={closeModal} className="modal__close">
          X
        </div>
        <div className="modal__title">Выберите элементы</div>
      </div>
      <SelectItems project={project} type={type} setMainState={setMainState} />
      <div onClick={pastItems} className="button button--ots_top">
        Вставить
      </div>
    </>
  );
};

export default AddItemModal;
