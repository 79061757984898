import React from "react";
import AsyncSelect from "react-select/async";
import { useContext } from "react";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const AsyncSelectBlock = (props) => {
  const { token } = useContext(AuthContext);

  //Вносим изменения в текст
  let onChangeSelect = (date) => {
    console.log(date);
    let newText = date.value;
    let name = props.item;
    //Получаем название вложенного объекта в state
    let parent = null;
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.changeText(newText, element, name);
  };

  let filterItems = (data) => {
    let result = data.data.map((item) => {
      return { value: item.sistemName, label: item.name };
    });
    return result;
  };

  let loadOptions = (inputValue, callback) => {
    fetch(`/api/users/all`, {
      method: "GET",
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        console.log(filterItems(result));
        callback(filterItems(result));
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  // //Совмещаем значение из поле с оциями, чтобы верно отобразить текущую опцию
  // let selectValue = props.options.find(item => item.value === props.value)

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <AsyncSelect
        className="element__select"
        cacheOptions
        loadOptions={loadOptions}
        defaultOptions
        onChange={onChangeSelect}
        placeholder="Выбор"
        isMulti={props.multi}
        closeMenuOnSelect={props.closeMenuOnSelect}
        isDisabled={props.readOnly}
        // value={selectValue}
      />
    </div>
  );
};

export default AsyncSelectBlock;
