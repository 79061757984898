import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { replaceBbCodes } from "src/functions/replaceBbCodes";

const TextareaBlock = (props) => {
  let сharactersСurrent = replaceBbCodes(props.value?.replace(/ *\n*\r*\t*/g, "")).length;

  if (typeof props.value === "undefined" || props.value === null) {
    console.log(`not value in`);
    console.log(props.element);
  }

  //Вносим изменения в текст
  let onChangeText = (e) => {
    let newText = e.target.value;
    let name = e.target.name;
    //Получаем название вложенного объекта в state
    let parent = e.target.getAttribute("parent");
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.changeText(newText, element, name);
  };

  return (
    <div className="element__block">
      <div className="element__blockName">
        <div className="element__blockNameLeft">{props.name}</div>
        {props.сharacters && (
          <div className="element__blockNameRight">
            <div className="element__countChart">
              Cимволов: {сharactersСurrent + "/" + props.сharacters}
            </div>
          </div>
        )}
      </div>
      <div key={props.value || props.item}>
        <TextareaAutosize
          onBlur={onChangeText}
          name={props.item}
          defaultValue={props.value}
          className="element__textarea"
          readOnly={props.readOnly}
        />
      </div>
    </div>
  );
};

export default TextareaBlock;
