//Получаем максимальное значение в массиве
export function getMaxValue(array) {
  let max = array[0]; // берем первый элемент массива
  for (var i = 0; i < array.length; i++) {
    // переберем весь массив
    // если элемент больше, чем в переменной, то присваиваем его значение переменной
    if (max < array[i]) max = array[i];
  }
  // возвращаем максимальное значение
  return max;
}
