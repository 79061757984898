export const authStatus = (store) => (next) => (action) => {
  // //Чтобы не повторялось несколько раз убираем из асинхронных действий
  // if (
  //     String(action.type) === 'ARTICLE_INITIAL_DATA_REQUEST'
  // ) {

  //     let cheker = async () => {

  //         let token

  //         if (JSON.parse(localStorage.getItem('userData'))) {
  //             token = JSON.parse(localStorage.getItem('userData')).token

  //             if (!token) {
  //                 token = 0
  //             }
  //         }

  //         fetch(`/api/auth/status`, { method: 'POST', body: null, headers: { Authorization: `Bearer ${token}` } })
  //             .then(res => {
  //                 if (res.status === 401) {
  //                     localStorage.removeItem('userData')
  //                     alert('Возникла проблема с авторизацией, обновите страницу')
  //                 } else {
  //                     console.log('Проверка пользователя успешна')
  //                 }
  //             })
  //             .catch(err => {
  //                 alert('Возникла проблема с проверкой авторизации, обновите страницу')
  //             });
  //     }
  //     cheker()
  // }

  return next(action);
};
