import React from "react";
import GlobalBlock from "src/components/Content/items/GlobalBlock";
import { EditorJsWrapper } from "src/components/Content/items/EditorJsWrapper";
import ResultBlock from "src/components/Content/items/resultBlock/ResultBlock";
import checkExactDependencies from "src/functions/checkExactDependencies";
import objectPath from "object-path";

const ContentBlocks = ({ theme, isAdmin, actions, state }) => {
  actions = actions();
  const articleStatus = state.status;
  const articleState = state;
  const site = state.project;

  const Items = [];

  Object.keys(theme).forEach((blockName, index) => {
    const blockDependenciesStatus = checkExactDependencies(
      theme[blockName].exactDependencies,
      articleState,
      state.data[blockName]
    );
    if (!blockDependenciesStatus) {
      return;
    }

    if (state.articleType === "items") {
      if (!["main", ...state.data?.main?.allowBlocks?.split(",")].includes(blockName)) {
        return;
      }
    }

    if (blockName === "editor") {
      Items.push(
        <EditorJsWrapper
          key={index}
          updateEditor={actions.updateEditor}
          state={state.data.editor}
          theme={theme.editor}
          classItem={theme.editor.class}
          name={state.data.main.name}
          site={site}
          editorClass={"editorPlace"}
        />
      );
    } else if (blockName === "editorEnding") {
      Items.push(
        <EditorJsWrapper
          key={index}
          updateEditor={(data) => {
            return actions.changeText(data, state.data, "editorEnding");
          }}
          state={state.data.editorEnding}
          theme={theme.editorEnding}
          classItem={theme.editorEnding.class}
          name={""}
          site={site}
          editorClass={"editorPlace"}
        />
      );
    } else if (blockName !== "result") {
      Items.push(
        <GlobalBlock
          key={index}
          actions={actions}
          state={state.data[blockName]}
          theme={theme[blockName]}
          themeItems={theme[blockName].items}
          themeItemsHidden={theme[blockName].itemsHidden}
          articleState={articleState}
          articleStatus={articleStatus}
          isAdmin={isAdmin}
          site={site}
        />
      );
    }
  });

  if (state.articleType === "items") {
    state.data.items.forEach((item, indexItem) => {
      const pathItemTheme = theme?.main?.articleItems?.templates?.find(
        (template) => template.type === item.templateType
      )?.prototypePath;
      const itemTheme = JSON.parse(JSON.stringify(objectPath.get(theme, pathItemTheme)));

      itemTheme.class = "editorBlock";
      itemTheme.name = indexItem + 1;

      Items.push(
        <GlobalBlock
          key={`items-${indexItem}`}
          actions={actions}
          state={item.data}
          theme={itemTheme}
          themeItems={itemTheme.items}
          themeItemsHidden={itemTheme.itemsHidden}
          articleState={articleState}
          articleStatus={articleStatus}
          isAdmin={isAdmin}
          site={site}
        />
      );
    });
  }

  Items.push(
    <ResultBlock
      key={"end"}
      changeText={actions.changeText}
      updateArticle={actions.updateArticle}
      completeAritcle={actions.completeAritcle}
      reloadArticle={actions.reloadArticle}
      abandonTheArticle={actions.abandonTheArticle}
      state={state.data}
      articleState={articleState}
      articleStatus={articleStatus}
      isAdmin={isAdmin}
    />
  );

  return Items;
};

export default ContentBlocks;
