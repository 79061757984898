import { nanoid } from "nanoid";
import { getAllCharts } from "src/functions/unique/getAllCharts";
import { swapArray } from "src/functions/swapArray";
import { newId } from "src/functions/newId";
import { IsJsonString } from "src/functions/IsJsonString";
import toaster from "src/hooks/toaster.hook";
import getObjectByStringPath from "src/functions/getObjectByStringPath";
import { replaceArrValues } from "src/functions/replaceArrValues";

const CLICK_HIDDEN_BUTTON = "CLICK_HIDDEN_BUTTON";
const CHANGE_TEXT = "CHANGE_TEXT";
const HIDDEN_ELEMENT = "HIDDEN_ELEMENT";
const HIDDEN_ELEMENT_ITEM = "HIDDEN_ELEMENT_ITEM";
const HIDDEN_ELEMENT_ITEM_ALL = "HIDDEN_ELEMENT_ITEM_ALL";
const TOP = "TOP";
const DOWN = "DOWN";
const COPY = "COPY";
const DEL = "DEL";
const PAST_ELEMENT = "PAST_ELEMENT";
const TOGGLE_MODAL = "TOGGLE_MODAL";
const ARTICLE_INITIAL_DATA_REQUEST = "ARTICLE_INITIAL_DATA_REQUEST";
const ARTICLE_INITIAL_DATA_SUCCESS = "ARTICLE_INITIAL_DATA_SUCCESS";
const ARTICLE_INITIAL_DATA_ERROR = "ARTICLE_INITIAL_DATA_ERROR";
const ITEM_INITIAL_DATA_REQUEST = "ITEM_INITIAL_DATA_REQUEST";
const ITEM_INITIAL_DATA_SUCCESS = "ITEM_INITIAL_DATA_SUCCESS";
const ITEM_INITIAL_DATA_ERROR = "ITEM_INITIAL_DATA_ERROR";
const UPDATE_EDITOR = "UPDATE_EDITOR";
const ADD_ITEM_NEW = "ADD_ITEM_NEW";
const SAVE_LAST_UPDATE = "SAVE_LAST_UPDATE";
const CHANGE_PLAN_CHARTS = "CHANGE_PLAN_CHARTS";
const CHANGE_SPECIFICATIONS = "CHANGE_SPECIFICATIONS";

let initialState = {
  data: {},
  isLoading: true,
  isError: false,
  error: null,
  errorCode: null,
  version: 0,
};

const articlesReducer = (state = initialState, action) => {
  let thisObject = "";
  const payload = action.payload;

  switch (action.type) {
    case ARTICLE_INITIAL_DATA_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case ARTICLE_INITIAL_DATA_SUCCESS:
      if (payload.data) {
        //Если в локальном хранилище версию новее, получаем данные оттуда
        const articleId = payload._id;
        const bdVersion = payload.version;
        const lastUpdate_bd = payload.lastUpdate;

        if (localStorage.getItem(`savedArticles`)) {
          const userArticle = JSON.parse(localStorage.getItem(`savedArticles`))?.find(
            (item) => item._id === articleId
          );

          if (userArticle) {
            const userVersion = userArticle.version;
            const lastUpdate_user = userArticle.lastUpdate;

            if (lastUpdate_bd > lastUpdate_user) {
              state = payload;
            } else {
              if (bdVersion >= userVersion) {
                state = payload;
              } else {
                state = userArticle;
              }
            }
          } else {
            state = payload;
          }
        } else {
          state = payload;
        }
        state.isError = false;
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
      }
      return state;

    case ARTICLE_INITIAL_DATA_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    case ITEM_INITIAL_DATA_REQUEST:
      state.isLoading = true;
      state.isError = false;
      state.error = null;
      return state;

    case ITEM_INITIAL_DATA_SUCCESS:
      if (payload.data) {
        state = payload;
        state.isError = false;
        state.isLoading = false;
      } else {
        state.isError = true;
        state.isLoading = false;
        state.errorCode = payload.error;
        state.error = payload;
      }
      return state;

    case ITEM_INITIAL_DATA_ERROR:
      state.isLoading = false;
      state.isError = true;
      state.error = payload;
      return state;

    case CHANGE_PLAN_CHARTS:
      state = changePlanChartsFn(state, action.data);

      state.version = new Date().getTime();
      return state;

    case SAVE_LAST_UPDATE:
      state.lastUpdate = action.data;
      return state;

    case UPDATE_EDITOR:
      let data = action.data;
      state.data.editor = data;
      updateCharts(state);

      state.version = new Date().getTime();
      return state;

    case TOGGLE_MODAL:
      state.data.code.config.modal[action.modalType] = action.modalValue;

      state.version = new Date().getTime();
      return state;

    case TOP:
      let topElementIndex = action.parentElement.items.indexOf(action.element);
      swapArray(action.parentElement.items, topElementIndex, topElementIndex - 1);

      state.version = new Date().getTime();
      return state;

    case DOWN:
      let downElementIndex = action.parentElement.items.indexOf(action.element);
      swapArray(action.parentElement.items, downElementIndex, downElementIndex + 1);

      state.version = new Date().getTime();
      return state;

    case COPY:
      let copyElement = action.element;
      let copyElementJson = JSON.stringify(copyElement);
      let copyElementIndex = action.parentElement.items.indexOf(copyElement);
      //Удаляем элемент
      if (action.action !== "copy") {
        action.parentElement.items.splice(copyElementIndex, 1);
      }

      state = updateCharts(state);
      localStorage.setItem("copyElement", copyElementJson);

      state.version = new Date().getTime();
      return state;

    case DEL:
      let delElement = action.element;
      let delElementJson = JSON.stringify(delElement);
      localStorage.setItem("delElement", delElementJson);
      let delElementIndex = action.parentElement.items.indexOf(delElement);
      action.parentElement.items.splice(delElementIndex, 1);

      state = updateCharts(state);

      state.version = new Date().getTime();

      toaster.notify(
        "Если элемент удален по ошибке, его можно скопировать с помощью кнопки внизу редактора. После чего, вставьте элемент в нужное место.",
        {
          duration: 5000,
        }
      );

      return state;

    //Скрывает блок элемента
    case HIDDEN_ELEMENT:
      thisObject = action.element;

      if (thisObject.config.hidden === true) {
        thisObject.config.hidden = false;
      } else {
        thisObject.config.hidden = true;
      }

      state.version = new Date().getTime();
      return state;

    //Показ и скрытие блока с доп полями
    case CLICK_HIDDEN_BUTTON:
      thisObject = action.element;

      if (thisObject.config.hiddenItems === true) {
        thisObject.config.hiddenItems = false;
      } else {
        thisObject.config.hiddenItems = true;
      }

      state.version = new Date().getTime();
      return state;

    case HIDDEN_ELEMENT_ITEM:
      thisObject = action.element;

      if (thisObject.config.hidden === true) {
        thisObject.config.hidden = false;
        // Если скрыты все элементы, меняем свойство у родителя
        // Проверяем скрыты ли все элементы у рядом стоящих элементов
        if (!action.parentElement.items.find((item) => item.config.hidden === true)) {
          action.parentElement.config.hiddenInnerBlocks = false;
        }
      } else {
        thisObject.config.hidden = true;
        if (!action.parentElement.items.find((item) => item.config.hidden === false)) {
          action.parentElement.config.hiddenInnerBlocks = true;
        }
      }

      state.version = new Date().getTime();
      return state;

    case HIDDEN_ELEMENT_ITEM_ALL:
      thisObject = action.element;

      if (thisObject.config.hiddenInnerBlocks === true) {
        thisObject.config.hiddenInnerBlocks = false;
        thisObject.items.forEach((item) => (item.config.hidden = false));
        //Если сам блок скрыт через hiden, при открытии всех, открываем и его
        if (thisObject.config.hidden === true) {
          thisObject.config.hidden = false;
        }
      } else {
        thisObject.config.hiddenInnerBlocks = true;
        thisObject.items.forEach((item) => (item.config.hidden = true));
      }

      state.version = new Date().getTime();
      return state;

    case CHANGE_TEXT:
      let name = action.name;
      if (action.newText === "true") {
        action.newText = true;
      } else if (action.newText === "false") {
        action.newText = false;
      }
      if (action.status) {
        switch (action.status) {
          case "array":
            action.newText = action.newText.split(",");
            break;
          default:
            break;
        }
      }
      if (typeof action.newText === "string") {
        action.element[name] = action.newText.replace(/<(?:.|\n)*?>/gm, "").trim();
      } else {
        action.element[name] = action.newText;
      }
      state = updateCharts(state);

      state.version = new Date().getTime();
      return state;

    case ADD_ITEM_NEW:
      let prototypeItem = action.prototype;
      let element = action.element;

      prototypeItem.id = newId(element.items);
      prototypeItem.globalId = nanoid();

      //Костылии
      if (state.projectId === 1) {
        prototypeItem.dateCode = "01";
      }
      if (prototypeItem.imagesName !== undefined && prototypeItem.imagesName.length < 5) {
        prototypeItem.imagesName = prototypeItem.imagesName + nanoid();
      }

      element.items.push(prototypeItem);

      state.version = new Date().getTime();
      return state;

    case PAST_ELEMENT:
      const addItemToItems = (pastElement, state, action) => {
        pastElement.id = newId(action.element.items);

        if (pastElement.config.type === action.element.config.typeItems) {
          if (action.specificationsOfChildren) {
            addSpecificationsToPastElement(
              state.data,
              pastElement,
              action.specificationsOfChildren
            );
          }
          if (action.specificationsToPrototype) {
            addSpecificationsToPastElement(
              state.data,
              pastElement,
              action.specificationsToPrototype
            );
          }

          action.element.items.push(pastElement);

          state = updateCharts(state);

          state.version = new Date().getTime();
        } else {
          toaster.notify("Выбрано неправильное место для вставки", {
            duration: 2000,
          });
        }
        return state;
      };

      const insertedFromServerItem = action.item;
      if (insertedFromServerItem) {
        if (insertedFromServerItem?.config) {
          insertedFromServerItem.config.imported = true;
        }
        return addItemToItems(insertedFromServerItem, state, action);
      }

      let insertedFromBrowserItem = localStorage.getItem("copyElement");
      if (insertedFromBrowserItem && IsJsonString(insertedFromBrowserItem) !== false) {
        insertedFromBrowserItem = JSON.parse(insertedFromBrowserItem);
        insertedFromBrowserItem.globalId = nanoid();
        return addItemToItems(insertedFromBrowserItem, state, action);
      }

      toaster.notify("Неверный объект для вставки", {
        duration: 2000,
      });

      return state;

    case CHANGE_SPECIFICATIONS:
      updateSpecificationItems({
        ...action.data,
        state: state.data,
        eteration: 0,
        action: runSpecificationsAction,
      });

      return state;

    default:
      return state;
  }
};

function changePlanChartsFn(state, data) {
  let count = 0;
  data.config.forEach((item) => {
    count += Number(data.element.config.сharactersItems[item]);
  });
  if (data.value === "true") {
    state.data.main.allCountCharts = state.data.main.allCountCharts - count;
  } else {
    state.data.main.allCountCharts = state.data.main.allCountCharts + count;
  }
  return state;
}

function updateCharts(state) {
  if (!state.data.main) {
    return state;
  }

  const allCharts = getAllCharts(state, "count");
  state.data.main.allCharts = allCharts;
  state.data.main.allCurrentCountCharts = state.data.main.allCharts.replace(
    / *\n*\r*\t*/g,
    ""
  ).length;
  return state;
}

function addSpecificationsToPastElement(state, pastElement, specificationsPaths) {
  specificationsPaths.forEach((specificationPath) => {
    function createElementSpecifications(items, data) {
      const origSpecificationItems = JSON.parse(
        JSON.stringify(getObjectByStringPath(data.globalState, data.pathToSpecs))
      );

      origSpecificationItems.forEach((item) => {
        const elelementItem = items.find((element) => element.name === item.name);
        if (elelementItem) {
          item.content = elelementItem.content;
        }
        item.globalId = nanoid();
      });

      replaceArrValues(items, origSpecificationItems);
    }

    updateSpecificationItems({
      state: pastElement,
      globalState: state,
      pathToRelatedItems: specificationPath.pathToPrototype,
      pathToSpecs: specificationPath.pathToSpecs,
      eteration: 0,
      action: createElementSpecifications,
    });
  });
}

function updateSpecificationItems(data) {
  if (!Array.isArray(data.pathToRelatedItems)) {
    return data.action(getObjectByStringPath(data.state, data.pathToRelatedItems), data);
  }

  const items = getObjectByStringPath(
    data.state,
    data.pathToRelatedItems[data.eteration]
  );

  if (data.pathToRelatedItems[data.eteration + 1]) {
    return items.forEach((item) => {
      updateSpecificationItems({
        ...data,
        eteration: data.eteration + 1,
        state: item,
      });
    });
  }

  data.action(items, data);
}

function runSpecificationsAction(items, data) {
  const { type, arrayId, prototype, text } = data;

  switch (type) {
    case "top":
      return liftUpItems(items, arrayId);
    case "down":
      return putDownItems(items, arrayId);
    case "del":
      return removeItems(items, arrayId);
    case "add":
      return addItems(items, prototype);
    case "past":
      return pastItems(items, prototype);
    case "update":
      return updateItems(items, text, arrayId);
    default:
      break;
  }

  function liftUpItems(items, arrayId) {
    items = swapArray(items, arrayId, arrayId - 1);
  }

  function putDownItems(items, arrayId) {
    items = swapArray(items, arrayId, arrayId + 1);
  }

  function removeItems(items, arrayId) {
    items.splice(arrayId, 1);
  }

  function addItems(items, prototype) {
    prototype = JSON.parse(JSON.stringify(prototype));
    prototype.id = newId(items);
    prototype.globalId = nanoid();
    items.push(prototype);
  }

  function pastItems(items, prototype) {
    let pastElement = localStorage.getItem("copyElement");
    if (IsJsonString(pastElement) !== false && pastElement !== null) {
      pastElement = JSON.parse(pastElement);

      if (pastElement.config.type === prototype.config.type) {
        pastElement.id = newId(items);
        pastElement.globalId = nanoid();
        items.push(pastElement);
      }
    }
  }

  function updateItems(items, text, arrayId) {
    items.forEach((item, index) => {
      if (index === arrayId) {
        item.name = text;
      }
    });
  }
}

export const articleInitialData = (articleId, token) => {
  return (dispatch) => {
    dispatch(articleInitialDataRequest());

    fetch(`/api/article/${articleId}`, {
      method: "GET",
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(articleInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(articleInitialDataError(err));
      });
  };
};

export const articleInitialDataRequest = () => ({
  type: ARTICLE_INITIAL_DATA_REQUEST,
});

export const articleInitialDataSuccess = (data) => ({
  type: ARTICLE_INITIAL_DATA_SUCCESS,
  payload: data,
});

export const articleInitialDataError = (error) => ({
  type: ARTICLE_INITIAL_DATA_ERROR,
  payload: error,
});

export const itemInitialData = (itemId, token) => {
  return (dispatch) => {
    dispatch(itemInitialDataRequest());

    fetch(`/api/items/getOne/${itemId}`, {
      method: "GET",
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        dispatch(itemInitialDataSuccess(result));
      })
      .catch((err) => {
        dispatch(itemInitialDataError(err));
      });
  };
};

export const itemInitialDataRequest = () => ({
  type: ITEM_INITIAL_DATA_REQUEST,
});

export const itemInitialDataSuccess = (data) => ({
  type: ITEM_INITIAL_DATA_SUCCESS,
  payload: data,
});

export const itemInitialDataError = (error) => ({
  type: ITEM_INITIAL_DATA_ERROR,
  payload: error,
});

export const clickAddItemNewAction = (prototype, element) => ({
  type: ADD_ITEM_NEW,
  prototype,
  element,
});

export const changeTextAction = (text, element, name, status) => ({
  type: CHANGE_TEXT,
  newText: text,
  element: element,
  name: name,
  status,
});

export const clickHiddenButtonAction = (element) => ({
  type: CLICK_HIDDEN_BUTTON,
  element: element,
});

export const clickHiddenElementAction = (element) => ({
  type: HIDDEN_ELEMENT,
  element: element,
});

export const clickHiddenElementItemAction = (element, parentElement) => ({
  type: HIDDEN_ELEMENT_ITEM,
  element: element,
  parentElement: parentElement,
});

export const clickHiddenElementItemAllAction = (element) => ({
  type: HIDDEN_ELEMENT_ITEM_ALL,
  element: element,
});

export const clickTopAction = (element, parentElement) => ({
  type: TOP,
  element: element,
  parentElement: parentElement,
});

export const clickDownAction = (element, parentElement) => ({
  type: DOWN,
  element: element,
  parentElement: parentElement,
});

export const clickCopyAction = (element, parentElement, action) => ({
  type: COPY,
  element: element,
  parentElement: parentElement,
  action,
});

export const clickDelAction = (element, parentElement) => ({
  type: DEL,
  element: element,
  parentElement: parentElement,
});

export const clickPastElementAction = (
  element,
  prototype,
  specificationsOfChildren,
  specificationsToPrototype,
  item
) => ({
  type: PAST_ELEMENT,
  element: element,
  prototype,
  specificationsOfChildren,
  specificationsToPrototype,
  item,
});

export const сlickToggleModalAction = (modalType, modalValue) => ({
  type: TOGGLE_MODAL,
  modalType: modalType,
  modalValue: modalValue,
});

export const updateEditorAction = (data) => ({
  type: UPDATE_EDITOR,
  data: data,
});

export const saveLastUpdateAction = (data) => ({
  type: SAVE_LAST_UPDATE,
  data: data,
});

export const changePlanCharts = (data) => ({
  type: CHANGE_PLAN_CHARTS,
  data: data,
});

export const changeSpecifications = (data) => ({
  type: CHANGE_SPECIFICATIONS,
  data: data,
});

export default articlesReducer;
