import { nanoid } from "nanoid";

function launchUniqueActions(
  actions,
  element,
  articleState,
  parentCreatorBlocks,
  config
) {
  if (!config || config.length < 1) {
    return;
  }

  config.forEach((item) => {
    if (!item?.name) {
      return;
    }

    switch (item.name) {
      case "updateElementRatingInProject1Import":
        updateProductRatingInProject1(element, parentCreatorBlocks, actions, "import");
        break;
      case "updateElementRatingInProject1List":
        updateProductRatingInProject1(element, parentCreatorBlocks, actions, "list");
        break;
      case "addImportSpecificationsInProject1Product":
        addImportSpecificationsInProject1Product(
          element,
          item.items,
          actions,
          articleState,
          item.prototypes
        );
        break;
      case "addImageInProject1Product":
        addImageInProject1Product(element, item.item, actions, item.prototypes);
        break;

      default:
        break;
    }
  });
}

function updateProductRatingInProject1(thisElement, parentCreatorBlocks, actions, type) {
  let element;
  if (type === "import") {
    element = thisElement;
  } else {
    element = parentCreatorBlocks[parentCreatorBlocks.length - 1];
  }

  const {
    reviewsYa,
    reviewsWildberries,
    reviewsOzon,
    reviewsIrecommend,
    reviewsAliexpress,
  } = element;

  let reviewsCount = String(
    Number(reviewsYa ?? 0) +
      Number(reviewsWildberries ?? 0) +
      Number(reviewsOzon ?? 0) +
      Number(reviewsIrecommend ?? 0) +
      Number(reviewsAliexpress ?? 0)
  );

  if (element?.reviews?.items && element.reviews.items.length > 0) {
    element.reviews.items.forEach((item) => {
      if (!item.count) {
        return;
      }
      reviewsCount = String(Number(reviewsCount) + Number(item.count));
    });
  }

  const { ratingYa, ratingWildberries, ratingOzon, ratingIrecommend, ratingAliexpress } =
    element;

  let oldAllRating = 0;
  let oldReviews = 0;
  if (element?.reviews?.items && element.reviews.items.length > 0) {
    oldReviews = element.reviews.items.reduce((previousValue, item) => {
      if (!item.rating || !item.count) {
        return previousValue;
      }
      const rating = Number(item.rating);
      const reviews = Number(item.count);
      if (rating > 5 || rating < 0) {
        return previousValue;
      }

      return previousValue + reviews;
    }, 0);

    oldAllRating = element.reviews.items.reduce((previousValue, item) => {
      if (!item.rating || !item.count) {
        return previousValue;
      }

      const rating = Number(item.rating);
      const reviews = Number(item.count);

      if (rating > 5 || rating < 0) {
        return previousValue;
      }

      return previousValue + rating * reviews;
    }, 0);
  }

  const countActiveRatingItems =
    Number(reviewsYa) +
    Number(reviewsWildberries) +
    Number(reviewsOzon) +
    Number(reviewsIrecommend) +
    Number(reviewsAliexpress) +
    oldReviews;

  let ratingCount = String(
    (
      (Number(ratingYa) * Number(reviewsYa) +
        Number(ratingWildberries) * Number(reviewsWildberries) +
        Number(ratingOzon) * Number(reviewsOzon) +
        Number(ratingIrecommend) * Number(reviewsIrecommend) +
        Number(ratingAliexpress) * Number(reviewsAliexpress) +
        oldAllRating) /
      countActiveRatingItems
    ).toFixed(2)
  );

  if (isNaN(ratingCount)) {
    ratingCount = "0";
  }

  actions.changeText(reviewsCount, element, "reviewsCount");
  actions.changeText(ratingCount, element, "rating");
}

function addImportSpecificationsInProject1Product(
  element,
  items,
  actions,
  articleState,
  prototypes
) {
  const specificationsImportItems = [];

  const specificationsCategoryImport = prototypes.specificationsCategoryImport;
  const specificationsItemImport = prototypes.specificationsItemImport;

  items.forEach((categoryItem, index) => {
    if (categoryItem?.subspecs && categoryItem.subspecs.length < 1) {
      return;
    }

    let prototype = JSON.stringify(specificationsCategoryImport);
    prototype = JSON.parse(prototype);

    const specificationsImportCategoryItem = {
      ...prototype,
      id: index + 1,
      dateCode: "01",
      globalId: nanoid(),
      name: categoryItem.name,
      config: { ...prototype.config, hidden: true },
    };

    categoryItem.subspecs.forEach((item, index) => {
      if (!item?.name || !item.value) {
        return;
      }

      let prototype = JSON.stringify(specificationsItemImport);
      prototype = JSON.parse(prototype);

      const specificationItem = {
        ...prototype,
        id: index + 1,
        dateCode: "01",
        globalId: nanoid(),
        name: item.name,
        content: String(item.value),
        config: { ...prototype.config, hidden: true },
      };

      specificationsImportCategoryItem.items.push(specificationItem);
    });

    specificationsImportItems.push(specificationsImportCategoryItem);
  });

  actions.changeText(specificationsImportItems, element.specificationsImport, "items");
}

function addImageInProject1Product(element, image, actions, prototypes) {
  const imagesItemPrototype = prototypes.imagesItemPrototype;

  let prototype = JSON.stringify(imagesItemPrototype);
  prototype = JSON.parse(prototype);

  const imageItem = {
    ...prototype,
    imagesName: image.name,
    imagesLink: image.url,
    type: image.type,
    width: image.width,
    height: image.height,
    status: true,
  };

  actions.clickAddItemNew(imageItem, element.images);
}

export default launchUniqueActions;
