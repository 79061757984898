import React from "react";

const TextBlock = (props) => {
  let text = props.element[props.item];

  const name = props.name || "";

  if (!text) {
    return "";
  }

  return (
    <p>
      {name} {text}
    </p>
  );
};

export default TextBlock;
