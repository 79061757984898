import Select from "react-select";
import React, { useState, useContext, useCallback, useEffect } from "react";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const LoadSelectBlock = (props) => {
  //Подгружаем опции
  const [options, setOptions] = useState([]);
  const { token } = useContext(AuthContext);

  const fetchArticles = useCallback(async () => {
    fetch(props.loadPatch, {
      method: "GET",
      body: null,
      headers: { Authorization: `Bearer ${token}` },
    })
      .then((res) => res.json())
      .then((result) => {
        setOptions(result.data);
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  }, [token, props.loadPatch]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  //Вносим изменения в текст
  let onChangeSelect = (date) => {
    let newText = date.value;
    let name = props.item;
    //Получаем название вложенного объекта в state
    let parent = null;
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.element[parent];
    } else {
      element = props.element;
    }
    props.changeText(newText, element, name);
  };

  //Выводим лоадер, пока полчаем информацию
  if (!options || options.length < 0) {
    return "Загрузка";
  }

  //Совмещаем значение из поле с оциями, чтобы верно отобразить текущую опцию
  let selectValue = options.find((item) => item.value === props.value);

  return (
    <div className="element__block">
      <span className="element__blockName">{props.name}</span>
      <Select
        className="element__select"
        value={selectValue}
        onChange={onChangeSelect}
        options={options}
        isMulti={props.multi}
        closeMenuOnSelect={props.closeMenuOnSelect}
        placeholder="Выбор"
        isDisabled={props.readOnly}
      />
    </div>
  );
};

export default LoadSelectBlock;
