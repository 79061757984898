import { createStore, combineReducers, applyMiddleware } from "redux";
import articlesReducer from "src/redux/articlesReducer";
import { thunk } from "redux-thunk";
import logger from "redux-logger";
import { authStatus } from "src/redux/middleware/auth.middleware";
import dashboardReducer from "src/redux/dashboardReducer";
import allReducer from "src/redux/allReducer";
import createReducer from "src/redux/createReducer";
import usersReducer from "src/redux/usersReducer";
import themReducer from "src/redux/themReducer";
import paysReducer from "src/redux/paysReducer.js";
import itemsReducer from "src/redux/itemsReducer";
import availableArticlesReducer from "src/redux/availableArticlesReducer";

//Смешиваем все редюсеры
let reducers = combineReducers({
  articlesReducer: articlesReducer,
  allReducer: allReducer,
  dashboardReducer: dashboardReducer,
  createReducer: createReducer,
  usersReducer: usersReducer,
  themReducer: themReducer,
  paysReducer: paysReducer,
  itemsReducer: itemsReducer,
  availableArticlesReducer: availableArticlesReducer,
});

let store = createStore(reducers, applyMiddleware(thunk, authStatus, logger));

export default store;
