import React, { useContext, useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { Loader } from "src/components/Loader";
import { AuthContext } from "src/context/AuthContext";
import { AllArticles } from "src/components/AllArticles";
import { useSearchParams } from "react-router-dom";
import { allInitialData, allInitialDataRequest } from "src/redux/allReducer";
import getSearchParamsObject from "src/functions/getSearchParamsObject";

const AllPage = (props) => {
  const [searchParams] = useSearchParams();
  const dispatch = useDispatch();
  const { token, status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  useEffect(() => {
    const searchParamsObject = getSearchParamsObject(searchParams.toString());
    dispatch(allInitialData(token, searchParamsObject));
    //Обнуляем данные при выходе
    return () => {
      dispatch(allInitialDataRequest());
    };
    //eslint-disable-next-line
  }, [token, dispatch, searchParams]);

  if (props.state.isLoading === true && !props.state.data) {
    return <Loader type="full" />;
  }

  if (props.state.isError === true) {
    if (props.state.errorCode === 401) {
      localStorage.removeItem("userData");
      return <p className="textCenter">Ошибка авторизации, перезагрузите эту страницу</p>;
    } else {
      return (
        <p className="textCenter">
          Ошибка загрузки данных. Если ошибка повторяется нажмите кнопку "Выйти" в самом
          верху и зайдите заново в редактор.
        </p>
      );
    }
  }

  return (
    <div className="content">
      <AllArticles
        isAdmin={isAdmin}
        articles={props.state.data}
        themeGlobal={props.themeGlobal}
        isLoading={props.state.isLoading}
        pagination={props.state.pagination}
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.allReducer,
    themeGlobal: state.themReducer.data.global,
  };
};

export default connect(mapStateToProps)(AllPage);
