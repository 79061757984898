import React, { useContext } from "react";
import copy from "copy-to-clipboard";
import { checkText } from "yandex-speller-https";
import TextareaAutosize from "react-textarea-autosize";
import toaster from "src/hooks/toaster.hook";
import { useParams } from "react-router-dom";
import ResultBlockProject1 from "src/components/Content/items/resultBlock/items/ResultBlockProject1";
import { removeDuplicates } from "src/functions/removeDuplicates";
import { AuthContext } from "src/context/AuthContext";
import ReadElement from "src/components/Content/items/resultBlock/ReadElement";
import { getAllCharts } from "src/functions/unique/getAllCharts";

const ResultBlock = (props) => {
  //Обновление статьи
  const { token } = useContext(AuthContext);
  const articleId = useParams().id;

  if (!props.state.result) {
    return "";
  }

  //Копирование удаленного элемента
  let onCopyDelElement = () => {
    let delElement = localStorage.getItem("delElement");
    localStorage.setItem("copyElement", delElement);
    localStorage.removeItem("delElement");
    toaster.notify("Скопировано", {
      duration: 3000,
    });
  };

  //Вносим изменения в текст
  let onChangeText = (e) => {
    let newText = e.target.value;
    let name = e.target.name;
    //Получаем название вложенного объекта в state
    let parent = e.target.getAttribute("parent");
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.state.result[parent];
    } else {
      element = props.state.result;
    }
    props.changeText(newText, element, name);
  };

  let publishButtonStatus = false;
  if (props.articleState.publish && props.articleState.publish.active === true) {
    if (props.articleState.publish.status === true) {
      publishButtonStatus = true;
    }
  }

  //Копирование удаленного элемента
  let onCopyText = () => {
    let text = props.articleState.data.main.allCharts.replace(/\[.*?\]/g, "");
    copy(text, {
      debug: true,
      message: "Нажмите #{key} чтобы скопировать",
    });
    toaster.notify("Текст скопирован", {
      duration: 2000,
    });
  };

  //Копирование кода статьи
  let onCopyCode = () => {
    //Преобразуем state в json
    let copyElement = JSON.stringify(props.state);
    //Перемещаем state в буфер обмена
    copy(copyElement, {
      debug: true,
      message: "Нажмите #{key} чтобы скопировать",
    });
    toaster.notify("Код скопирован", {
      duration: 2000,
    });
  };

  let onUpdateCE = () => {
    props.updateArticle(props.articleState, token);
  };

  let completeAritcle = () => {
    let newstatus = "moderation";
    props.completeAritcle(props.articleState, token, newstatus);
  };

  let completeModeration = () => {
    let newstatus = "completed";
    props.completeAritcle(props.articleState, token, newstatus);
  };

  let sendForCorrection = () => {
    let newstatus = "corrections";
    props.completeAritcle(props.articleState, token, newstatus);
  };

  let UpdateArticleCallBack = () => {
    toaster.notify("Данные обновлены", {
      duration: 2000,
    });
  };
  let onUpdateArticle = () => {
    props.updateArticle(props.articleState, token, UpdateArticleCallBack);
  };

  const onAbandonTheArticle = () => {
    props.abandonTheArticle(props.articleState._id, token);
  };

  let authorRender = false;
  if (
    props.articleState.status === "corrections" ||
    props.articleState.status === "editing"
  ) {
    authorRender = true;
  }
  let moderatorRender = false;
  if (props.articleState.status === "moderation") {
    moderatorRender = true;
  }
  let authorRender_admin = false;
  if (
    props.articleState.status === "corrections" ||
    props.articleState.status === "editing" ||
    props.isAdmin
  ) {
    authorRender_admin = true;
  }
  let moderatorRender_admin = false;
  if (props.articleState.status === "moderation" || props.isAdmin) {
    moderatorRender_admin = true;
  }

  //Вносим изменения в текст
  let onChangeModeration = (e) => {
    let newText = Number(e.target.value);
    let name = e.target.name;
    //Получаем название вложенного объекта в state
    let parent = e.target.getAttribute("parent");
    let element = "";
    //Если state для input вложен, то отправляем его
    if (parent !== null) {
      element = props.articleState[parent];
    } else {
      element = props.articleState;
    }
    props.changeText(newText, element, name);
  };

  //Проверка текста
  let onTextValiadtion = () => {
    const allCharts = getAllCharts(props.articleState, "check");

    let d = " ";
    let t = ".";
    let text =
      props.articleState.data.main.name +
      t +
      d +
      props.articleState.data.main.title +
      t +
      d +
      props.articleState.data.main.numberTitle +
      t +
      d +
      props.articleState.data.main.headerTitle +
      t +
      d +
      allCharts;
    text = text
      .replace("undefined", "")
      .replaceAll(". .", ".")
      .replace(/\[.*?\]/g, "");

    checkText(text, (err, body) => {
      pastCheckText(body, err);
    });
  };

  //Вносим изменения в текст
  let pastCheckText = (text, err) => {
    let newText = text;
    let name = "orfo";
    let element = props.state.result;
    props.changeText(newText, element, name);
    if (err === null) {
      toaster.notify("Проверка завершена", {
        duration: 2000,
      });
    }
  };

  //Удаляем неуникальные ключи
  let orfoItems = removeDuplicates(props.state.result.orfo);

  let publishItem = (status) => {
    props.updateArticle(props.articleState, token, publish);

    function publish() {
      let result = window.prompt("Введите пароль");
      if (!result) {
        return;
      }

      let data = {
        id: props.articleState._id,
        publishStatus: status,
        password: result,
      };

      let body = JSON.stringify(data);
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      fetch(`/api/article/publish`, { method: "POST", body, headers })
        .then((res) => res.json())
        .then((result) => {
          if (result.error !== 1) {
            props.reloadArticle(articleId, token);
          } else {
            toaster.notify(result.message, {
              duration: 3000,
            });
          }
        })
        .catch((err) => {
          toaster.notify(err.message, {
            duration: 3000,
          });
        });
    }
  };

  return (
    <>
      {props.articleState.articleType !== "items" && (
        <div className="element editorBlock">
          <div className="result__intro">
            Внимательно прочитайте текст и проверьте его на наличие ошибок и опечаток.
            Проверьте легко ли читаются предложения? Понятны ли они? Возможно что-то можно
            сказать более ясно и кратко? После прочтения текста и исправления найденных
            ошибок, проверьте текст, нажав на кнопку в конце блока. После чего, также
            исправьте ошибки и опечатки. Не забудьте проверить текст на{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://advego.com/text/seo/"
            >
              частотность
            </a>{" "}
            и{" "}
            <a target="_blank" rel="noopener noreferrer" href="https://text.ru/">
              уникальность
            </a>
            . Чтобы скопировать текст для вставки, нажмите кнопку в конце блока.
          </div>
          {props.articleState.projectId === 1 && (
            <ResultBlockProject1
              state={props.state}
              onUpdateCE={onUpdateCE}
              changeText={props.changeText}
            />
          )}
          <div onClick={onTextValiadtion} className="button button--ots_right">
            Проверить текст
          </div>
          <div onClick={onCopyText} className="button">
            Скопировать текст
          </div>

          {orfoItems.length > 0 && (
            <div className="chekTextBlock">
              <div className="chekTextBlock__title">
                Возможно в этих словах есть ошибки
              </div>
              <ul className="chekTextBlock__ul">
                {orfoItems.map((item, index) => {
                  //Если блок текста содержит слишком много ошибок - пропускаем его
                  if (item.code === 4) {
                    return null;
                  }
                  return (
                    <li key={index}>
                      <strong>{item.word}</strong> ({" "}
                      {item.s.map((item, index) => (
                        <span key={index}>{item}, </span>
                      ))}{" "}
                      )
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </div>
      )}

      {props.isAdmin && props.articleState.articleType !== "items" && (
        <div className="element editorBlock">
          <span className="element__blockName element__blockName--bmOts">Заголовок:</span>
          <ReadElement
            onUpdateCE={onUpdateCE}
            tagName="div"
            name={"name"}
            element={props.state.main.name}
            patch={props.state.main}
            changeText={props.changeText}
          />
          <span className="element__blockName element__blockName--bmOts">Тайтл:</span>
          <ReadElement
            onUpdateCE={onUpdateCE}
            tagName="div"
            name={"headerTitle"}
            element={props.state.main.headerTitle}
            patch={props.state.main}
            changeText={props.changeText}
          />
          <span className="element__blockName element__blockName--bmOts">Описание:</span>
          <ReadElement
            onUpdateCE={onUpdateCE}
            tagName="div"
            name={"description"}
            element={props.state.main.description}
            patch={props.state.main}
            changeText={props.changeText}
          />
        </div>
      )}

      <div className="element editorBlock">
        <div className="element__block element__item">
          {props.isAdmin && (
            <>
              <span className="element__blockName">Комментарий КМ</span>
              <TextareaAutosize
                onBlur={onChangeText}
                parent="comments"
                name="contentMaker"
                defaultValue={props.state.result.comments.contentMaker}
                className="element__textarea"
              />
            </>
          )}
          {authorRender_admin && (
            <>
              <span className="element__blockName">Комментарий автора</span>
              <TextareaAutosize
                onBlur={onChangeText}
                parent="comments"
                name="editor"
                defaultValue={props.state.result.comments.editor}
                className="element__textarea"
              />
            </>
          )}
          {moderatorRender_admin && (
            <>
              <span className="element__blockName">Комментарий модератора</span>
              <TextareaAutosize
                onBlur={onChangeText}
                parent="comments"
                name="moderator"
                defaultValue={props.state.result.comments.moderator}
                className="element__textarea"
              />
            </>
          )}
        </div>

        {moderatorRender_admin && (
          <>
            <div className="element__block element__item element__block--oneLine">
              <div className="element__column">
                <span className="element__blockName">Всего символов к оплате</span>
                <input
                  onBlur={onChangeModeration}
                  name="charactersCount"
                  parent="payCount"
                  defaultValue={props.articleState.payCount.charactersCount}
                  className="element__input"
                />
              </div>
              <div className="element__column">
                <span className="element__blockName">Видео добавленные автором</span>
                <input
                  onBlur={onChangeModeration}
                  name="videoAuthorCount"
                  parent="payCount"
                  defaultValue={props.articleState.payCount.videoAuthorCount}
                  className="element__input"
                />
              </div>
              <div className="element__column">
                <span className="element__blockName">Видео добавленные модератором</span>
                <input
                  onBlur={onChangeModeration}
                  name="videoModeratorCount"
                  parent="payCount"
                  defaultValue={props.articleState.payCount.videoModeratorCount}
                  className="element__input"
                />
              </div>
              <div className="element__column">
                <span className="element__blockName">Элементы обр. автором</span>
                <input
                  onBlur={onChangeModeration}
                  name="fixAuthorCount"
                  parent="payCount"
                  defaultValue={props.articleState.payCount.fixAuthorCount}
                  className="element__input"
                />
              </div>
              <div className="element__column">
                <span className="element__blockName">Элементы обр. модератором</span>
                <input
                  onBlur={onChangeModeration}
                  name="fixModeratorCount"
                  parent="payCount"
                  defaultValue={props.articleState.payCount.fixModeratorCount}
                  className="element__input"
                />
              </div>
            </div>
          </>
        )}

        {props.isAdmin && (
          <div className="element__block element__item element__block--oneLine">
            <div className="element__column">
              <span className="element__blockName">Оплаченое количество символов</span>
              <input
                onBlur={onChangeModeration}
                name="charactersPay"
                parent="payCount"
                defaultValue={props.articleState.payCount.charactersPay}
                className="element__input"
              />
            </div>
            <div className="element__column">
              <span className="element__blockName">Оплаченое количество видео</span>
              <input
                onBlur={onChangeModeration}
                name="videoPay"
                parent="payCount"
                defaultValue={props.articleState.payCount.videoPay}
                className="element__input"
              />
            </div>
          </div>
        )}

        {authorRender && (
          <>
            <div
              onClick={completeAritcle}
              className="button button--ots_bm button--ots_right"
            >
              Завершить и отправить на проверку
            </div>
            <div
              onClick={onAbandonTheArticle}
              className="button button--ots_bm  button--red button--ots_right"
            >
              Отказаться от работы
            </div>
          </>
        )}
        {moderatorRender && (
          <>
            <div
              onClick={completeModeration}
              className="button button--ots_bm button--ots_right"
            >
              Подтвердить проверку
            </div>
            <div
              onClick={sendForCorrection}
              className="button button--ots_bm button--red button--ots_right "
            >
              Отправить на доработку
            </div>
          </>
        )}
        {props.isAdmin && (
          <>
            <div onClick={onCopyCode} className="button button--ots_bm button--ots_right">
              Копировать код
            </div>
            <div
              onClick={onUpdateArticle}
              className="button button--ots_bm button--ots_right"
            >
              Сохранить
            </div>
            {publishButtonStatus && (
              <div
                onClick={() => publishItem("update")}
                className="button button--ots_bm button--ots_right"
              >
                Обновить на сайте
              </div>
            )}
            {!publishButtonStatus &&
              props.articleState.publish &&
              props.articleState.publish.active === true && (
                <div
                  onClick={() => publishItem("add")}
                  className="button button--ots_bm button--ots_right"
                >
                  Опубликовать на сайт
                </div>
              )}
            {props.articleState.publish.url && (
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={props.articleState.publish.url}
              >
                <div className="button button--ots_bm button--ots_right">Открыть</div>
              </a>
            )}
          </>
        )}
        <div onClick={onCopyDelElement} className="button button--ots_bm button--past">
          Копировать удаленный элемент
        </div>
      </div>
    </>
  );
};

export default ResultBlock;
