import React from "react";
import { NavLink } from "react-router-dom";
import { addZero } from "src/functions/addZero";

export const Article = (props) => {
  //Получаем верный объект с датой
  let deadline = props.state.deadline;

  //Преобразуем в дату
  let deadlineTime = new Date(deadline);

  let deadlineFormated =
    deadlineTime.getDate() +
    "." +
    addZero(deadlineTime.getMonth() + 1) +
    "." +
    deadlineTime.getFullYear() +
    " " +
    addZero(deadlineTime.getHours()) +
    ":" +
    addZero(deadlineTime.getMinutes());

  //Определяем просрочку дедлайна
  let deadlineStatus = "";
  if (props.deadlineStatus === true) {
    let deadlineMark = deadlineTime.getTime();
    let thisDateMark = Date.now();

    if (Number(thisDateMark) >= Number(deadlineMark)) {
      deadlineStatus = " article__value--warning";
    } else if (Number(thisDateMark) >= Number(deadlineMark) - 86400000) {
      deadlineStatus = " article__value--alert";
    }
  }

  //Имя для блока с символами и содержание
  let charactersName;
  let characters;

  let charactersStatus = "all";
  if (props.state.payCount.charactersPay > 0 && props.state.status === "completed") {
    charactersStatus = "pay";
  } else if (
    props.state.payCount.charactersPay > 0 &&
    props.state.status === "published"
  ) {
    charactersStatus = "pay";
  }

  if (charactersStatus === "pay") {
    charactersName = "Итоговые символы:";
    characters =
      String(props.state.payCount.charactersPay) +
      "/" +
      String(props.state.payCount.charactersPlan);
  } else {
    charactersName = "Символы:";
    characters =
      String(props.state.payCount.charactersComplete) +
      "/" +
      String(props.state.payCount.charactersPlan);
  }

  let articleActiveClass = "";
  if (
    props.state.payCount.charactersComplete > 0 &&
    props.state.status !== "published" &&
    props.state.status !== "completed"
  ) {
    articleActiveClass = " article--active";
  }

  return (
    <div className={"article" + articleActiveClass}>
      <div className="article__container">
        <div className="article__block">
          <h4>{props.state.name}</h4>
        </div>

        <div className="article__block">
          {!props.isAdmin &&
            props.state.status !== "completed" &&
            props.state.status !== "published" && (
              <div className="article__item">
                <div className="article__lable">Срок:</div>
                <div className={"article__value article__value--time" + deadlineStatus}>
                  {deadlineFormated}
                </div>
              </div>
            )}

          <div className="article__item">
            <div className="article__lable">{charactersName}</div>
            <div className={"article__value"}>{characters}</div>
          </div>
        </div>
      </div>

      {props.linkStatus && (
        <NavLink to={`/article/${props.state._id}`} className="button article__button">
          Открыть
        </NavLink>
      )}
    </div>
  );
};
