import React, { useState, useEffect, useContext } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { useHttp } from "src/hooks/http.hook";
import { AuthContext } from "src/context/AuthContext";
import { Loader } from "src/components/Loader";
import { useLocation } from "react-router-dom";

export const IframeGamePage = () => {
  const auth = useContext(AuthContext);
  const location = useLocation();
  const { loading, error, request } = useHttp();

  const [data, setData] = useState({
    link: "",
    isFixed: false,
  });

  const loginHandler = async (id, num) => {
    try {
      let data = null;
      if (id && num) {
        data = await request(
          "api/article/getGameLink",
          "POST",
          { id, num },
          { Authorization: `Bearer ${auth.token}` }
        );
      }
      setData((state) => {
        return { ...state, link: data };
      });
    } catch (error) {}
  };

  //Проверка данных
  useEffect(() => {
    let id = new URLSearchParams(location.search).get("id");
    id = id ? id : null;
    let num = new URLSearchParams(location.search).get("num");
    num = num ? num : null;
    loginHandler(id, num);
    // eslint-disable-next-line
  }, [location.search]);

  const fullScreenHandle = useFullScreenHandle();

  const fixHandler = () => {
    setData((state) => {
      return { ...state, isFixed: true };
    });
  };

  const openRedirect = (link) => {
    window.location = link;
  };

  let height = "100%";
  if (data.isFixed === true) {
    height = "900px";
  }

  if (loading === true) {
    return <Loader type="full" />;
  }

  if (error || data.link === null) {
    return <div className="textCenter">Ошибка загрузки</div>;
  }

  if (data.link.includes("http://gamelayer.ru/nohttps.php")) {
    openRedirect(data.link);
    return <div></div>; //render function should return something
  }
  if (data.link.includes("gamedistribution.com")) {
    openRedirect(data.link);
    return <div></div>; //render function should return something
  }

  return (
    <div className="page gamePlace">
      <div className="gamePlace__buttons">
        <div
          onClick={fullScreenHandle.enter}
          className="button button--ots_right gamePlace__button"
        >
          Открыть на весь экран
        </div>
        Если игра обрезается и не помещается в экран, нажмите кнопку справа:
        <div onClick={fixHandler} className="button button--ots_left">
          Зафиксировать
        </div>
      </div>
      <FullScreen className="gamePlace__fullscreen" handle={fullScreenHandle}>
        <iframe
          className="gamePlace__gameFrame"
          src={data.link}
          title="игра"
          scrolling="no"
          width="100%"
          height={height}
          sandbox="allow-same-origin allow-forms allow-pointer-lock allow-scripts allow-modals"
          loading="eager"
          importance="high"
          webkitallowfullscreen="true"
          mozallowfullscreen="true"
          allowFullScreen={true}
          marginWidth="0"
          marginHeight="0"
          hspace="0"
          vspace="0"
          frameBorder="0"
        ></iframe>
      </FullScreen>
    </div>
  );
};
