import React, { useCallback } from "react";
// import { useHistory } from 'react-router-dom';
import { useDispatch, connect } from "react-redux";
import {
  createAddArticles,
  createChangeTextAction,
  createPastStateAction,
  createHiddenButton,
  createHiddenElement,
  createUpdateEditor,
  createAddArticle,
  createClickAddItemNewAction,
  createClickTopAction,
  createClickDownAction,
  createClickCopyAction,
  createClickDelAction,
  createClickPastElementAction,
} from "src/redux/createReducer";
import { CreateArticleAll } from "src/components/CreateArticleAll";
import { useContext } from "react";
import { AuthContext } from "src/context/AuthContext";
import toaster from "src/hooks/toaster.hook";

const CreateArticlePage = (props) => {
  // const history = useHistory()
  // history.push(`/article/${data.article._id}`)
  const dispatch = useDispatch();

  //Получаем статус админа
  const { status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  const actions = useCallback(() => {
    //Вносим изменения в текст
    let changeText = (text, element, name) => {
      dispatch(createChangeTextAction(text, element, name));
    };

    let pastState = (data) => {
      dispatch(createPastStateAction(data));
    };
    //Показываем/скрываем доп данные
    let clickHiddenButton = (element) => {
      dispatch(createHiddenButton(element));
    };
    //Показываем/скрываем элемент
    let clickHiddenElement = (element) => {
      dispatch(createHiddenElement(element));
    };
    //Обновляем редактор
    let updateEditor = (data) => {
      dispatch(createUpdateEditor(data));
    };
    //Добавляем добавленную статью
    let addArticle = (data, users) => {
      dispatch(createAddArticle(data, users));
    };
    //Импортируем статьи
    let addArticles = (data, users) => {
      dispatch(createAddArticles(data, users));
    };
    let clickAddItemNew = (prototype, element) => {
      dispatch(createClickAddItemNewAction(prototype, element));
    };
    //Вверх
    let clickTop = (element, parentElement) => {
      dispatch(createClickTopAction(element, parentElement));
    };
    //Вниз
    let clickDown = (element, parentElement) => {
      dispatch(createClickDownAction(element, parentElement));
    };
    //Копировать
    let clickCopy = (element, parentElement) => {
      dispatch(createClickCopyAction(element, parentElement));
    };
    //Удалить
    let clickDel = (element, parentElement) => {
      dispatch(createClickDelAction(element, parentElement));
    };
    //Вставить
    let clickPastElement = (element) => {
      dispatch(createClickPastElementAction(element));
    };
    let generateAritcle = async (data, token) => {
      let body = JSON.stringify(data);
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      fetch(`/api/article/generate`, { method: "POST", body, headers })
        .then((res) => res.json())
        .then((result) => {
          let data = result;
          if (data.error === 1) {
            toaster.notify(data.message, {
              duration: 3000,
            });
          } else {
            addArticle(data.article, data.users);
          }
        })
        .catch((err) => {
          toaster.notify(err.message, {
            duration: 3000,
          });
        });
    };
    let importArticles = async (data, token) => {
      let body = JSON.stringify(data);
      let headers = {};
      headers["Authorization"] = `Bearer ${token}`;
      headers["Content-Type"] = "application/json";
      fetch(`/api/article/import`, { method: "POST", body, headers })
        .then((res) => res.json())
        .then((result) => {
          let data = result;
          if (data.error === 1) {
            toaster.notify(data.message, {
              duration: 3000,
            });
          } else {
            addArticles(data.articles, data.users);
          }
        })
        .catch((err) => {
          toaster.notify(err.message, {
            duration: 3000,
          });
        });
    };
    return {
      changeText,
      generateAritcle,
      importArticles,
      pastState,
      clickHiddenButton,
      clickHiddenElement,
      updateEditor,
      clickAddItemNew,
      clickTop,
      clickDown,
      clickCopy,
      clickDel,
      clickPastElement,
    };
  }, [dispatch]);

  return (
    <CreateArticleAll
      isAdmin={isAdmin}
      themeGlobal={props.themeGlobal}
      themeContent={props.themeContent}
      state={props.state}
      actions={actions}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    state: state.createReducer,
    themeGlobal: state.themReducer.data.global,
    themeContent: state.themReducer.data.content,
  };
};

export default connect(mapStateToProps)(CreateArticlePage);
