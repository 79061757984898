import React, { useState, useEffect, useContext } from "react";
import { useHttp } from "src/hooks/http.hook";
import { useMessage } from "src/hooks/message.hook";
import { AuthContext } from "src/context/AuthContext";
import Select from "react-select";
import { connect } from "react-redux";
import toaster from "src/hooks/toaster.hook";

const RegPage = (props) => {
  //Получаем данные и функции из хуков
  const auth = useContext(AuthContext);
  const message = useMessage();
  const { error, request, clearError } = useHttp();
  const [form, setForm] = useState({
    email: "",
    password: "",
    name: "",
    sistemName: "",
    telegram: "",
    projects: "",
    categories: [],
    status: "user",
  });

  //Проверка данных
  useEffect(() => {
    message(error);
    clearError();
  }, [error, message, clearError]);

  //Записываем данные в локальный state
  const changeHandler = (event) => {
    setForm({ ...form, [event.target.name]: event.target.value });
  };

  //Отправляем данные на сервер
  const regiserHandler = async () => {
    try {
      const data = await request(
        "api/auth/register",
        "POST",
        { ...form },
        { Authorization: `Bearer ${auth.token}` }
      );
      toaster.notify(data.message, {
        duration: 2000,
      });
      setForm({
        email: "",
        password: "",
        name: "",
        sistemName: "",
        telegram: "",
        projects: "",
        status: "user",
        categories: [],
      });
    } catch (error) {}
  };

  let selectValue = [];
  if (form.projects) {
    let selectValueItems = form.projects.split(",");
    selectValueItems.forEach((element) => {
      selectValue.push(props.options.projects.find((item) => item.value === element));
    });
  }

  let onChangeSelect = (date) => {
    let newText = "";
    if (date && typeof date === "object") {
      date.forEach((element) => {
        if (element.value !== undefined) {
          newText += element.value + ",";
        }
      });
      newText = newText.substring(0, newText.length - 1);
    }

    setForm({ ...form, projects: newText });
  };

  return (
    <div className="content">
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Регистрация</div>
        </div>
        <div className="element__item">
          <div className="element__block">
            <span className="element__blockName">Логин</span>
            <input
              className="element__input"
              placeholder="Введите Логин"
              id="email"
              name="email"
              type="text"
              value={form.email}
              onChange={changeHandler}
            />
            <span className="element__blockName">Пароль</span>
            <input
              className="element__input"
              placeholder="Введите пароль"
              id="password"
              name="password"
              type="password"
              value={form.password}
              onChange={changeHandler}
              autoComplete="new-password"
            />
            <span className="element__blockName">Имя и фамилия</span>
            <input
              className="element__input"
              placeholder="Имя"
              id="name"
              name="name"
              type="text"
              value={form.name}
              onChange={changeHandler}
            />
            <span className="element__blockName">Telegram</span>
            <input
              className="element__input"
              placeholder="Telegram"
              id="telegram"
              name="telegram"
              type="text"
              value={form.telegram}
              onChange={changeHandler}
            />
            <span className="element__blockName">Категории через ", "</span>
            <input
              autoComplete="off"
              placeholder="Категории"
              name="categories"
              className="element__input"
              value={form.categories?.join(", ")}
              onChange={(e) => {
                setForm({ ...form, categories: e.target.value?.split(", ") });
              }}
            />
            <span className="element__blockName">Роль</span>
            <Select
              className="element__select"
              value={props.options.userStatus.find(
                (item) => item.value === String(form.status)
              )}
              onChange={(result) => {
                setForm({
                  ...form,
                  status: result.value,
                });
              }}
              options={props.options.userStatus}
              isClearable={true}
              isMulti={false}
              closeMenuOnSelect={true}
            />
            <div className="element__blockName">Проекты</div>
            <Select
              className="element__select"
              value={selectValue}
              onChange={onChangeSelect}
              options={props.options.projects}
              isMulti={true}
              closeMenuOnSelect={false}
              placeholder="Выбор"
            />
          </div>
          <div onClick={regiserHandler} className="button button--ots_top">
            Регистрация
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    options: state.themReducer.data.global.options,
  };
};

export default connect(mapStateToProps)(RegPage);
