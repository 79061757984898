import React from "react";
import { NavLink, useSearchParams } from "react-router-dom";
import { useContext } from "react";
import DatePicker from "react-datepicker";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import "../../../../node_modules/react-datepicker/dist/react-datepicker.css";
import ru from "date-fns/locale/ru";
import SelectTableBlock from "./SelectTableBlock";
import { useDispatch } from "react-redux";
import toaster from "src/hooks/toaster.hook";
import copy from "copy-to-clipboard";
import { AuthContext } from "src/context/AuthContext";
import getSearchParamsObject from "src/functions/getSearchParamsObject";
registerLocale("ru", ru);
setDefaultLocale(ru);

export const ArticleTable = (props) => {
  //Задаем параметры
  const [searchParams] = useSearchParams();
  const { token } = useContext(AuthContext);
  const searchParamsObject = getSearchParamsObject(searchParams.toString());
  const dispatch = useDispatch();

  //Получаем верный объект с датой
  let deadline = props.state.deadline;

  //Преобразуем в дату
  let deadlineTime = new Date(deadline);

  //Определяем просрочку дедлайна
  let deadlineStatus = "";
  if (props.deadlineStatus === true) {
    let deadlineMark = deadlineTime.getTime();
    let thisDateMark = Date.now();
    if (Number(thisDateMark) >= Number(deadlineMark)) {
      deadlineStatus = " article__value--time article__value--warning";
    } else if (Number(thisDateMark) >= Number(deadlineMark) - 86400000) {
      deadlineStatus = " article__value--time article__value--alert";
    } else {
      deadlineStatus = " article__value--time";
    }
  }

  let publishButtonStatus = { status: "hide", text: "Не активна" };
  if (props.state.publish.active === true) {
    if (props.state.publish.status === true) {
      publishButtonStatus = { status: "update", text: "Обновить" };
    } else {
      publishButtonStatus = { status: "add", text: "Добавить" };
    }
  }

  let priceStatus = false;
  if (
    props.state.status === "completed" ||
    props.state.status === "published" ||
    props.state.status === "moderation"
  ) {
    priceStatus = true;
  }

  let linkStatus = false;
  if (props.state.publish.url) {
    linkStatus = true;
  }

  let priceModerator;
  let priceAuthor;
  if (priceStatus === true && props.state.payStatus === "notPaid") {
    const charactersCount = props.state.payCount.charactersCount;
    const videoModeratorCount = props.state.payCount.videoModeratorCount;
    const videoAuthorCount = props.state.payCount.videoAuthorCount;
    const fixModeratorCount = props.state.payCount.fixModeratorCount;
    const fixAuthorCount = props.state.payCount.fixAuthorCount;
    const authorRatesFix = props.state.payCount.authorRatesFix;
    const authorRatesText = props.state.payCount.authorRatesText;
    const authorRatesVideo = props.state.payCount.authorRatesVideo;
    const moderatorRatesFix = props.state.payCount.moderatorRatesFix;
    const moderatorRatesText = props.state.payCount.moderatorRatesText;
    const moderatorRatesVideo = props.state.payCount.moderatorRatesVideo;

    priceModerator = Math.round(
      (charactersCount / 1000) * moderatorRatesText +
        videoModeratorCount * moderatorRatesVideo +
        moderatorRatesFix * fixModeratorCount
    );
    priceAuthor = Math.round(
      (charactersCount / 1000) * authorRatesText +
        videoAuthorCount * authorRatesVideo +
        authorRatesFix * fixAuthorCount
    );
  }

  //Получаес статут последнего обновление на сайте (был ли он в течении этого дня)
  let updateDate = props.state.publish.updateDate;
  updateDate = new Date(updateDate).getTime();
  let limitDate = new Date().getTime() - 86400000;

  let lastDayUpdateClass = "";
  if (limitDate <= updateDate) {
    lastDayUpdateClass = " publishButtom--currentUpdate";
  }

  //Фунция обновление статуса
  let newStatus = (data, callBackData) => {
    //Если изменение статуса оплаты на оплачена, вводим сумму
    let countAuthor = 0;
    let countModerator = 0;
    if (data.value === "paid") {
      countAuthor = prompt("Сумма оплаты автору");
      countModerator = prompt("Сумма оплаты модератору");
    }

    //Если нажата кнопка отмены, отменяем действие
    if (countAuthor === null || countModerator === null) {
      return "";
    }

    //Задаем данные на выход
    let dataObject = {
      article: props.state,
      newstatus: data.value,
      countAuthor: Number(countAuthor),
      countModerator: Number(countModerator),
    };

    let body = JSON.stringify(dataObject);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/statusReplace`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  //Фунцкия обновление пользователя
  let newUser = (data, callBackData) => {
    let dataObject = {
      article: props.state,
      userName: data.value,
      action: callBackData,
    };

    let body = JSON.stringify(dataObject);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/userReplace`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let categoryReplace = (data, callBackData) => {
    let dataObject = {
      article: props.state,
      categories: data,
    };

    let body = JSON.stringify(dataObject);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/categoryReplace`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let onChangeDate = (date) => {
    let data = {
      article: props.state,
      newDate: date,
    };

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/dataReplace`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let deleteItem = () => {
    let result = window.confirm(`Вы действительно хотите удалить "${props.state.name}"?`);
    if (!result) {
      return;
    }

    let data = {
      article: props.state,
    };

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/delete`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== 1) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  let publishItem = (publishStatus) => {
    let ShowNotif = (text) => {
      toaster.notify(text, {
        duration: 2000,
      });
    };

    if (publishStatus === "hide") {
      return;
    }

    let result = window.prompt("Введите пароль");
    if (!result) {
      return;
    }

    let data = {
      id: props.state._id,
      publishStatus,
      password: result,
    };

    let text;
    if (publishStatus === "add") {
      text = "Опубликовано";
    } else {
      text = "Обновлено";
    }

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/article/publish`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== 1) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
          ShowNotif(text);
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  //Копирование id статьи
  let onCopyID = () => {
    let copyElement = props.state._id;
    //Перемещаем state в буфер обмена
    copy(copyElement, {
      debug: true,
      message: "Нажмите #{key} чтобы скопировать",
    });
    toaster.notify("ID скопировано", {
      duration: 2000,
    });
  };

  //Получаем опции
  let optionsProjects = props.options.projects;

  //Функция преобразования
  let getName = (options, sisName) => {
    let name = options.find((item) => {
      if (item.value === sisName) {
        return true;
      }
      return false;
    });
    //Если ничего не найдено
    if (name === undefined) {
      name = { label: "Нет" };
    }
    return name.label;
  };
  //Заменяем значеня на названия
  let project = props.state.project;
  let projectName = getName(optionsProjects, project);

  return (
    <tr>
      <td onClick={onCopyID} className="tableBlock__td tableBlock__td--id">
        {props.num}
      </td>
      <td className="tableBlock__td">
        <NavLink
          to={`/article/${props.state._id}`}
          className="article__itemName article__value article__value--active"
        >
          {props.state.name}
        </NavLink>
      </td>
      <td className="tableBlock__td tableBlock__td--select">
        <SelectTableBlock
          callBack={newUser}
          callBackData={"owner"}
          options={props.options.users}
          value={props.state.ownerData.sistemName}
        />
      </td>
      <td className="tableBlock__td tableBlock__td--select">
        <SelectTableBlock
          callBack={newUser}
          callBackData={"moderator"}
          options={props.options.users}
          value={props.state.moderatorData.sistemName}
        />
      </td>
      <td className="tableBlock__td">
        <div className={"article__value article__value--active" + deadlineStatus}>
          <DatePicker
            onChange={(date) => onChangeDate(date)}
            selected={deadlineTime}
            showTimeSelect
            timeIntervals={60}
            dateFormat="dd.M.yyyy HH:mm"
          />
        </div>
      </td>
      <td className="tableBlock__td">
        <div className={"article__value"}>
          {props.state.payCount.charactersComplete} /{" "}
          {props.state.payCount.charactersPlan}
        </div>
      </td>
      <td className="tableBlock__td tableBlock__td--select">
        <SelectTableBlock
          callBack={newStatus}
          callBackData={""}
          options={props.options.status}
          value={props.state.status}
        />
      </td>
      <td className="tableBlock__td tableBlock__td--select tableBlock__td--price">
        <div className="tableBlock__price">
          <SelectTableBlock
            callBack={newStatus}
            callBackData={""}
            options={props.options.payStatus}
            value={props.state.payStatus}
          />
          {props.state.payStatus === "notPaid" && priceStatus === true && (
            <div className="tableBlock__priceItem">
              (А: {priceAuthor} М: {priceModerator})
            </div>
          )}
        </div>
      </td>
      <td className="tableBlock__td">
        <div className="article__value article__value--active">{projectName}</div>
      </td>
      <td className="tableBlock__td">
        <div className="article__value article__value--active">
          {props.state.articleType}
        </div>
      </td>
      <td className="tableBlock__td tableBlock__td--select tableBlock__td--category">
        <SelectTableBlock
          callBack={categoryReplace}
          callBackData={""}
          options={props.options.category[props.state.project]}
          value={props.state.category}
          multi={true}
          closeMenuOnSelect={false}
        />
      </td>
      <td className="tableBlock__td">
        <div
          onClick={deleteItem}
          className="article__value article__value--active article__value--warning"
        >
          Удалить
        </div>
      </td>
      <td className="tableBlock__td">
        <div
          onClick={() => publishItem(publishButtonStatus.status)}
          className={
            "publishButtom article__value article__value--active publishButtom--" +
            publishButtonStatus.status +
            lastDayUpdateClass
          }
        >
          {publishButtonStatus.text}
        </div>
      </td>
      {linkStatus && (
        <td className="tableBlock__td">
          <a
            className="article__value--linkTable article__value article__value--active"
            target="_blank"
            rel="noopener noreferrer"
            href={props.state.publish.url}
          >
            Перейти
          </a>
        </td>
      )}
      {!linkStatus && (
        <td className="tableBlock__td">
          <div className="article__value article__value--active">Нет</div>
        </td>
      )}
    </tr>
  );
};
