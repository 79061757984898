import { useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Loader } from "src/components/Loader";
import { AuthContext } from "src/context/AuthContext";
import Select from "react-select";
import toaster from "src/hooks/toaster.hook";

const ProfilePage = ({ themeGlobal }) => {
  const userName = useParams().username;
  const { token, status } = useContext(AuthContext);
  const isAdmin = status === "admin" ? true : false;

  const [userData, setUserData] = useState({
    isLoading: true,
    isError: false,
    data: null,
  });

  const getUserData = async (userName, token) => {
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/users/profile/${userName}`, { method: "GET", headers })
      .then((res) => res.json())
      .then((result) => {
        setUserData(result);
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  const changeUserData = async (userName, token) => {
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    let body = JSON.stringify({ userSistemName: userName, userData: userData.data });
    fetch(`/api/users/changeUserData`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        toaster.notify(result.message, {
          duration: 2000,
        });
      })
      .catch((err) => {
        console.log(err.message);
      });
  };

  useEffect(() => {
    getUserData(userName, token);
  }, [userName, token]);

  if (userData.isLoading === true) {
    return <Loader type="full" />;
  }

  if (!isAdmin || userData.isError) {
    return (
      <p className="textCenter">
        Ошибка загрузки данных. Если ошибка повторяется и у вас должен быть доступ к этой
        странице, нажмите кнопку "Выйти" в самом верху и зайдите заново в редактор.
      </p>
    );
  }

  const projectsOptionValues = userData.data.projects.map((element) => {
    return themeGlobal.options.projects.find((item) => item.value === element);
  });

  let payRateItems = Object.entries(userData.data.payRate);
  payRateItems = payRateItems.map((item) => {
    return [item[0], Object.entries(item[1])];
  });

  const updateSitesIdWhenItChangesProjectsList = (userData, newProjects) => {
    const sitesIdList = [];

    newProjects.forEach((item) => {
      const siteIdObj = userData.data.sitesId.find(
        (sitesIdItem) => sitesIdItem.project === item
      );

      if (siteIdObj) {
        sitesIdList.push({ id: siteIdObj.id || "", project: siteIdObj.project });
      } else {
        sitesIdList.push({ id: "", project: item });
      }
    });

    return sitesIdList;
  };

  const updateAllowedItemTypesWhenItChangesProjectsList = (userData, newProjects) => {
    const uploadAllowedItemTypes = themeGlobal.options.allowedItemTypes;
    const allowedItemTypes = userData.data?.allowedItemTypes;
    const newAllowedItemTypes = {};

    newProjects.forEach((item) => {
      if (allowedItemTypes && allowedItemTypes[item]) {
        newAllowedItemTypes[item] = allowedItemTypes[item];
      } else {
        newAllowedItemTypes[item] = uploadAllowedItemTypes[item];
      }
    });

    return newAllowedItemTypes;
  };

  const updatePayRatesWhenItChangesProjectsList = (userData, newProjects) => {
    const uploadPayRateItems = themeGlobal.options.payRates;

    let payRate = {
      author: {
        text: [],
        video: [],
        fix: [],
      },
      moderator: {
        text: [],
        video: [],
        fix: [],
      },
    };

    let payRateArray = Object.entries(payRate);
    payRateArray = payRateArray.map((item) => {
      return [item[0], Object.entries(item[1])];
    });

    payRateArray.forEach((userRoleItem) => {
      userRoleItem[1].forEach((payTypeItem) => {
        newProjects.forEach((projectItem) => {
          let oldPayRateProdjectTypes = userData.data.payRate[userRoleItem[0]][
            payTypeItem[0]
          ]?.find((item) => item.project === projectItem)?.type;

          let newPayRateProdjectTypes =
            uploadPayRateItems[projectItem][userRoleItem[0]][payTypeItem[0]];

          newPayRateProdjectTypes.map((prodjectTypeItem) => {
            if (oldPayRateProdjectTypes) {
              const oldProdjectTypeItem = oldPayRateProdjectTypes.find(
                (oldProdjectTypeItem) => {
                  if (
                    oldProdjectTypeItem?.type &&
                    oldProdjectTypeItem?.type === prodjectTypeItem.type
                  ) {
                    return true;
                  }
                  return false;
                }
              );

              if (oldProdjectTypeItem) {
                prodjectTypeItem.count = oldProdjectTypeItem.count;
              }
            }

            return prodjectTypeItem;
          });

          payRate[userRoleItem[0]][payTypeItem[0]].push({
            project: projectItem,
            type: newPayRateProdjectTypes,
          });
        });
      });
    });

    return payRate;
  };

  return (
    <div className="content">
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Основные настройки</div>
        </div>
        <div className="element__item">
          <div className="element__block">
            <span className="element__blockName">Имя пользователя</span>
            <input
              autoComplete="off"
              name="name"
              placeholder="Имя"
              className="element__input"
              value={userData.data.name}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  data: { ...userData.data, name: e.target.value },
                });
              }}
            />
            <span className="element__blockName">Телеграм</span>
            <input
              autoComplete="off"
              name="telegram"
              placeholder="Telegram"
              className="element__input"
              value={userData.data.telegram}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  data: { ...userData.data, telegram: e.target.value },
                });
              }}
            />
            <span className="element__blockName">Роль</span>
            <Select
              className="element__select"
              value={themeGlobal.options.userStatus.find(
                (item) => item.value === String(userData.data.status)
              )}
              onChange={(result) => {
                setUserData({
                  ...userData,
                  data: {
                    ...userData.data,
                    status: result.value,
                  },
                });
              }}
              options={themeGlobal.options.userStatus}
              isClearable={true}
              isMulti={false}
              closeMenuOnSelect={true}
            />
            <span className="element__blockName">Активность</span>
            <Select
              className="element__select"
              value={themeGlobal.options.userActivity.find(
                (item) => item.value === String(userData.data.activity)
              )}
              onChange={(result) => {
                setUserData({
                  ...userData,
                  data: {
                    ...userData.data,
                    activity: result.value === "true" ? true : false,
                  },
                });
              }}
              options={themeGlobal.options.userActivity}
              isClearable={true}
              isMulti={false}
              closeMenuOnSelect={true}
            />
            <span className="element__blockName">Лимит взятых работ</span>
            <input
              autoComplete="off"
              name="categories"
              placeholder="Лимит взятых работ"
              className="element__input"
              type="number"
              value={userData.data.workLimit}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  data: { ...userData.data, workLimit: e.target.value },
                });
              }}
            />
            <span className="element__blockName">Категории через ", "</span>
            <input
              autoComplete="off"
              name="categories"
              placeholder="Категории"
              className="element__input"
              value={userData.data.categories?.join(", ")}
              onChange={(e) => {
                setUserData({
                  ...userData,
                  data: { ...userData.data, categories: e.target.value?.split(", ") },
                });
              }}
            />
            <span className="element__blockName">Активные проекты</span>
            <Select
              className="element__select"
              value={projectsOptionValues}
              onChange={(result) => {
                const newProjects = result.map((item) => item.value);
                setUserData((prevState) => {
                  return {
                    ...prevState,
                    data: {
                      ...prevState.data,
                      projects: newProjects,
                      sitesId: updateSitesIdWhenItChangesProjectsList(
                        prevState,
                        newProjects
                      ),
                      payRate: updatePayRatesWhenItChangesProjectsList(
                        prevState,
                        newProjects
                      ),
                      allowedItemTypes: updateAllowedItemTypesWhenItChangesProjectsList(
                        prevState,
                        newProjects
                      ),
                    },
                  };
                });
              }}
              options={themeGlobal.options.projects}
              isClearable={true}
              isMulti={true}
              closeMenuOnSelect={true}
              placeholder="Активные проекты"
            />
          </div>
        </div>
      </div>
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">ID автора в проектах</div>
        </div>
        <div className="element__item">
          {userData.data.sitesId.map((item, index) => {
            return (
              <div key={index} className="element__block">
                <span className="element__blockName">{item.project}</span>
                <input
                  autoComplete="off"
                  name={`userId-${item.project}`}
                  placeholder="ID автора"
                  className="element__input"
                  value={item.id}
                  type="number"
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      data: {
                        ...userData.data,
                        sitesId: userData.data.sitesId.map((editingItem) => {
                          if (editingItem.project === item.project) {
                            return { project: item.project, id: Number(e.target.value) };
                          }
                          return editingItem;
                        }),
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Доступ к элементам</div>
        </div>
        <div className="element__item">
          {Object.keys(userData.data.allowedItemTypes).map((item, index) => {
            return (
              <div key={index} className="element__block">
                <span className="element__blockName">{item}</span>
                <input
                  autoComplete="off"
                  name={`userId-${item}`}
                  placeholder="Доступные типы"
                  className="element__input"
                  value={userData.data.allowedItemTypes[item]?.join(", ")}
                  onChange={(e) => {
                    setUserData({
                      ...userData,
                      data: {
                        ...userData.data,
                        allowedItemTypes: {
                          ...userData.data.allowedItemTypes,
                          [item]: e.target.value?.split(", "),
                        },
                      },
                    });
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="element editorBlock">
        <div className="element__title element__title--group">
          <div className="element__titleLeft">Тарифы</div>
        </div>
        {payRateItems.map((userRoleItem, index) => {
          return (
            <div key={index} className="element__item">
              <span className="element__itemName">{userRoleItem[0]}</span>
              {userRoleItem[1].map((payTypeItem, index) => {
                return (
                  <div key={index} className="element__item">
                    <span className="element__itemName">{payTypeItem[0]}</span>
                    {userData.data.payRate[userRoleItem[0]][payTypeItem[0]].map(
                      (itemProject, indexProject) => {
                        return (
                          <div key={indexProject} className="element__item">
                            <span className="element__itemName">
                              {itemProject.project}
                            </span>
                            {itemProject.type.map((itemType, indexType) => {
                              return (
                                <div key={indexType} className="element__block">
                                  <span className="element__blockName">
                                    {itemType.type}
                                  </span>
                                  <input
                                    autoComplete="off"
                                    name={`price-${itemType.type}-${itemProject.project}-${payTypeItem[0]}-${userRoleItem[0]}`}
                                    placeholder="Тариф"
                                    className="element__input"
                                    value={itemType.count}
                                    type="number"
                                    onChange={(e) => {
                                      const userDataClon = { ...userData };
                                      userDataClon.data.payRate[userRoleItem[0]][
                                        payTypeItem[0]
                                      ][indexProject].type[indexType].count = Number(
                                        e.target.value
                                      );
                                      setUserData(userDataClon);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          </div>
                        );
                      }
                    )}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className="element editorBlock">
        <div
          onClick={() => {
            changeUserData(userName, token);
          }}
          className="button"
        >
          Cохранить изменения
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    themeGlobal: state.themReducer.data.global,
  };
};

export default connect(mapStateToProps)(ProfilePage);
