import React from 'react';

const LinkBlock = (props) => {

    let link = props.element[props.item]

    let name
    if(props.name){
        name = props.name
    } else {
        name = 'Ссылка'
    }

    return (
        <a className="linkBlock" target="_blank" rel="noopener noreferrer" href={link}>{name}</a>
    )
}

export default LinkBlock;