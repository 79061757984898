import React, { useContext } from "react";
import SelectTableBlock from "src/components/Category/TableBlock/SelectTableBlock";
import { useDispatch } from "react-redux";
import { AuthContext } from "src/context/AuthContext";
import { NavLink, useSearchParams } from "react-router-dom";
import getSearchParamsObject from "src/functions/getSearchParamsObject";
import toaster from "src/hooks/toaster.hook";

export const UserTable = (props) => {
  const [searchParams] = useSearchParams();
  const { token } = useContext(AuthContext);
  const searchParamsObject = getSearchParamsObject(searchParams.toString());
  const dispatch = useDispatch();

  const onChangeActivity = () => {
    let data = {
      userSistemName: props.state.sistemName,
      activity: !props.state.activity,
    };

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/users/changeActivity`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (result.error !== true) {
          if (props.updater) {
            dispatch(props.updater(token, searchParamsObject));
          }
          if (props.callBack) {
            dispatch(props.callBack(result));
          }
        } else {
          toaster.notify(result.message, {
            duration: 3000,
          });
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  const onRemoveUser = () => {
    let result = window.confirm(`Вы действительно хотите удалить "${props.state.name}"?`);
    if (!result) {
      return;
    }

    let data = {
      userSistemName: props.state.sistemName,
    };

    let body = JSON.stringify(data);
    let headers = {};
    headers["Authorization"] = `Bearer ${token}`;
    headers["Content-Type"] = "application/json";
    fetch(`/api/users/removeUser`, { method: "POST", body, headers })
      .then((res) => res.json())
      .then((result) => {
        if (props.updater) {
          dispatch(props.updater(token, searchParamsObject));
        }
        if (props.callBack) {
          dispatch(props.callBack(result));
        }
      })
      .catch((err) => {
        toaster.notify(err.message, {
          duration: 3000,
        });
      });
  };

  //Получаем опции
  let optionsProjects = props.themeGlobal.options.projects;

  //Заменяем значеня на названия
  let projects = props.state.projects;
  projects = projects.map((project) => {
    let name = optionsProjects.find((item) => {
      if (item.value === project) {
        return true;
      }
      return false;
    });
    //Если ничего не найдено
    if (name === undefined) {
      name = { label: "Нет" };
    }
    return name.label;
  });
  //Преобразуем в строку
  projects = projects.join(", ");

  const categories = props.state.categories?.join(", ") || "";

  return (
    <tr>
      <td className="tableBlock__td">{props.state.name}</td>
      <td className="tableBlock__td">{props.state.telegram}</td>
      <td className="tableBlock__td">{projects}</td>
      <td className="tableBlock__td">{categories}</td>
      <td className="tableBlock__td">{props.state.active}</td>
      <td className="tableBlock__td">{props.state.corrections}</td>
      <td className="tableBlock__td">{props.state.moderation}</td>
      <td className="tableBlock__td">{props.state.onModeration}</td>
      <td className="tableBlock__td">{props.state.notPaid}</td>
      <td className="tableBlock__td">{props.state.paid}</td>
      <td className="tableBlock__td tableBlock__td--select">
        <SelectTableBlock
          callBack={onChangeActivity}
          callBackData={""}
          options={props.themeGlobal.options.userActivity}
          value={String(props.state.activity)}
        />
      </td>
      <td className="tableBlock__td">
        <NavLink
          to={`/profile/${props.state.sistemName}`}
          className="article__value--linkTable article__value"
        >
          Перейти
        </NavLink>
      </td>
      <td className="tableBlock__td">
        <div
          onClick={onRemoveUser}
          className="article__value article__value--active article__value--warning"
        >
          Удалить
        </div>
      </td>
    </tr>
  );
};
